<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="appsDappsIdsOpens">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-12 center pt-5">
          <img class="img-fluid img-org" [src]="appsDappsId['fieldImagenApp']['url']" alt="">
        </div>
        <div class="col-12 col-md-12 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-l-store ">
            {{appsDappsId['title']}}
          </h2>
          <hr class="line-title-banner color-l-store">
          <p class="p-nomaice mb-4" [innerHTML]="appsDappsId['body']['processed']"></p>
          <div class="row padding-section-responsive">
            <div class="col-12 d-flex align-items-center p-0 m-0 flex-wrap-responsive">
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'titularidad' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldTitularidad']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'tipodesolucion' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldTipoSolucionAppsDapps']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'plataforma' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldPlataformaApp']}} </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row padding-section-responsive">
            <div class="col-12 d-flex align-items-center p-0 m-0 flex-wrap-responsive">
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'categoria' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldCategoriaAppDapp']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'estado' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldEstadoApp']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'sede' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldSedeLegalApp']}} </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row padding-section-responsive">
            <div class="col-12 d-flex align-items-center p-0 m-0 flex-wrap-responsive">
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'idiomasdisponibles' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldIdiomasDisponibles']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  {{'puntoContacto' | translate}}:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldPuntoContactoApp']}} </p>
                </span>
              </div>
              <div class="col-md-4 col-sm-12 card-items-internal m-1">
                <span class="d-block color-l-store">
                  GitHub:
                  <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldGithubApp']}} </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row padding-section-responsive">
            <div class="col-12 d-flex align-items-center p-0 m-0 flex-wrap-responsive">
              <div class="col-12 d-flex align-items-start p-0 m-0 flex-wrap-responsive">
                <div class="col-md-6 col-sm-12 card-items-internal m-1">
                  <span class="d-block color-l-store">
                    {{'sitioweb' | translate}}:
                    <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldSitioWebApp']['uri']}} </p>
                  </span>
                </div>
                <div class="col-md-6 col-sm-12 card-items-internal m-1">
                  <span class="d-block color-l-store">
                    {{'sitiodescarga' | translate}}:
                    <p class="p-nomaice d-inline-block m-0"> {{appsDappsId['fieldSitioDescargaApp']['uri']}} </p>
                  </span>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="our-allies">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of appsDapps; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/appsDapps',routerAppsDapps(item.fieldTituloIngles))" class="btn  btn-block mt-2 maxw28rem"
                role="button">
                <app-card-apps-dapps [title]="item.title" [text]="item.body.summary"
                  [type]="item.fieldTipoSolucionAppsDapps" [images]="item.fieldImagenPreview.url">
                </app-card-apps-dapps>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

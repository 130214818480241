import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalProgramsService } from 'src/app/core/services/digital-programs.service';

@Component({
  selector: 'app-digital-platforms-all',
  templateUrl: './digital-platforms-all.component.html',
  styleUrls: ['./digital-platforms-all.component.scss']
})
export class DigitalPlatformsAllComponent implements OnInit {

  digitalProgramsId: any = {};
  digitalPrograms: any[] = [];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  digitalProgramsIdOpens = false;
  digitalProgramsOpens = false;
  indrag = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private lstoreService: DigitalProgramsService,
    private router: Router) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getDigitalProgramsId(true);
            this.getDigitalPrograms();
        }
    });
     }

  ngOnInit(): void {
    this.getDigitalProgramsId(false);
    this.getDigitalPrograms();
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, (title) ]);
    }
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  getDigitalProgramsId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const entitiesId = this.getEvenIdByName(arg.title);
      this.digitalProgramsIdOpens = false;
      this.lstoreService.getPlatforms(`
        {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (entitiesId) : this.digitalProgramsId.fieldTituloIngles }"
        },
      `,
      1,
      0,
      localStorage.getItem('lang').toUpperCase(),(events, total) => {
        this.digitalProgramsId =  events[0];
        this.digitalProgramsIdOpens = true;
      });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getDigitalPrograms() {
    this.lstoreService.getPlatforms('',
      100,
      0,
      localStorage.getItem('lang').toUpperCase(),(_alliances, total) => {
        this.digitalPrograms =  _alliances;
        this.digitalProgramsOpens = true;
    });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  routerDigitalPrograms(title: string) {
    return title.replace(' ', '-');
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-communities',
  templateUrl: './card-communities.component.html',
  styleUrls: ['./card-communities.component.scss']
})
export class CardCommunitiesComponent implements OnInit {

@Input() title: string;
@Input() text: string;
@Input() type: string;
@Input() images: string;


  constructor() { }

  ngOnInit(): void {
  }



}

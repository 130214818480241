<div class="content-cities-map mt-5" *ngIf="!isMobile && innerWidth > 1100">
  <div class="container-fluid back-cities">
    <div class="mx1005 row d-flex justify-content-center">
      <div class="text-center col-4">
        <a [routerLink]="['/use-cases']" (click)="activeCities(2)" [class.cities-active]="activeCitiesInput === 'two'">
          <div class="sec-icon-cases">
            <h5 class="alianza">{{'casosdeuso' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-casos-de-uso.png" alt="">
          </div>
          <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/casos-de-uso.png" alt="">
        </a>
      </div>
      <div class="col-4 ">
        <a [routerLink]="['/entrepreneurship']" (click)="activeCities(4)"
          [class.cities-active]="activeCitiesInput === 'one' || activeCitiesInput === 'three'">
          <div class="sec-icon-emprendimiento">
            <h5 class="alianza">{{'emprendimiento' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-emprendimiento.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-emprendimiento hvr-hang"
            src="../../../../assets/images/cities/emprendimiento.png" alt="">
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-4 mt-6-6">
        <a [routerLink]="['/resources']" (click)="activeCities(5)" [class.cities-active]="activeCitiesInput === ''">
          <div class="sec-icon-recursos">
            <h5 class="alianza">{{'recursos' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-recursos.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-recursos hvr-hang"
            src="../../../../assets/images/cities/recursos.png" alt="">
        </a>
      </div>
      <div class="col-4 mt-4">
        <a [routerLink]="['/the-blockchain']" (click)="activeCities(3)"
          [class.cities-active]="activeCitiesInput === 'zero' || activeCitiesInput === 'one' || activeCitiesInput === 'two'">
          <div class="sec-icon-blockchain">
            <h5 class="alianza">{{'lablockchain' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-blockchain.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-blockchain hvr-hang"
            src="../../../../assets/images/cities/blockchain.png" alt="">
        </a>
      </div>
      <div class="col-4 mt-6-6">
        <a [routerLink]="['/l-store']" (click)="activeCities(7)" [class.cities-active]="activeCitiesInput === 'three'">
          <div class="sec-icon-l-store">
            <h5 class="alianza">{{'lstore' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Iconol-store.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-l-store hvr-hang"
            src="../../../../assets/images/cities/l-store.png" alt="">
        </a>
      </div>
    </div>


    <div class="row mx980">
      <div class="col-4 mt-4">
        <a [routerLink]="['/ecosystem']" (click)="activeCities(6)" [class.cities-active]="activeCitiesInput === 'zero'">
          <div class="sec-icon-eco">
            <h5 class="alianza">{{'ecosistema' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-ecosistema.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-eco hvr-hang"
            src="../../../../assets/images/cities/ecosistema.png" alt="">
        </a>
      </div>


      <div class="col-4 mt-10">
        <a [routerLink]="['/academy']" (click)="activeCities(1)"
          [class.cities-active]="activeCitiesInput === 'zero' || activeCitiesInput === 'one' || activeCitiesInput === 'two' || activeCitiesInput === 'three' ">
          <div class="sec-icon-academy">
            <h5 class="alianza">{{'academia' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-academy.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-alianza hvr-hang"
            src="../../../../assets/images/cities/Academy.png" alt="">
        </a>
      </div>

      <div class="col-4 mt-4">
        <a [routerLink]="['/alliance']" (click)="activeCities(1)" [class.cities-active]="activeCitiesInput ===  ''">
          <div class="sec-icon-alianza">
            <h5 class="alianza">{{'alianza' | translate}}</h5>
            <img class="img-fluid hvr-hang" src="../../../../assets/images/cities/Icono-alianza.png" alt="">
          </div>
          <img class="img-fluid position-absolute img-alianza hvr-hang"
            src="../../../../assets/images/cities/alianza.png" alt="">
        </a>
      </div>

    </div>
  </div>
</div>


<div class="container-fluid  container-carousel p-4" *ngIf="isMobile || innerWidth <= 1100">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/alliance']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/alianza.png" alt="bid">
                <h3 class="color-alliance">{{'alianza' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/ecosystem']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/ecosistema.png" alt="bid">
                <h3 class="color-ecosystems">{{'ecosistema' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/entrepreneurship']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/emprendimiento.png"
                  alt="bid">
                <h3 class="color-entrepreneurship">{{'emprendimiento' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/l-store']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/l-store.png" alt="bid">
                <h3 class="color-l-store">{{'lstore' | translate}}</h3>

              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/use-cases']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/casos-de-uso.png" alt="bid">
                <h3 class="color-projects">{{'casosdeuso' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/resources']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/recursos.png" alt="bid">
                <h3 class="color-resources">{{'recursos' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/the-blockchain']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/blockchain.png" alt="bid">
                <h3 class="color-the-blockchain">{{'lablockchain' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card-slider">
        <div class="row">
          <div class="col-md-12">
            <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
              <a [routerLink]="['/academy']">
                <img class="img-fluid logo-carousel" src="../../../../assets/images/cities/Academy.png" alt="bid">
                <h3 class="color-the-academy">{{'academia' | translate}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {
  participatesShow = false;
  carBlueActive = false;
  selectedIndex: number = null;
  selectedIndexMore: number = null;
  initalLoad = true;
  launchCommunity = '#launchcommunity';

  @Output()
  cardPositionEmit: EventEmitter<string> = new EventEmitter<string>();


  public informationCommunities = [
    { title_lang: 'promcomlacchain',
      description: 'promcomlacchainvermasone',
      descriptionII: 'manual',
      descriptionIII: 'promcomlacchainvermastwo',
      options:
      [{name: 'ecosistema', value: 'ecosystem'},
       {name: 'lablockchain', value: 'the-blockchain' },
      { name: 'academia', value: 'academy' }]
    },
    { title_lang: 'eresunaempresa',
      description: 'eresunaempresavermas',
      descriptionII: '',
      descriptionIII: '',
      options:  [
      {name: 'lablockchain', value: 'the-blockchain' },
      {name: 'emprendimiento', value: 'entrepreneurship'},
      { name: 'academia', value: 'academy' }]},
    { title_lang: 'eresungobiero',
      description: 'eresungobierovermas',
      descriptionII: '',
      descriptionIII: '',
      options:  [
        {name: 'casosdeuso', value: 'use-cases'},
        {name: 'lablockchain', value: 'the-blockchain'},
        { name: 'academia', value: 'academy' }]  },
    { title_lang: 'eresemprendedor',
      description: 'eresemprendedorvermas',
      descriptionII: '',
      descriptionIII: '',
      options:  [{name: 'emprendimiento', value: 'entrepreneurship'},
      {name: 'lstore', value: 'l-store'},
        { name: 'academia', value: 'academy' }]  },
  ];

  constructor() { }

  ngOnInit(): void {
  }
  activeCard(index){
    this.selectedIndex = index;
    this.carBlueActive = !this.carBlueActive;
    if (index === 0){
      this.cardPositionEmit.emit('zero');
    } else if (index === 1){
      this.cardPositionEmit.emit('one');
    } else if (index === 2) {
      this.cardPositionEmit.emit('two');
    } else if (index === 3) {
      this.cardPositionEmit.emit('three');
    }
  }

  shoMore(index){
    this.initalLoad = false;
    this.participatesShow = !this.participatesShow ;
    this.selectedIndexMore = index;
  }

  backMe(){
    this.initalLoad = true;
    this.participatesShow = false;
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from '../app/modules/home/home.component';
import { AllianceComponent } from '../app/modules/alliance/alliance.component';
import { EcosystemsComponent } from '../app/modules/ecosystems/ecosystems.component';
import { EntrepreneurshipComponent } from '../app/modules/entrepreneurship/entrepreneurship.component';
import { LStoreComponent } from '../app/modules/l-store/l-store.component';
import { UseCasesComponent } from '../app/modules/use-cases/use-cases.component';
import { ResourcesComponent } from '../app/modules/resources/resources.component';
import { TheBlockchainComponent } from '../app/modules/the-blockchain/the-blockchain.component';
import { CommunitiesAllComponent } from '../app/shared/components/communities-all/communities-all.component';
import { EntitiesAllComponent } from '../app/shared/components/entities-all/entities-all.component';
import { EventsAllComponent } from '../app/shared/components/events-all/events-all.component';
import { OurNewsAllComponent } from './shared/components/our-news-all/our-news-all.component';
import { ServicesAllComponent } from './shared/components/services-all/services-all.component';
import { DigitalPlatformsAllComponent } from './shared/components/digital-platforms-all/digital-platforms-all.component';
import { AppsDappsAllComponent } from './shared/components/apps-dapps-all/apps-dapps-all.component';
import { FinancingOpportunitienitiesAllComponent } from './shared/components/financing-opportunitienities-all/financing-opportunitienities-all.component';
import { ProjectsAllComponent } from './shared/components/projects-all/projects-all.component';
import { AllianceEventsAllComponent } from './shared/components/alliance-events-all/alliance-events-all.component';
import { TendersAllComponent } from './shared/components/tenders-all/tenders-all.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { AcademyComponent } from './modules/academy/academy.component';
import { RecentContentComponent } from './shared/components/recent-content/recent-content.component';
import { CertifiableCoursesComponent } from './shared/components/certifiable-courses/certifiable-courses.component';
import { VideoRelatedInformationComponent } from './shared/components/video-related-information/video-related-information.component';
import { CourseDetailAcademyComponent } from './shared/components/course-detail-academy/course-detail-academy.component';
import { AcademicVideosComponent } from './shared/components/academic-videos/academic-videos.component';
import { SubscribeAcademyComponent } from './shared/components/subscribe-academy/subscribe-academy.component';
import { AcademicPublicationsComponent } from './shared/components/academic-publications/academic-publications.component';


const routerOptions: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  // scrollOffset: [0, 64],
};


const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'alliance', component: AllianceComponent },
  { path: 'ecosystem', component: EcosystemsComponent },
  { path: 'communities/:title', component: CommunitiesAllComponent },
  { path: 'entities/:title', component: EntitiesAllComponent },
  { path: 'services/:title', component: ServicesAllComponent },
  { path: 'events/:title', component: EventsAllComponent },
  { path: 'events-participacion/:title', component: AllianceEventsAllComponent },
  { path: 'our-news/:title', component: OurNewsAllComponent },
  { path: 'digitalPrograms/:title', component: DigitalPlatformsAllComponent },
  { path: 'appsDapps/:title', component: AppsDappsAllComponent },
  { path: 'financingOpportunities/:title', component: FinancingOpportunitienitiesAllComponent },
  { path: 'projects/:title', component: ProjectsAllComponent },
  { path: 'tenders/:title', component: TendersAllComponent },
  { path: 'entrepreneurship', component: EntrepreneurshipComponent },
  { path: 'l-store', component: LStoreComponent },
  { path: 'use-cases', component: UseCasesComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'academy', component: AcademyComponent },
  { path: 'recent-content/:title', component: RecentContentComponent },
  { path: 'video-related-information/:title', component: VideoRelatedInformationComponent },
  { path: 'course-detail/:title', component: CourseDetailAcademyComponent },
  { path: 'certifiable-courses', component: CertifiableCoursesComponent },
  { path: 'academic-videos', component: AcademicVideosComponent },
  { path: 'the-blockchain', component: TheBlockchainComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'subscribe', component: SubscribeAcademyComponent },
  { path: 'academic-publications', component: AcademicPublicationsComponent },

  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

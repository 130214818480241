<div class="col-12">
  <div class="row">
    <img class="image-card-section" [src]="images" alt="">
  </div>
  <div class="row d-flex align-items-center">
    <div class="col-12 p-0 m-0">
      <h3 class="title-card-section pt-3 mb-0">{{title}}</h3>
    </div>
    <div class="col-12 p-0 m-0 text-left">
      <h3 class="category-card-section ">{{type}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0 m-0">
      <p class="description-card-section">{{text}}</p>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-webinar',
  templateUrl: './modal.webinar.component.html',
  styleUrls: ['./modal.webinar.component.scss']
})
export class ModalWebinarComponent implements OnInit {

  title;
  url;
  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }
}

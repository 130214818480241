import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsStoreService {

  constructor(private http: HttpClient) { }

  public sendContactUsStore(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/vnd.api+json',
        'Authorization': 'Basic ' + btoa('admin:S1n@ps1s')
      })
    };
    return this.http.post('https://live-lacchain.pantheonsite.io/jsonapi/node/formulario_l_store', form, httpOptions);
  }

}

import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { UseProjectsService } from 'src/app/core/services/use-projects.service';

@Component({
  selector: 'app-projects-all',
  templateUrl: './projects-all.component.html',
  styleUrls: ['./projects-all.component.scss']
})
export class ProjectsAllComponent implements OnInit {

  useProjectsId: any = {};
  useProjects: any[] = [];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  indrag = false;
  useProjectsIdOpens = false;
  useProjectsOpens = false;

  ngOnInit(): void {
    this.getUseProjectsId(false);
    this.getUseProjects();
  }


  constructor(
    private activatedRoute: ActivatedRoute,
    private useProjectsService: UseProjectsService,
    private router: Router) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getUseProjectsId(true);
            this.getUseProjects();
        }
    });
     }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, (title) ]);
    }
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  routerEntities(title: string) {
    return title.replace(' ', '-');
  }

  getUseProjectsId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const entitiesId = this.getEvenIdByName(arg.title);
      this.useProjectsIdOpens = false;
      this.useProjectsService.getUseProjects(`
        {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (entitiesId) : this.useProjectsId.fieldTituloIngles }"
        },
      `,
      1,
      0,
      localStorage.getItem('lang').toUpperCase(), (events, total) => {
        this.useProjectsId =  events[0];
        this.useProjectsIdOpens = true;
      });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getUseProjects() {
    this.useProjectsService.getUseProjects('',
      100,
      0,
      localStorage.getItem('lang').toUpperCase(), (_alliances, total) => {
        this.useProjects =  _alliances;
        this.useProjectsOpens = true;
    });
  }
  scrollTop() {
    window.scrollTo(0, 0);
  }
  routerProjects(title: string) {
    return title.replace(' ', '-');
  }

}

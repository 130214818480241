<div class="col-12 card-slider-event-container p-0 m-0">
  <img class="image-event w-100 h-100" [src]="images" alt="">
  <div class="gradient-new-home p-0 m-0">
    <div class="row">
      <div class="col-12 d-flex flex-column">
      </div>
    </div>
    <h3 class="title-card-section title-card-event position-title-event pt-3 d-flex justify-content-between">
      <h3 class="spOne">{{title}}</h3>
      <div class="sect-icon">
        <span class="spTwo">{{year | date:'yyyy'}}</span>
        <em class="fas fa-graduation-cap" *ngIf="isVideoCurse !== 'videos_educativos'"></em>
        <em class="fab fa-youtube" *ngIf="isVideoCurse === 'videos_educativos'"></em>
      </div>
    </h3>
    <p>{{text}}</p>
  </div>
</div>

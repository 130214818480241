<div class="col-12 card-event-container p-0 m-0">
  <img class="image-event w-100 h-100" [src]="images" alt="">
  <div class="gradient-event p-0 m-0">
    <div class="row">
      <div class="col-12 d-flex flex-column">
        <div *ngIf="formatDate" class="col-3 card-date-event mt-3" [ngClass]="{'back-academy': colorAcademy === 'eventos_academia'}">
          <h3 class="title-card-section title-card-event p-0 m-0 text-center"
            [ngClass]="{'back-academy': colorAcademy === 'eventos_academia'}"><span class="number-event">
              {{formatDate | date:'d' }}
            </span><br>
            {{formatDate | date:'MMM' }}
          </h3>
        </div>
      </div>
    </div>
    <h3 class="title-card-section title-card-event-alliance position-title-event pt-3">
      {{title}}</h3>
    <p class="description-event-alliance">{{text}}</p>
  </div>
</div>

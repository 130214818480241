import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  private entities: any[] = [

  ];

  private entitiesGraphl = {
    "data": {
      "nodeQuery": {
        "count": 7,
        "entities": [
          {
            "title": "Test",
            "fieldSede": "Medellín",
            "fieldFechaDeConstitucion": {
              "date": "2020-05-13 12:00:00 UTC"
            },
            "fieldPuntoDeContactoEnti": "mail@mail.com",
            "fieldImagenComunidad": {
              "url": "https://live-lacchain.pantheonsite.io/sites/default/files/2020-05/slider3_1.jpg"
            },
            "body": {
              "summary": "Testtt",
              "processed": "<p>Testtttttttttttttttttttttt</p>"
            }
          }
        ]
      }
    }
  };

  constructor(private apollo: Apollo) { }

  getEntities(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getEntitiesQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getEntitiesQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query entidades {
          nodeQuery(limit: ${limit}, offset: ${offset}, sort: {field: "title", direction: ASC}, filter: {conditions: [{operator: EQUAL, field: "status", value: "1" language: ${lang} }, {operator: EQUAL, field: "type", value: "Entidades"}${filter}]}) {
            count
            entities {
              ... on NodeEntidades {
                title
                fieldTituloIngles
                uuid
                fieldContactoEntidad
                body {
                  processed
                  summary
                }
                fieldExpertiseEntidad
                fieldFechaEntidad
                fieldImagenComunidad {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldContactoEntidad
                fieldSede
                fieldSitioWeb {
                  uri
                }
              }
            }
          }
        }
      `;
  }

  getEntitiesId(id) {
    return this.entities[id];
  }
}

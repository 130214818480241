import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AllianceEventsService } from 'src/app/core/services/alliance-events.service';
import { AlliancesService } from 'src/app/core/services/alliances.service';

@Component({
  selector: 'app-alliance-events-all',
  templateUrl: './alliance-events-all.component.html',
  styleUrls: ['./alliance-events-all.component.scss']
})
export class AllianceEventsAllComponent implements OnInit {


  eventsId: any = {};
  events: any[] = [];
  eventsRouletteOpens = false;
  eventDetailOpen = false;
  date: string;

  customOptions: OwlOptions = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
  };
  indrag = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private allianceEventsService: AllianceEventsService,
    private alliancesServices: AlliancesService,
    private router: Router) {
    window.scrollTo(0, 0);

    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getEventsId(true);
            this.getEvents();
        }
    });
    }

  ngOnInit(): void {
    this.getEventsId(false);
    this.getEvents();
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, title]);
    }
  }

  getEventsId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const eventId = this.getEvenIdByName(arg.title);
      this.eventDetailOpen = false;
      this.allianceEventsService.getAllianceEventsService(`
         {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (eventId) : this.eventsId.fieldTituloIngles }"
        },
      `,
      1,
      0, localStorage.getItem('lang').toUpperCase() ,(events, total) => {
        this.eventsId =  events[0];
        this.date = this.eventsId.fieldFechaInicioDeEvento.date.replace(/-/g, '/');
        this.eventDetailOpen = true;
      });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getEvents() {
    this.allianceEventsService.getAllianceEventsService('',
      100,
      0, localStorage.getItem('lang').toUpperCase(),(_alliances, total) => {
        this.events =  _alliances;
        this.eventsRouletteOpens = true;
    });
  }

  scrollTop(){
    window.scrollTo(0, 0);
  }

  routerEventParticipation(title: string) {
    return title.replace(' ', '-');
  }

}

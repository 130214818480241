<nav class="navbar navbar-expand-lg nav-menu">
  <a class="navbar-brand" [routerLink]="['/home']">
    <img class="img-fluid logo" src="../../../../assets/images/logo-lacchain-menu.png" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <em class="fas fa-bars"></em>
  </button>

  <div *ngIf="!isMobile" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto pr-4 text-center text-nowrap">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/home']">{{'inicio' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/alliance']">{{'alianza' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/ecosystem']">{{'ecosistema' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase"
          [routerLink]="['/entrepreneurship']">{{'emprendimiento' | translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/l-store']">{{'lstore' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/use-cases']">{{'casosdeuso' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/the-blockchain']">{{'lablockchain'
          | translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/resources']">{{'recursos' |
          translate}}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/academy']">{{'academia' |
          translate}} </a>
      </li>
      <li class="nav-item">
        <a *ngIf="!defaultSpanish" class="nav-link hvr-underline-from-center text-uppercase"
          [ngClass]="{'active': activeLang === 'es'}" (click)="useLanguage('es')"><em
            class="fas fa-globe-americas"></em> {{'espanol' | translate}}</a>
        <a *ngIf="defaultSpanish" class="nav-link hvr-underline-from-center text-uppercase"
          [ngClass]="{'active': activeLang === 'en'}" (click)="useLanguage('en')"><em
            class="fas fa-globe-americas"></em> {{'ingles' | translate}}</a>
      </li>
    </ul>
  </div>

  <div *ngIf="isMobile" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto pr-4 text-center">
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/home']">{{'inicio' |
          translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/alliance']">{{'alianza' |
          translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/ecosystem']">{{'ecosistema' |
          translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase"
          [routerLink]="['/entrepreneurship']">{{'emprendimiento' | translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/l-store']">{{'lstore' |
          translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/use-cases']">{{'casosdeuso' |
          translate}}</a>
      </li>

      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/the-blockchain']">{{'lablockchain'
          | translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/resources']">{{'recursos' |
          translate}}</a>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false">
        <a class="nav-link hvr-underline-from-center text-uppercase" [routerLink]="['/academy']">{{'academia' |
          translate}}</a>
      </li>
      <li class="nav-item">
        <a *ngIf="!defaultSpanish" class="nav-link hvr-underline-from-center text-uppercase"
          [ngClass]="{'active': activeLang === 'es'}" (click)="useLanguage('es')"><em
            class="fas fa-globe-americas"></em> {{'espanol' | translate}}</a>
        <a *ngIf="defaultSpanish" class="nav-link hvr-underline-from-center text-uppercase"
          [ngClass]="{'active': activeLang === 'en'}" (click)="useLanguage('en')"><em
            class="fas fa-globe-americas"></em> {{'ingles' | translate}}</a>
      </li>
    </ul>
  </div>
</nav>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-event-alliance',
  templateUrl: './card-event-alliance.component.html',
  styleUrls: ['./card-event-alliance.component.scss']
})
export class CardEventAllianceComponent implements OnInit {

  @Input() title: string;
  @Input() date: string;
  @Input() images: string;
  @Input() text: string;
  @Input() sponsor: string;
  @Input() time: string;
  formatDate: any;

  constructor() {
  }

  ngOnInit(): void {
    this.formatDate = this.date.replace(/-/g, '/');
  }

}

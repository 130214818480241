import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-our-news',
  templateUrl: './card-our-news.component.html'
})
export class CardOurNewsComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() type: string;
  @Input() images: string;
  @Input() fuente: string;
  @Input() color: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}


export interface DataBanner {
  image: string;
  title: string;
  description: string;
  description1: string;
  color: string;
  isButton: boolean;
  textButton: string;
  isScroll: boolean;
  longDescription: boolean;
}

export const allianceTextLine: any = [
  {

    descriptionInfra: 'infraestructuradesc1',

  }];

export const  allianceData: any = [
    {
      image: '../../../assets/images/cities/alianza.png',
      icon: '../../../assets/images/cities/Icono-alianza.png',
      title: 'alianzalacchain',
      description: 'alianzalacchaindesc',
      color: '#DE4769',
      isButton: true,
      textButton: 'quieresseraliado',
      isScroll: false,
      longDescription: false
}];

export const  ecosystemsData: any = [
    {
      image: '../../../assets/images/cities/ecosistema.png',
      icon: '../../../assets/images/cities/Icono-ecosistema.png',
      title: 'ecosistema',
      description: 'ecosistemadescgeneral',
      color: '#FFCC00',
      isButton: false,
      textButton: '',
      isScroll: false,
      longDescription: false
}];

export const  entrepreneurshipData: any = [
    {
      image: '../../../assets/images/cities/emprendimiento.png',
      icon: '../../../assets/images/cities/Icono-emprendimiento.png',
      title: 'apoyamoselemprend',
      description: 'apoyamoselemprenddesc1',
      description1: 'apoyamoselemprenddesc2',
      color: '#00715D',
      isButton: false,
      textButton: 'lanzaderadestartups',
      isScroll: true,
      longDescription: true
}];

export const  lStoreData: any = [
    {
      image: '../../../assets/images/cities/l-store.png',
      icon: '../../../assets/images/cities/Iconol-store.png',
      title: 'lstore',
      description: 'lstoredescgeneral',
      color: '#5CA9DD',
      isButton: true,
      textButton: 'lstorecontacto',
      isScroll: false,
      longDescription: false
}];

export const  useCasesData: any = [
    {
      image: '../../../assets/images/cities/casos-de-uso.png',
      icon: '../../../assets/images/cities/Icono-casos-de-uso.png',
      title: 'casosdeuso',
      description: 'casosdeusodescgeneral',
      color: '#F58820',
      isButton: true,
      textButton: 'contactanos',
      isScroll: false,
      longDescription: false
}];

export const  resourcesData: any = [
    {
      image: '../../../assets/images/cities/recursos.png',
      icon: '../../../assets/images/cities/Icono-recursos.png',
      title: 'recursos',
      description: 'recursosdesc1',
      description1: 'recursosdesc2',
      description2: 'recursosdesc3',
      description3: 'recursosdesc4',
      color: '#74C2BF',
      isButton: true,
      textButton: 'quieresparticipar',
      isScroll: true,
      longDescription: false
}];

export const  theBlockchainData: any = [
    {
      image: '../../../assets/images/cities/blockchain.png',
      icon: '../../../assets/images/cities/Icono-blockchain.png',
      title: 'lablockchain',
      description: 'lablockchaindescgeneral',
      color: '#2F66B0',
      isButton: false,
      textButton: '',
      isScroll: false,
      longDescription: false
}];

export const academyItemsData: any = [
  {
    image: '../../../assets/images/cities/Academy.png',
    icon: '../../../assets/images/cities/Icono-academy.png',
    title: 'academy',
    description: 'academydescgeneral',
    color: '#09B89D',
    isButton: true,
    textButton: 'suscribir',
    isScroll: false,
    longDescription: false
  }];


export const organizationAlliance: any = [
         {
           id: '01111',
          title: 'introduccion',
           number: 1,
          detail: 'orgtecnointro',
         },
         {
           id: '02222',
           title: 'soluciones',
           number: 2,
           detail: 'orgtecnosolu',
         },
         {
           id: '03333',
           title: 'infraestructura',
           number: 3,
           detail: 'orgtecnoinfra',
         },
       ];

export const organizationAllianceCommunication: any = [
  {
    id: '01111',
    title: 'introduccion',
    number: 1,
    detail: 'orgcomintro',
  }
];

export const organizationAllianceStandard: any = [
  {
    id: '01111',
    title: 'introduccion',
    number: 1,
    detail: 'orgestandntro',
  }
];

export const organizationAllianceLegal: any = [
  {
    id: '01111',
    title: 'responsabilidadalianza',
    number: 1,
    detail: 'responsabilidadalianzadescripcion',
  },
  {
    id: '02222',
    title: 'gestorneutralalianza',
    number: 2,
    detail: 'gestorneutralalianzadescripcion',
  },
  {
    id: '03333',
    title: 'incentivosysostenibilidadalianza',
    number: 3,
    detail: 'incentivosysostenibilidadalianzadescripcion',
  },

];

export const productionTheBlochain: any = [
  {
    id: '01111',
    title: 'queeslnet',
    number: 1,
    detail: 'queeslnetcomplet',
  },
  {
    id: '02222',
    title: 'quenoeslnet',
    number: 2,
    detail: 'quenoeslnetcomplet',
  },

];

export const homeOcdeGov: any = [
  {
    id: '01111',
    title: 'cumplimiento',
    number: 1,
    detail: 'lacchaincumplimiento',
  },
  {
    id: '02222',
    title: 'gobyrendicioncuen',
    number: 2,
    detail: 'lacchagobtransrendicion',
  },
  {
    id: '03333',
    title: 'interoperatividad',
    number: 3,
    detail: 'lacchagointerop',
  },
  {
    id: '04444',
    title: 'seguridaddigital',
    number: 4,
    detail: 'lacchaseguridaddigi',
  },
  {
    id: '05555',
    title: 'eduydesdehabil',
    number: 5,
    detail: 'lacchaedudeshabil',
  }

];

export const homeLacchain: any = [
  {
    id: '01111',
    title: 'tecnoblockchain',
    number: 1,
    detail: 'lacchaintecno',
  },
  {
    id: '02222',
    title: 'promobidlab',
    number: 2,
    detail: 'lacchainpromo',
  },
  {
    id: '03333',
    title: 'paratodalaregion',
    number: 3,
    detail: 'lacchainpararegion',
  },
  {
    id: '04444',
    title: 'contestnetact',
    number: 4,
    detail: 'lacchaincontestenet',
  },
  {
    id: '05555',
    title: 'parausosreales',
    number: 5,
    detail: 'lacchainparausoreales',
  }

];

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="entrepreneurshipDataBanner['title']"
    [description]="entrepreneurshipDataBanner['description']" [image]="entrepreneurshipDataBanner['image']"
    [description1]="entrepreneurshipDataBanner['description1']" [color]="entrepreneurshipDataBanner['color']"
    [isButton]="entrepreneurshipDataBanner['isButton']" [textButton]="entrepreneurshipDataBanner['textButton']"
    [isScroll]="entrepreneurshipDataBanner['isScroll']"
    [longDescription]="entrepreneurshipDataBanner['longDescription']" [icon]="entrepreneurshipDataBanner['icon']">
  </app-banner-sites>



  <div class="container-fluid section-gray">
    <div class="container-entrepreneurship">
      <div class="row">
        <div class="col-12 col-md-2">
          <img class="rocket" src="../../../assets/images/rocket.svg" alt="" data-aos="fade-up"
            data-aos-duration="3000">
        </div>
        <div class="col-12 col-md-7 d-flex justify-content-center flex-wrap align-content-center">
          <p class="paragraph-banners">{{'apoyamoselemprenddesc3' | translate}}</p>
        </div>
        <div class="col-12 col-md-3 d-flex justify-content-center flex-wrap align-content-center">
          <a type="button" class="btn button-plact "
            href="https://docs.google.com/forms/d/e/1FAIpQLSfMW9sCHk5R1dj_Q1n6syZ05HvgMP2aW67kTXK623WzCc5p8A/viewform?usp=sf_link"
            target="_blank">{{'platicanosdetuproyecto' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Section Financing opportunities -->
  <div class="container-fluid py-5 padding-pagination" (keyup.enter)="searchOpportunitie()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections-gray mb-4 pl-4">{{'oportunidadesdefinanciacion' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap">
        <div class="col-md-12 col-sm-12">
          <div class="search my-4">
            <input type="text" [(ngModel)]="searchOpportunitieValue" class="searchTerm"
              placeholder="{{'buscaremprendimiento' | translate}}">
            <button type="button" (click)="searchOpportunitie()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
          <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
        </div>
        <div class="row container-sites">
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="content-dropdown dropdown-advanced-search d-flex align-items-center" *ngIf="typeOpen">
                    <p class="text-search-advanced p-0 m-0 mr-3">{{'tipodeproyecto' | translate}}:</p>
                    <kendo-dropdownlist [data]="listTypeEntrepreneur" (selectionChange)="selectionType($event)"
                      [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="content-dropdown dropdown-advanced-search d-flex align-items-center">
                    <p class="text-search-advanced p-0 m-0 mr-3">{{'tiposolicita'  | translate}}:</p>
                    <kendo-dropdownlist [data]="listTypeEntrepreneurSupport"
                      (selectionChange)="selectionTypeSupport($event)">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites" *ngIf="financingOpportunitiesOpen">
      <pagination-controls id="opportunities" (pageChange)="getPage($event)" previousLabel="Anterior"
        nextLabel="Siguiente">
      </pagination-controls>
      <div *ngIf="inTransact.opportunities" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of financingOpportunities | paginate: {
            id: 'opportunities', itemsPerPage: limit, currentPage: currentPage, totalItems: total
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/financingOpportunities', routerFinancingOpportunities(item.fieldTituloIngles)]"
            class="btn  btn-block mt-2" role="button">
            <app-card-financing-opportunities [title]="item.title" [text]="item.body.summary" [date]="item.date"
              [images]="item.fieldImagenPreview.url">
            </app-card-financing-opportunities>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found" *ngIf="financingOpportunities.length === 0">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <section class="section-banner-two d-flex align-items-center mt-5">
    <div class="col-12 mt-8">
      <div class="col-12 col-md-8">
        <div class="container-banner-two">
          <h2 class="title-banners alliance-color-text color-white">{{'inversion' | translate}}</h2>
          <hr class="line-title-banner color-white">
          <div class="sec-text mb-4">
            <p>{{'inversiondesc1' | translate}}</p>
            <p>{{'inversiondesc2' | translate}}</p>
            <p>{{'inversiondesc3' | translate}}</p>
          </div>
          <a class="btn btn-banner-two"
            [routerLink]="['/contact']"><strong>{{'contactanosparainvertir' | translate}}</strong></a>
        </div>
      </div>
    </div>
  </section>


</div>
<app-footer></app-footer>

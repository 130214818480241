<div class="col-12">
  <div class="row">
    <img class="image-card-section" [src]="images" alt="">
  </div>
  <div class="row d-flex align-items-center">
    <div class="col-8 p-0 m-0">
      <h3 class="title-card-section pt-3">{{title}}</h3>
    </div>
    <div class="col-4 p-0 m-0 text-right">
      <h3 class="category-card-section pt-3">{{budget}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0 m-0">
      <p class="description-card-section">{{text}}</p>
    </div>
  </div>
</div>

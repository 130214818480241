<div class="alliance-page m-0 p-0">
  <div class="row m-0 p-0">
    <div class="col-md-12">
      <div class="pb-2 pt-4 m-0 responsive-card-alliance">
        <div class="row">
          <div class="col-12 col-md-4 col-responsive-pad">
            <div class="number-alliance" id="scroll-allianceOne">
              <button class="btn-item-alliance btn btn-link  d-flex flex-wrap align-content-center"
                *ngFor="let organizationAlliance of currentInfoLocal; let i= index" [class.active]="selectedIndex === i"
                (click)="setIndex(i)">
                <div class="number-intem col-1">
                  <button class="btn btn-number-number">
                    {{ organizationAlliance.number}}
                  </button>
                </div>
                <div class="text-info-number col-10">
                  <p> {{organizationAlliance.title | translate}} </p>
                </div>
              </button>
            </div>
          </div>
          <div class="col-12 col-md-8 col-responsive-pad">
            <div class="sect-infotext">
              <div class="item-sect-infotext mb-5" id="scroll-allianceTwo">
                <div *ngIf="!showHtmlRecommendation" innerHTML="{{htmlStr | translate }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-5">
  <div class="row container-sites">
    <div class="col-12 d-flex justify-content-center align-items-center flex-wrap">
      <div class="col-md-6  col-sm-12 container-imagen-city">
        <img class="img-fluid img-icon" [src]="icon" alt="">
        <img class="img-fluid img-city mb-5" [src]="image" alt="">
      </div>
      <div class="col-md-6 col-sm-12">
        <h1 [ngStyle]="{'color': color}" class="title-banners alliance-color-text text-uppercase">{{title | translate}}
        </h1>
        <hr [ngStyle]="{'border-color': color}" class="line-title-banner">
        <P *ngIf="description1 == undefined" [ngClass]="{'paragraph-banners-scroll':isScroll}"
          class="paragraph-banners pr-1" id="scroll-paragraph">{{description | translate}}</P>
        <P *ngIf="description1 != undefined && description2 == undefined"
          [ngClass]="{'paragraph-banners-scroll':isScroll}" class="paragraph-banners pr-1" id="scroll-paragraph">
          {{description | translate}}<br><br>{{description1 | translate}}<br><br>{{description2 | translate}}<br><br>{{description3 | translate}}
        </P>
        <P *ngIf="description1 != undefined && description2 !== undefined"
          [ngClass]="{'paragraph-banners-scroll':isScroll}" class="paragraph-banners pr-1" id="scroll-paragraph">
          {{description | translate}}<br><br>{{description1 | translate}}<br><br>{{description2 | translate}}<br><br>{{description3 | translate}}
        </P>
        <button *ngIf="isButton" [ngStyle]="{'background-color': color}" type="button" class="btn button-banner mb-5"
          (click)="redirect()">{{textButton | translate}}</button>
      </div>
    </div>

  </div>
</div>

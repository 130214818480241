<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="financingOpportunitiesIdsOpens">
    <div class="container-sites">
      <div class="row">
        <div class="col-12 col-md-6 center pt-5">
          <img class="img-fluid img-org" [src]="financingOpportunitiesId['fieldImagenComunidad']['url']" alt="">
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-entrepreneurship">
            {{financingOpportunitiesId['title']}}
          </h2>
          <hr class="line-title-banner color-entrepreneurship">
          <span class="d-block color-entrepreneurship">
            {{'empresa' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldEmpresa']}} </p>
          </span>
          <span class="d-block color-entrepreneurship">
            {{'descripcion' | translate}}:
          </span>
          <p class="p-nomaice" [innerHTML]="financingOpportunitiesId['body']['processed']"></p>
          <span class="d-block color-entrepreneurship">
            {{'tipodeproyecto' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldTipoDeProyecto']}} </p>
          </span>
          <span class="d-block color-entrepreneurship">
            {{'categoria' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldCategoria']}} </p>
          </span>
          <span class="d-block color-entrepreneurship">
            {{'tiposolicita' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldTipoDeApoyoQueSolicita']}} </p>
          </span>
          <span class="d-block color-entrepreneurship">
            {{'puntocontacto' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldPuntoContactoOportunidad']}} </p>
          </span>
          <span class="d-block color-entrepreneurship">
            {{'sitioweb' | translate}}:
            <p class="p-nomaice d-inline-block"> {{financingOpportunitiesId['fieldSitioWeb']['uri']}} </p>
          </span>

        </div>
      </div>

      <div class="row ml-4">
        <div class="col-12 info-all mt-4 padding-section-responsive">
          <div class="col-md-12">

          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="our-allies" *ngIf="financingOpportunitiesOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of financingOpportunities; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/financingOpportunities', routerFinancingOpportunities(item.fieldTituloIngles))"
                class="btn  btn-block mt-2 maxw28rem" role="button">
                <app-card-financing-opportunities [title]="item.title" [text]="item.body.summary" [date]="item.date"
                  [images]="item.fieldImagenPreview.url">
                </app-card-financing-opportunities>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

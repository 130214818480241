import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {

  private communities: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getCommunities(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getCommunitiesQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getCommunitiesQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query NodeComunidades($limit: Int= ${limit}, $offset: Int = ${offset}){
        nodeQuery(
          limit:$limit,
          offset:$offset,
          filter:{
            conditions:[
              {
                operator: EQUAL
                field: "status"
                value: "1"
                language: ${lang}
              }
              {
                operator: EQUAL
                field: "type"
                value: "Comunidades"
              }
            ${filter}]
          },
          sort:[
            {field: "title", direction: ASC}
          ]
        ){
          count
          entities{
            ... on NodeComunidades{
              entityPublished
              type {
                targetId
              }
              promote
              status
              title
              fieldTituloIngles
              uuid
              fieldSedeComunidad
              fieldFechaComunidad
              fieldPuntoDeContactoComunida
              body {
                summary
                processed
              }
              created
              fieldImagenComunidad {
                targetId
                alt
                title
                width
                height
                url
              }
              fieldImagenPreview {
                  url
              }
              fieldSitioWeb {
                uri
                title
                options
              }
              fieldTipoDeComunidad
            }
          }
        }
      }
      `;

  }

  getCommunitiesId(id) {
    return this.communities[id];
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-tender',
  templateUrl: './card-tender.component.html',
  styleUrls: ['./card-tender.component.scss']
})
export class CardTenderComponent implements OnInit {

@Input() title: string;
@Input() text: string;
@Input() budget: string;
@Input() images: string;

  constructor() { }

  ngOnInit(): void {
  }

}

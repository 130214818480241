import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class DigitalProgramsService {

  private digitalPrograms: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getPlatforms(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query plataformas_digitales {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "title", direction: ASC },
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Soluciones"}
              ${filter}]}
          ) {
            count
            entities {
              ... on NodeSoluciones {
                title
                fieldTituloIngles
                uuid
                fieldCategoriaSoluciones
                body {
                  processed
                  summary
                }
                fieldEstadoSolucion
                fieldGithub {
                  uri
                }
                fieldIdiomasDisponibles
                fieldImagen {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldPlataforma
                fieldPuntoContactoSoluciones
                fieldSedeLegalSolucion
                fieldSitioDescargaSolucion {
                  uri
                }
                fieldSitioWebSoluciones {
                  uri
                }
                fieldTipoSolucion
                fieldTitularidadSoluciones
              }
            }
          }
        }
      `;
  }

  getDigitalProgramsId(id) {
    return this.digitalPrograms[id];
  }
}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class OurNewsService {


  private ourNews: any[] = [


  ];

  constructor(private apollo: Apollo) { }

  getOurResources(lang, callback) {
    this.apollo.query<any>({
      query: this.getQueryOurResources('', '', '', lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQueryOurResources(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query lacchain_en_medios {
          nodeQuery(
            sort: {field: "created", direction: DESC},
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Lacchain_En_Medios"}
              ${filter}]}
          ) {
            count
            entities {
              ... on NodeLacchainEnMedios {
                title
                fieldDescripcionMedio
                fieldEnlace {
                  title
                  uri
                }
                fieldImagenMedios {
                  url
                }
              }
            }
          }
        }
      `;
  }

  getOurNews(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query noticias {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "created", direction: DESC},
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Noticias"}
              ${filter}
              ]}
          ) {
            count
            entities {
              ... on NodeNoticias {
                title
                fieldTituloIngles
                uuid
                body {
                  processed
                  summary
                }
                fieldFechaPublicacionNoticia {
                  date
                }
                fieldFuentes {
                  title
                  uri
                }
                fieldImagenPreview {
                  url
                }
                fieldImagenNoticia {
                  url
                }
              }
            }
          }
        }
      `;
  }

  getOurNewsId(id) {
    return this.ourNews[id];
  }
}

import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-schedule-call-banner',
  templateUrl: './schedule-call-banner.component.html',
  styleUrls: ['./schedule-call-banner.component.scss'],
})
export class ScheduleCallBannerComponent {
  public showElement = false;
  private hasShownElement = false;
  @HostListener('window:scroll')
  onScroll() {
    if (this.hasShownElement) return;
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition > 600) {
      this.showElement = true;
      this.hasShownElement = true;
    }
  }

  hideElement() {
    this.showElement = false;
    this.hasShownElement = true;
  }
}

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 center pt-5">
          <img class="img-fluid img-org" [src]="tendersId['fieldImagenLicitacion']['url']" alt="">
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners color-projects">
            {{tendersId['title']}}
          </h2>
          <hr class="line-title-banner color-projects">
          <p class="p-nomaice">{{tendersId['body']['summary']}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of tenders; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/tenders', routerTenders(item.fieldTituloIngles))" class="btn  btn-block mt-2 maxw28rem"
                role="button">
                <app-card-tender [title]="item.title" [text]="item.body.summary" [budget]="item.fieldPresupuesto"
                  [images]="item.fieldImagenPreview.url">
                </app-card-tender>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

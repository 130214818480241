import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class FinancingOpportunitiesService {

  private financingOpportunities: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getFinancingOpportunities(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query oportunidades_de_financiacion {
            nodeQuery(
              limit: ${limit},
              offset: ${offset},
              sort: {field: "title", direction: ASC },
              filter: {conditions: [{operator: EQUAL, field: "status", value: "1" language: ${lang} }, {operator: EQUAL, field: "type", value: "Oportunidades_De_Financiacion"}${filter}]}) {
              count
              entities {
                ... on NodeOportunidadesDeFinanciacion {
                  title
                  fieldTituloIngles
                  uuid
                  fieldCategoria
                  body {
                    processed
                    summary
                  }
                  fieldImagenComunidad {
                    url
                  }
                  fieldImagenPreview {
                    url
                  }
                  fieldEmpresa
                  fieldPuntoContactoOportunidad
                  fieldSitioWeb {
                    uri
                  }
                  fieldTipoDeApoyoQueSolicita
                  fieldTipoDeProyecto
                }
              }
            }
          }
      `;

  }

  getFinancingOpportunitiesId(id) {
    return this.financingOpportunities[id];
  }
}

import { Component, OnInit } from '@angular/core';
import { resourcesData } from '../../core/mocks/data-banners';
import { OurNewsService } from 'src/app/core/services/our-news.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BlogsService } from 'src/app/core/services/blogs.service';
import { PublicationsService } from 'src/app/core/services/publications.service';
import { WebinarsService } from 'src/app/core/services/webinars.service';
import { Router } from '@angular/router';
import { ModalWebinarComponent } from 'src/app/shared/components/modal-webinar/modal.webinar.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
public resourcesDataBanner: any[] = [];
  ourResources;
  ourResoucesOpens = false;
  resources;
  videoUrl: string = 'https://player.vimeo.com/video/239566739?color=ffffff&title=0&byline=0&portrait=0';

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  searchPressValue = '';
  resourcesOpens = false;
  limitResources = 6;
  totalResources = 0;
  currentPageResources = 0;
  currentPageBlogs = 0;
  totalBlogs = 0;
  limitBlogs = 6;
  blogsOpens = false;
  blogs;
  publicationsOpens = false;
  totalPublications = 0;
  limitPublications = 6;
  publications;
  currentPagePublications = 0;
  webinarsOpens = false;
  webinars;

  modalRef: BsModalRef;
  urlRedirect = 'contact';
  public inTransact = {
    prensa: false,
  };
  indrag = false;

  constructor(
    private ourNewsService: OurNewsService,
    private blogsService: BlogsService,
    private publicationsService: PublicationsService,
    private webinarsService: WebinarsService,
    private router: Router,
    private modalService: BsModalService,
    private seoService: SeoService,
    private title: Title
  ) {
    this.resourcesDataBanner = resourcesData[0];
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.ngOnInit();
        }
    });
   }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.router.navigate([link, title]);
    }
  }

  searchPress() {
    if (this.searchPressValue !== '') {
      this.getOurNews(`{field:"title", operator: LIKE, value: "%${this.searchPressValue}%"}`);
    } else {
      this.getOurNews('');
    }
  }

  ngOnInit(): void {
    this.getOurNews('');
    this.getOurResources('');
    this.getBlogs('');
    this.getPublications('');
    this.getWebinars('');
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Recursos | LACChain es el resultado de un trabajo colectivo, el desarrollo de múltiples acciones de información y colaboración',
      site_name: 'LCChain',
      description: 'LACChain es el resultado de un trabajo colectivo, el desarrollo de múltiples acciones de información y colaboración que se traducen en la suma de...',
      slug: 'resources',
      image: 'https://www.lacchain.net/assets/images/cities/recursos.png'
    });
    this.title.setTitle('Recursos | LACChain es el resultado de un trabajo colectivo, el desarrollo de múltiples acciones de información y colaboración');
  }

  getPageResources(page: number) {
    this.currentPageResources = page;
    this.getOurNews('');
  }

  getPageBlogs(page: number) {
    this.currentPageBlogs = page;
    this.getBlogs('');
  }

  getBlogs(filter: string) {
    this.blogsService.getBlogs(filter,
      this.limitBlogs,
      this.getOfsset(this.currentPageBlogs, this.limitBlogs),
      localStorage.getItem('lang').toUpperCase(), (_blogs, total) => {
      this.blogs =  _blogs;
      this.totalBlogs = total;
      this.blogsOpens = true;
    });
  }

  getPagePublications(page: number) {
    this.currentPagePublications = page;
    this.getPublications('');
  }

  getPublications(filter: string) {
    this.publicationsService.getPublications(filter,
      this.limitPublications,
      this.getOfsset(this.currentPagePublications, this.limitPublications),
      localStorage.getItem('lang').toUpperCase(), (_publications, total) => {
      this.publications =  _publications;
      this.totalPublications = total;
      this.publicationsOpens = true;
    });
  }

  getOurResources(filter: string) {
    this.ourNewsService.getOurResources(localStorage.getItem('lang').toUpperCase(), (_ourResources, total) => {
      this.ourResources = _ourResources;
      this.ourResoucesOpens = true;
    });
  }

  getWebinars(filter: string) {
    this.webinarsService.getWebinars(localStorage.getItem('lang').toUpperCase() ,(_webinars, total) => {
      this.webinars = _webinars;
      this.webinarsOpens = true;
    });
  }

  getOurNews(filter: string) {
    this.inTransact.prensa = true;
    this.ourNewsService.getOurNews(filter,
      this.limitResources,
      this.getOfsset(this.currentPageResources, this.limitResources),
      localStorage.getItem('lang').toUpperCase(), (_resources, total) => {
      this.inTransact.prensa = false;
      this.resources =  _resources;
      this.totalResources = total;
      this.resourcesOpens = true;
    });
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  showWebinar(urlIframe: string): void {
    if (!this.indrag) {
      this.modalRef = this.modalService.show(ModalWebinarComponent,  {
        initialState: {
          title: 'Modal title',
          url: urlIframe
        }
      });
    }
  }

  routerOurNews(title: string) {
    return title.replace(' ', '-');
  }

}

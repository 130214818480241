import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'angular2-notifications';
import { ContactUsStoreService } from 'src/app/core/services/forms/contact.us.store.service';
import { SeoService } from '../../../core/services/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  showHidenInput: boolean;

  status = false;

  contact = {
    typeProyect: '',
    state: '',
  };
  support = {
    aliado: 'Ser aliado',
    licitacion: 'Anunciar Licitacion',
    financiacion: 'Anunciar financiacion',
    difundirConm: 'Difundir comunidad',
    promocionarP: 'Promocionar Proyecto',
    coOtro: 'Otro',
    data: []
  };
  aliado = false;
  licitacion = false;
  financiacion = false;
  difundirConm = false;
  promocionarP = false;
  coOtro = false;

  submitted = false;

  public form: FormGroup;
  get f() { return this.form.controls; }


  constructor(
    private contactUsService: ContactUsStoreService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private seoService: SeoService,
    private title: Title
    )
    {
      this.loadValidations();
    }

  ngOnInit(): void {
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'LACChain Contacto',
      site_name: 'LCChain',
      description: 'LACChain Contacto',
      slug: 'contact',
      image: ''
    });
    this.title.setTitle('LACChain Contacto');
  }


  private loadValidations(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      contactPhone: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      businessRepresent: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(1024)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      categoriaOtro: ['', Validators.compose([Validators.maxLength(50)])],
    });
  }



  SendProyect(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const status = this.validateSupport();

    if (this.coOtro && this.f.categoriaOtro.value === '') {
      return;
    }

    if (!status) {
      return;
    }

    const form =
    {
      'data': {
        'type': 'node--formulario_l_store',
        'attributes': {
          'langcode': localStorage.getItem('lang'),
          'title': this.f.name.value,
          'field_categoria_formulario':
            this.support.data.join(',') +
            (', ' + (this.coOtro) ? (((this.support.data.length > 0) ? ',' : '') + this.f.categoriaOtro.value) : ''),
          'field_correo_electronico': this.f.email.value,
          'field_mensaje': this.f.description.value,
          'field_nombre_de_la_compania': this.f.businessRepresent.value,
          'field_telefono': this.f.contactPhone.value,
        }
      }
    };

    this.contactUsService.sendContactUsStore(form).subscribe((result: any) => {
      if (result.data.id !== null) {
        this.notificationService.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error('error on insert form');
      }
    })
  }

  validateSupport(): boolean {
    let status = false;
    const noSocial = this.support.data.filter(x => x === this.support.aliado === false).length === 0;

    if (this.aliado) {
      if (noSocial) {
        this.support.data.push(this.support.aliado);
      }
      status = true;
    } else {
      if (!this.aliado && !noSocial) {
        this.support.data = this.support.data.filter((x, y) => x !== this.support.aliado);
      }
    }

    const noFinanzas = this.support.data.filter(x => x === this.support.licitacion).length === 0;
    if (this.licitacion) {
      if (noFinanzas) {
        this.support.data.push(this.support.licitacion);
      }
      status = true;
    } else {
      if (!this.licitacion && !noFinanzas) {
        this.support.data = this.support.data.filter((x, y) => x !== this.support.licitacion);
      }
    }

    const noDesarrollo = this.support.data.filter(x => x === this.support.financiacion).length === 0;
    if (this.financiacion) {
      if (noDesarrollo) {
        this.support.data.push(this.support.financiacion);
      }
      status = true;
    } else {
      if (!this.financiacion && !noDesarrollo) {
        this.support.data = this.support.data.filter((x, y) => x !== this.support.financiacion);
      }
    }

    const noCoSeguridad = this.support.data.filter(x => x === this.support.difundirConm).length === 0;
    if (this.difundirConm) {
      if (noCoSeguridad) {
        this.support.data.push(this.support.difundirConm);
      }
      status = true;
    } else {
      if (!this.difundirConm && !noCoSeguridad) {
        this.support.data = this.support.data.filter((x, y) => x !== this.support.difundirConm);
      }
    }

    const noCoGobierno = this.support.data.filter(x => x === this.support.promocionarP).length === 0;

    if (this.promocionarP) {
      if (noCoGobierno) {
        this.support.data.push(this.support.promocionarP);
      }
      status = true;
    } else {
      if (!this.promocionarP && !noCoGobierno) {
        this.support.data = this.support.data.filter((x, y) => x !== this.support.promocionarP);
      }
    }

    if (this.coOtro) {
      status = true;
    }

    this.status = status;

    return status;
  }
  showInput() {
    this.showHidenInput = !this.showHidenInput;
  }
}

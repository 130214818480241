<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500" *ngIf="eventDetailOpen">
  <div class="container-fluid  pt-5 content-one-certifiable-courses">
    <div class="container-sites">
      <div class="wrapper">
        <!-- Sidebar  -->
        <nav id="sidebar" [ngClass]="{'fullHeightSidebar': isShow}">
          <div class="sidebar-header">
            <button type="button" id="sidebarCollapse" class="btn btn-toggled" (click)="desQuitActive()">
              <em class="fas fa-angle-double-right"></em>
            </button>
          </div>
          <ul class="list-unstyled components">
            <h3 class="title-sections color-white mb-3">{{dataId.title}}</h3>
            <h4 class="date-academy">{{dataId.fieldTemporalCurso.date | date: 'dd/MM/yyyy'}}</h4>
            <p>{{dataId.fieldDescripcionCurso.summary}}</p>
            <div *ngIf="dataId.fieldInstructores.length > 0">
              <span class="color-academy">{{'impartidopor' | translate}}:</span>
              <div *ngFor="let instructor of dataId.fieldInstructores">
                {{instructor.entity.name}}
              </div>
            </div>

            <div class="content-guide" *ngIf="moduleOpen">
              <div *ngFor="let module of modules; let j = index"
                [class]="(j === (modules.length - 1)) ? 'sect-guide-lin finish' : 'sect-guide-lin'">
                <div class="title-guide">
                  <h4>
                    {{module.title}}
                  </h4>
                </div>
                <div class="content-body-guide">
                  <div class="button-content" *ngIf="claseOpen">
                    <button *ngFor="let clase of getClasesByModule(module.title, clases); let i = index;"
                      (click)="goToClass(clase)" class="btn btn-select">
                      <span
                        [class]="(activeIndex === getGeneralIndexClassCurse(clase.title)) ? 'on-select' : 'off-select'"></span>
                      <span
                        [class]="(activeIndex === getGeneralIndexClassCurse(clase.title)) ? 'on-select-title' : 'off-select-title'">{{clase.title
                        }}</span>
                      <span *ngIf="clase.fieldDuracionClase?.length > 1" class="color-academy">
                        ({{clase.fieldDuracionClase}})
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ul>
          <div class="content-help">
            <button class="btn btn-help" (click)="onViwModal()">{{'necesitasayuda' | translate}}</button>
          </div>
        </nav>

        <!-- Page Content  -->
        <div id="content" *ngIf="class.open">
          <div class="col-12">
            <div class="sec-title-toggled d-flex justify-content-between">
              <h3 class="title-sections color-academy mb-4"> {{class.data.title}}
                <p class="pt-2 font-time" *ngIf="class.data.fieldDuracionClase">
                  {{'tiempo' | translate}}: <span>{{class.data.fieldDuracionClase}}</span>
                </p>
              </h3>
              <button type="button" *ngIf="hiddeninfoMenu" class="btn  btn-toggled" (click)="quitActive()">
                <em class="fas fa-bars"></em>
              </button>
            </div>
            <p *ngIf="class.data.body !== null" class="color-text-academy" [innerHTML]="class.data.body.processed"></p>
            <div *ngIf="class.data.fieldLink" class="sect-video d-flex flex-wrap align-content-center"
              [ngStyle]="{'max-width':activeMedium ? '750px' : '580px'}">
              <iframe [src]="class.data.fieldLink.uri | domSafe" [width]="widthIframe" title="Video" [height]="heightIframe"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                style=" border-radius: 10px;">
              </iframe>
              <button mat-button class="btn btn-max-min" (click)="iframeMedium()">
                <em class="fas fa-expand-alt"></em>
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngIf="class.data.fieldAdjuntosClase.length > 0">
                  <h2 class="color-academy">{{'adjuntosclase' | translate}}:</h2>
                  <div class="d-flex">
                    <div *ngFor="let attach of class.data.fieldAdjuntosClase">
                      <div class="sect-content-info">
                        <button class="btn">
                          <em class="fas fa-file-download icon-download color-academy"></em><br><a class="color-academy"
                            href="{{attach.entity.url}}" target="_blank">{{attach.entity.filename}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sect-content-info d-flex justify-content-between">
              <div class="sect-btn">
                <button class="btn back text-uppercase" *ngIf="activeIndex !== 0"
                  (click)="goToClass(clases[getGeneralIndexClassCurse(class.data.title) - 1])">
                  {{'anterior' | translate}}
                </button>
              </div>
              <div class="sect-btn ">
                <button class="btn text-uppercase" *ngIf="activeIndex !== lastIndex"
                  (click)="goToClass(clases[getGeneralIndexClassCurse(class.data.title) + 1])">
                  ¡{{'siguiente' | translate}}!</button>
              </div>
            </div>

            <mat-tab-group>
              <mat-tab label="Acerca de este curso">
                <div class="content-tap">
                  <h3>{{'descripcion' | translate}}:</h3>

                  <p class="p-nomaice d-inline-block" [ngClass]="{'showActive': showMoreId}"
                    [innerHTML]="dataId.fieldDescripcionCurso.processed"></p>
                  <div class="content-div-btn w-100 d-flex justify-content-center">
                    <button class="btn btn-vermas" type="button" (click)="showMore()">
                      {{showMoreText | translate}}
                      <em *ngIf="showMoreId === true" class="far fa-arrow-alt-circle-down"></em>
                      <em *ngIf="showMoreId === false" class="far fa-arrow-alt-circle-up"></em>
                    </button>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Sobre los instructores">
                <div class="content-tap">
                  <div class="cont-tutor mb-3" *ngFor="let item of dataId.fieldInstructores">
                    <h3>{{item.entity.name}}</h3>
                    <p [innerHTML]="item.entity.description.processed"></p>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>

          </div>
          <div class="container-fluid pt-5 curse-detail-video section-gray">
            <div class="row container-sites padding-section-responsive">
              <h3 class="title-sections color-academy mb-4 pl-4 text-center">{{'informacionrelacionada' | translate}}
              </h3>
            </div>
            <div class="row container-sites">
              <owl-carousel-o [options]="customOptionTwoAcademy" (dragging)="dragging($event)">
                <ng-template carouselSlide *ngFor="let item of curseSlider; let i = index">
                  <div class="row">
                    <div class="col-12 col-sm-11 px-md-3 p-sm-3">
                      <a (click)="goLinkNoDragging('/course-detail', item.title)" class="btn  btn-block mt-2"
                        role="button">
                        <app-card-slider-recent [year]="item.entityCreated" [title]="item.title"
                          [text]="item.fieldDescripcionCurso.summary" [images]="getImageTypeContent(item)">
                        </app-card-slider-recent>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>

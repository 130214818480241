<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="useProjectsIdOpens">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 center pt-5">
          <img *ngIf="useProjectsId['fieldImagenDelProyecto'] !== null" class="img-fluid img-org"
            [src]="useProjectsId['fieldImagenDelProyecto']['url']" alt="">
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners color-projects">
            {{useProjectsId['title']}}
          </h2>
          <hr class="line-title-banner color-projects">
          <p class="p-nomaice" [innerHTML]="useProjectsId['body']['processed']"></p>

          <span class="d-block color-projects">
            {{'entidadesinvolucradas' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldEntidadesInvolucradas']}} </p>
          </span>
          <span class="d-block color-projects">
            {{'estado' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldEstadoProyectos']}} </p>
          </span>
          <span class="d-block color-projects">
            {{'categoria' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldCategoriaProyecto']}} </p>
          </span>
          <span class="d-block color-projects">
            {{'lugarorigen' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldLugarProyecto']}} </p>
          </span>
          <span class="d-block color-projects">
            {{'idiomasdisponibles' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldIdiomasDisponibles']}} </p>
          </span>
          <span *ngIf="useProjectsId['fieldPersonaDeContacto'] !== null" class="d-block color-projects">
            {{'puntocontacto' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldPersonaDeContacto']}} </p>
          </span>
          <span *ngIf="useProjectsId['fieldSitioWebProyectos'] !== null" class="d-block color-projects">
            {{'sitioweb' | translate}}/{{'sitiodescarga' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldSitioWebProyectos']['uri']}} </p>
          </span>
          <span *ngIf="useProjectsId['fieldGithubProyecto'] !== null" class="d-block color-projects">
            Github:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldGithubProyecto']['uri']}}</p>
          </span>
          <span *ngIf="useProjectsId['fieldDemo'] !== null" class="d-block color-projects">
            DEMO:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldDemo']['uri']}} </p>
          </span>
          <span *ngIf="useProjectsId['fieldProcesoDeIntegracion'] !== null" class="d-block color-projects">
            {{'procesoboarding' | translate}}:
            <p class="p-nomaice d-inline-block"> {{useProjectsId['fieldProcesoDeIntegracion']}} </p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies" *ngIf="useProjectsOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of useProjects; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/projects',routerProjects(item.fieldTituloIngles))" class="btn  btn-block mt-2" role="button">
                <app-card-projects [title]="item.title" [text]="item.body.summary" [state]="''"
                  [images]="item.fieldImagenPreview.url">
                </app-card-projects>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="banner-back">
    <app-banner-sites [title]="academyDataBanner['title']" [description]="academyDataBanner['description']"
      [image]="academyDataBanner['image']" [color]="academyDataBanner['color']"
      [isButton]="academyDataBanner['isButton']" [textButton]="academyDataBanner['textButton']"
      [isScroll]="academyDataBanner['isScroll']" [icon]="academyDataBanner['icon']" [urlRedirect]="urlRedirect">
    </app-banner-sites>
  </div>

  <div class="section-gray">
    <div class="container-fluid  pt-5 content-one-academy">
      <div class="container-sites">
        <div class="col-12">
          <h2 class="mt-4">{{'nuestrocontenidodos' | translate}}:</h2>
        </div>
        <div class="row mx-108m">
          <div class="col-md-4 col-12">
            <div class="card-academy mb-4">
              <div class="row">
                <div class="col-md-12">
                  <a [routerLink]="['/academic-videos']">
                    <div class="sect-logo w-100 d-flex justify-content-center">
                      <div class="circle-blue">
                        <em class="fab fa-youtube"></em>
                      </div>
                    </div>
                    <h3 class="sub-title">
                      {{'videoacademia' | translate}}
                    </h3>
                    <p>
                      {{'videotextpresentacion' | translate}}
                    </p>
                    <h3>
                      {{'seleccionar' | translate}}
                      <span>
                        <em class="fas fa-chevron-right"></em>
                      </span>
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="card-academy mb-4">
              <div class="row">
                <div class="col-md-12">
                  <a [routerLink]="['/certifiable-courses']">
                    <div class="sect-logo w-100 d-flex justify-content-center">
                      <div class="circle-blue">
                        <em class="fas fa-graduation-cap"></em>
                      </div>
                    </div>
                    <h3 class="sub-title">
                      {{'cursoscertificables' | translate}}
                    </h3>
                    <p>
                      {{'cursotecnosolucioneslacchain' | translate}}
                    </p>
                    <h3>
                      {{'seleccionar' | translate}}
                      <span>
                        <em class="fas fa-chevron-right"></em>
                      </span>
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="card-academy">
              <div class="row">
                <div class="col-md-12">
                  <a [routerLink]="['/academic-publications']">
                    <div class="sect-logo w-100 d-flex justify-content-center">
                      <div class="circle-blue">
                        <em class="fas fa-newspaper"></em>
                      </div>
                    </div>
                    <h3 class="sub-title">
                      {{'publicaciones' | translate}}
                    </h3>
                    <p>
                      {{'publicacionesacademy' | translate}}
                    </p>
                    <h3>
                      {{'seleccionar' | translate}}
                      <span>
                        <em class="fas fa-chevron-right"></em>
                      </span>
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <section class="new-LACChain-content card-content-academy pt-5 pb-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-5 d-flex flex-wrap align-content-center padding-section-responsive">
          <div class="content-new">
            <div class="sec-title">
              <h2 class="title-sections color-academy mb-4">
                {{'proximoseventos' | translate}}
              </h2>
            </div>
            <p>
              {{'descubreproximoseventos' | translate}}
            </p>
            <button [routerLink]="['/subscribe']" class="btn btn-evensaup" type="button">{{'suscribir' |
              translate}}</button>
          </div>
        </div>
        <div class="col-12 col-md-7" *ngIf="eventsOpensSlider">
          <owl-carousel-o [options]="customOptionOne" (dragging)="dragging($event)">
            <ng-template carouselSlide *ngFor="let item of eventsSlider; let i = index">
              <div class="row">
                <div class="col-sm-12 px-md-3 p-sm-3">
                  <a (click)="goLinkNoDragging('/' + getUrlTypeContent(item.type.targetId),(item.title))"
                    class="btn  btn-block mt-2" role="button">
                    <app-card-content [title]="item.title" [formatDate]="item.fieldFechaInicioEvento.date"
                      [text]="item.fieldDescripcionEvento.summary" [images]="getImageTypeContent(item)">
                    </app-card-content>
                  </a>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>

  <!-- Section entities -->

  <div class="section-gray">
    <div class="container-fluid pt-5 recent-content" (keyup.enter)="searchEntity()">
      <div class="row container-sites padding-section-responsive">
        <h2 class="title-sections color-academy mb-4 pl-4">
          {{'contenidoreciente' | translate}}
        </h2>

      </div>
      <div class="row container-sites sities1530">
        <!-- Contenido Reciente -->
        <owl-carousel-o [options]="customOptionTwo" (dragging)="dragging($event)">
          <ng-template carouselSlide *ngFor="let item of recentContent; let i = index">
            <div class="row">
              <div class="col-12 col-sm-11 px-md-3 p-sm-3">
                <a (click)="goLinkNoDragging('/' + getUrlTypeContent(item.type.targetId), (item.title))"
                  class="btn  btn-block mt-2" role="button">
                  <app-card-slider-recent [year]="" [title]="item.title" [text]="getTextTypeContentRecent(item)"
                    [images]="getImageTypeContentRecent(item)" [isVideoCurse]="item.type.targetId">
                  </app-card-slider-recent>
                </a>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>


  <!-- <div class="container-fluid pt-5 pb-5">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mt-5 mb-0 pl-4 color-academy">{{'publicaciones' | translate}}</h2>
    </div>
    <div class="row container-sites padding-section-responsive" *ngIf="publicationsOpens">
      <pagination-controls id="publicationsTwo" (pageChange)="pageChanged($event)" responsive="true"
        previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div class="w-100 d-flex align-itmes-center flex-wrap mt-0">
        <div *ngFor="let item of publications | paginate: {
              id: 'publicationsTwo', itemsPerPage: limitPublications, currentPage: currentPagePublications, totalItems: totalPublications
            }; let i = index" class="col-md-4 col-sm-12 px-md-0 p-sm-3">
          <a target="_blank" href="{{item.fieldLinkPublicacionAcademy.uri}}" class="btn  btn-block mt-2" role="button">
            <div class="card-publications ">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5">
                  <img class="img-fluid" [src]="item.fieldImgPublicacionAcademy.url" alt="">
                </div>
                <div class=" col-12 col-sm-12 col-md-7">
                  <div class="sec-title">
                    <h3>{{item.title}}</h3>
                  </div>
                  <div class="sec-text" multilineDotdotdot>
                    <p> {{item.fieldDesPublicacionAcademy}}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div> -->


</div>

<app-footer></app-footer>

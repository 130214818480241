<div
  class="position-fixed bottom-0 start-0 w-100 schedule-call-banner px-5 px-xxl-0 py-4"
  [ngClass]="{ visible: showElement }"
>
  <div
    class="container d-flex align-items-center justify-content-around h-100 px-0 flex-column flex-md-row"
  >
    <h1 class="mb-md-0">
      {{ "scheduleCallText" | translate }}
    </h1>
    <div class="d-flex">
      <a
        class="schedule-call-button px-3 py-3 text-nowrap mx-4"
        href="https://meetings.hubspot.com/ilan-melendez"
        target="_blank"
      >
        {{ "scheduleCallButton" | translate }}
      </a>
      <button class="close-button" (click)="hideElement()">
        <img src="/assets/images/close-icon.svg" alt="Close" />
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private events: any[] = [

  ];

  private eventsGraphl = {
    "data": {
      "nodeQuery": {
        "count": 8,
        "entities": [
          {
            "title": "Evento ecosistema 1 titulo",
            "body": {
              "summary": "",
              "processed": "<p>Ecosistema 1 lorem impsum summary</p>"
            },
            "fieldFechaEvento": {
              "date": "2020-05-23 20:20:20 UTC"
            },
            "fieldImagenComunidad": {
              "url": "https://live-lacchain.pantheonsite.io/sites/default/files/2020-05/IMG_0647%28f47076d10d6d49e79f8ea029fb7ab3b4%29.jpg"
            },
            "fieldLugarEventoEcosistema": "Calle siempre viva",
            "fieldTipoEventoEcosistema": 1
          }
        ]
      }
    }
  };

  constructor(private apollo: Apollo) { }

  getEvents(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getEventsQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  getEventsAcademy(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getEventsAcademyQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.nodeQuery.entities,
            result.data.nodeQuery.count
          );
        },
        error => { console.log(error) });
  }

  getPublicationAcademy(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getPublicationAcademyQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.nodeQuery.entities,
            result.data.nodeQuery.count
          );
        },
        error => { console.log(error) });
  }

  private getEventsQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query eventos_ecosistema {
            nodeQuery(limit: ${limit}, offset: ${offset}, sort: {field: "field_fecha_inicio_de_evento", direction: DESC}, filter: {conditions: [{operator: EQUAL, field: "status", value: "1" language: ${lang}}, {operator: EQUAL, field: "type", value: "Eventos_ecosistema"}${filter}]}) {
              count
              entities {
                ... on NodeEventosEcosistema {
                  title
                  fieldTituloIngles
                  uuid
                  fieldAgendaEventoEcosistema {
                    processed
                  }
                  body {
                    processed
                    summary
                  }
                  fieldFechaInicioDeEvento {
                    date
                  }
                  fieldFechaFinDeEvento {
                    date
                  }
                  fieldImagenComunidad {
                    url
                  }
                  fieldImagenPreview {
                    url
                  }
                  fieldLugarEventoEcosistema
                  fieldPuntoContactoEventoComu
                  fieldSitioWeb {
                    uri
                  }
                  fieldTipoEventoEcosistema
                }
              }
            }
          }
      `;
  }

  private getEventsAcademyQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
      query eventos_academy($limit: Int= ${limit}, $offset: Int = ${offset}){
          nodeQuery (
          limit:$limit,
          offset:$offset,
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
              },{
                operator: EQUAL
                field: "type"
                value: "Eventos_Academia"
              }${filter}]
            }
          ) {
            count
 							entities {
              ... on NodeEventosAcademia {
                title
 								uuid
                type {
                  targetId
                }
                fieldDescripcionEvento {
                  processed
                  summary
                }
                fieldFechaInicioEvento {
                  date
                }
                fieldImagenPreviewAcademy {
                  url
                }
                fieldImagenAcademy {
                  url
                }
                fieldImpartido {
                  processed
                }
                fieldHoraPersonalizada {
                  processed
                }
                fieldLinkEvento {
                  uri
                }
                fieldImagenInstuctor {
                  entity {
                    ... on TaxonomyTermInstructores {
                      name
                      fieldImagenInstructor {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `;
  }


  private getPublicationAcademyQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
    query publicacion_academy {
        nodeQuery(
            limit: ${limit},
            offset:${offset},
          filter: {
            conditions: [{
              operator: EQUAL
              field: "status"
              value: "1"
              language: ES
            },
              {
                operator: EQUAL
                field: "type"
                value: "Publicacion_academy"
              }${filter}]
          }
        ) {
          count
          entities {
            ... on NodePublicacionAcademy {
              title
              fieldDesPublicacionAcademy
              fieldImgPublicacionAcademy {
                url
              }
              fieldLinkPublicacionAcademy {
                uri
              }
            }
          }
        }
      }
      `;
  }


  getEventsId(id) {
    return this.events[id];
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-home',
  templateUrl: './modal-home.component.html',
  styleUrls: ['./modal-home.component.scss']
})
export class ModalHomeComponent implements OnInit {
  image = null;
  link = null;
  open = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // if (data !== undefined && data !== null) {
      this.image = data.image;
      this.link = data.link;
      this.open = true;
    // }
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { useCasesData } from '../../core/mocks/data-banners';
import { UseProjectsService } from 'src/app/core/services/use-projects.service';
import { TendersService } from 'src/app/core/services/tenders.service';
import { Router } from '@angular/router';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {
public useCasesDataBanner: any[] = [];
public listItems: Array<string> = ["Nacional", "Local", "Regional", "Sectorial"];
public listCategories: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Identidad Descentralizada', es: 'Identidad Descentralizada', en: 'Decentralized Identity', value: null},
  {id: 'Pagos y finanzas', es: 'Pagos y finanzas', en: 'Payments and finances', value: null},
  {id: 'Gobierno', es: 'Gobierno', en: 'Government', value: null},
  {id: 'Seguridad', es: 'Seguridad', en: 'Security', value: null},
  {id: 'Social', es: 'Social', en: 'Social', value: null},
  {id: 'Finanzas', es: 'Finanzas', en: 'Finance', value: null},
  {id: 'Pobreza', es: 'Pobreza', en: 'Poor', value: null},
  {id: 'Intercambio aduanero', es: 'Intercambio aduanero', en: 'Customs exchange', value: null},
  {id: 'Certificados Académicos Digitales', es: 'Certificados Académicos Digitales', en: 'Digital Academic Certificates', value: null},
  {
    id: 'Pagos internacionales a proveedores',
    es: 'Pagos internacionales a proveedores',
    en: 'International payments to suppliers', value: null
  },
  {id: 'Licitaciones públicas', es: 'Licitaciones públicas', en: 'Public tenders', value: null},
];
public listTypeStateProyect: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'En diseño', es: 'En diseño', en: 'In desing', value: null },
  {id: 'En desarrollo', es: 'En desarrollo', en: 'In development', value: null },
  {id: 'Finalizado', es: 'Finalizado', en: 'Finalized', value: null },
  {id: 'En producción', es: 'En producción', en: 'In production', value: null },
];
useProjects: any[] = [];
tenders: any[] = [];
currentPageProyect = 0;
fieldsProyects = [];
limitProyect = 6;
totalProyect = 0;
proyectsOpens = false;
listCategoriesOpen = false;
searchProyectsValue = '';
tendersOpens = false;
searchTendersValue = '';
totalTender = 0;
limitTender = 6;
currentPageTender = 0;
mainFilterproyect = '';
mainFilterCategory = '';
typeStateProyect = 'Seleccionar';
typeCategoryProyect = 'Seleccionar';
urlRedirect = 'contact';
listTypeStateProyectOpen = false;
public inTransact = {
  proyects: false,
  tenders: false,
};

  constructor(
    private useProjectsService: UseProjectsService,
    private tendersService: TendersService,
    private router: Router,
    private seoService: SeoService,
    private title: Title
    ) {
    this.useCasesDataBanner = useCasesData[0];
    this.loadListLang();
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.ngOnInit();
            this.loadListLang();
        }
    });
  }

  private loadListLang(): void {
    this.listTypeStateProyectOpen = false;
    setTimeout(() => {
      this.listTypeStateProyect = this.listTypeStateProyect.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listTypeStateProyectOpen = true;

      this.listCategories = this.listCategories.map(x => {
        x.value = x[localStorage.getItem('lang')];
        return x;
      });
      this.listCategoriesOpen = true;
    }, 200);
  }

  showAdvancedSearchCommunitiesClic = false;

  showAdvancedSearch() {
    this.showAdvancedSearchCommunitiesClic = !this.showAdvancedSearchCommunitiesClic;
  }

  ngOnInit(): void {
    this.getUseProjects('');
    this.getTenders('');
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Casos de uso | En esta sección encontrarás información sobre algunos de los proyectos que están desplegados sobre las redes de LACChain',
      site_name: 'LCChain',
      description: 'En esta sección encontrarás información sobre algunos de los proyectos que están desplegados sobre las redes de LACChain. El objetivo es que cada proyecto pueda...',
      slug: 'use-cases',
      image: 'https://www.lacchain.net/assets/images/cities/casos-de-uso.png'
    });
    this.title.setTitle('Casos de uso | En esta sección encontrarás información sobre algunos de los proyectos que están desplegados sobre las redes de LACChain');
  }

  getUseProjects(filter: string) {
    this.inTransact.proyects = true;
    this.useProjectsService.getUseProjects(filter,
      this.limitProyect,
      this.getOfsset(this.currentPageProyect, this.limitProyect),
      localStorage.getItem('lang').toUpperCase(),(_proyects, total) => {
      this.inTransact.proyects = false;
      this.useProjects =  _proyects;
      this.totalProyect = total;
      this.proyectsOpens = true;
    });
  }

  getPageProyects(page: number) {
    this.currentPageProyect = page;
    this.getUseProjects('');
  }

  getPageTenders(page: number) {
    this.currentPageTender = page;
    this.getTenders('');
  }

  getTenders(filter: string) {
    this.inTransact.tenders = true;
    this.tendersService.getTenders(filter,
      this.limitTender,
      this.getOfsset(this.currentPageTender, this.limitTender),
      localStorage.getItem('lang').toUpperCase(),(_tenders, total) => {
      this.inTransact.tenders = false;
      this.tenders =  _tenders;
      this.totalTender = total;
      this.tendersOpens = true;
    });
  }

  selectionTypeStateProyect(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeStateProyect = value;
    this.purgeFilterProyect('field_estado_proyectos');
    if (value !== 'Seleccionar') {
      this.getUseProjects(this.concatFilterProyect(
        `{field:"field_estado_proyectos", operator: EQUAL, value: "${value}"}`, 'field_estado_proyectos'));
    } else {
      this.getUseProjects(this.mainFilterproyect);
    }
    this.searchProyects();
  }

  searchProyects() {
    this.purgeFilterProyect('title');
    if (this.searchProyectsValue !== '') {
      this.getUseProjects(this.concatFilterProyect(
        `{field:"title", operator: LIKE, value: "%${this.searchProyectsValue}%"}`, 'title'));
    } else {
      this.mainFilterproyect = '';
      this.getUseProjects(this.mainFilterproyect);
    }
  }

  selectionTypeCategoryProyect(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeCategoryProyect = value;
    this.purgeFilterProyect('field_categoria_proyecto');
    if (value !== 'Seleccionar') {
      this.getUseProjects(this.concatFilterProyect(
        `{field:"field_categoria_proyecto", operator: EQUAL, value: "${value}"}`, 'field_categoria_proyecto'));
    } else {
      this.getUseProjects(this.mainFilterproyect);
    }
    this.searchProyects();
  }

  searchTenders() {
    if (this.searchTendersValue !== '') {
      this.getTenders(`{field:"title", operator: LIKE, value: "%${this.searchTendersValue}%"}`);
    } else {
      this.getTenders(``);
    }
  }

  private concatFilterProyect(filterSlabone: string, field: string) {
    if (this.fieldsProyects.filter((x) => x.id === field).length === 0) {
      this.fieldsProyects.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsProyects = this.fieldsProyects.filter((x) => x !== field);
    if (this.fieldsProyects.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterproyect === '') {
        this.mainFilterproyect = this.mainFilterproyect + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterproyect = this.mainFilterproyect + filterSlabone;
      }
    }
    return this.mainFilterproyect;
  }
  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  private purgeFilterProyect(field: string): void {
    const fieldDataProyects = this.fieldsProyects.filter((x) => x.id === field);
    if (fieldDataProyects.length > 0) {
      this.fieldsProyects = this.fieldsProyects.filter((x) => x.id !== field);
    }
  }

  routerProjects(title: string) {
    return title.replace(' ', '-');
  }

}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class HomeService {


  constructor(private apollo: Apollo) { }

  getInformation() {
    return new Promise((resolve, reject) => {
      this.apollo.query<any>({
        query: this.getInformationQuery(),
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        result => {
          resolve({
            data: result.data.nodeQuery.entities,
            total: result.data.nodeQuery.count
          });
        },
        error => {console.log(error)});
    });
  }

  private getInformationQuery(): DocumentNode {
    return gql`
        query modal_home{
          nodeQuery (
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
              }, {
                operator: EQUAL
                field: "type"
                value: "modal_home"
              }]
            }
          ) {
            count
            entities {
              ...on NodeModalHome {
                fieldEnlaceModalHome {
                  uri
                }
                fieldImagenModalHome {
                  url
                }
                fieldVisibleModalHome
              }
            }
          }
        }
      `;
  }
}

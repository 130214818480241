import { Component, OnInit, Input } from '@angular/core';
import {
  allianceData, organizationAlliance,
  organizationAllianceCommunication,
  organizationAllianceStandard,
  organizationAllianceLegal, allianceTextLine} from '../../core/mocks/data-banners';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AllianceEventsService } from 'src/app/core/services/alliance-events.service';
import { AlliancesService } from 'src/app/core/services/alliances.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-alliance',
  templateUrl: './alliance.component.html',
  styleUrls: ['./alliance.component.scss'],
})
export class AllianceComponent implements OnInit {
  public allianceDataBanner: any[] = [];
  public organizationAllianceLocal: any[] = [];
  public organizationAllianceLocalCommunication: any[] = [];
  public organizationAllianceLocalStandard: any[] = [];
  public organizationAllianceLocalLegal: any[] = [];
  public allianceTextLine: any[] = [];
  urlRedirect = 'contact';
  statusDots: boolean;
  statusNav: boolean;
  isMobile: boolean;
  isTablet: boolean;
  customOptions: OwlOptions = {};

  selectedIndex: number = null;
  htmlStr: string;
  htmlStrText: string;
  showHtmlRecommendation = false;
  allianceEvents;
  allianceOpens = false;
  allianceEventsOpen = false;
  limit = 6;
  currentPage = 0;
  total = 0;
  // TODO: Danilo, pending to change interface
  @Input() alliances: any[];

  public inTransact = {
    events: false,
  };



  constructor(
    private router: Router,
    private allianceEventsService: AllianceEventsService,
    private alliancesServices: AlliancesService,
    private deviceService: DeviceDetectorService,
    private seoService: SeoService,
    private title: Title
    ) {
    this.organizationAllianceLocal = organizationAlliance;
    this.organizationAllianceLocalCommunication = organizationAllianceCommunication;
    this.organizationAllianceLocalStandard = organizationAllianceStandard;
    this.allianceTextLine = allianceTextLine;
    this.organizationAllianceLocalLegal = organizationAllianceLegal;
    this.allianceDataBanner = allianceData[0];
    this.selectedIndex = 0;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.detectMobilCarrusel();
    this.getAlliancesEvents('');
    this.router.events.subscribe((event: any) => {
      if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.htmlStrText = this.allianceTextLine[0].descriptionInfra;
    this.getAllianceEventsService();
    this.getAlliances();

    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Alianza | Los miembros de la alianza identificaron una fragmentación y dispersión de las comunidades',
      site_name: 'LACChain',
      description: 'Los miembros de la alianza identificaron una fragmentación y dispersión de las comunidades y las redes blockchain que limitan los esfuerzos que se están realizando para...',
      slug: 'alliance',
      image: 'https://www.lacchain.net/assets/images/cities/alianza.png'
    });
    this.title.setTitle('Alianza | Los miembros de la alianza identificaron una fragmentación y dispersión de las comunidades');
  }

  detectMobilCarrusel(){
    if (this.isMobile === true || this.isTablet === true) {
      this.statusDots = false;
      this.statusNav = true;

      this.customOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };
    }else{
      this.statusDots = true;
      this.statusNav = false;

      this.customOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };
    }
  }

  getPage(page: number) {
      this.currentPage = page;
      this.getAlliancesEvents('');
  }

  setIndex(index: number) {
    this.selectedIndex = index;
    this.htmlStr = this.organizationAllianceLocal[index].detail;

  }

  getAllianceEventsService(){
    //this.allianceEvents =  this.allianceEventsService.getAllianceEvents();
  }

  getAlliances() {
    this.alliancesServices.getAlliances(localStorage.getItem('lang').toUpperCase(),(_alliances, total) => {
      this.alliances = _alliances;
      this.allianceOpens = true;
    });
  }

  getAlliancesEvents(filter: string) {
    this.inTransact.events = true;
    this.allianceEventsService.getAllianceEventsService(filter,
      this.limit,
      this.getOfsset(this.currentPage, this.limit),
      localStorage.getItem('lang').toUpperCase(),
      (_alliances, total) => {
        this.inTransact.events = false;
        this.allianceEvents =  _alliances;
        this.total = total;
        this.allianceEventsOpen = true;
    });
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  routerEventParticipation(title: string) {
    return title.replace(' ', '-');
  }

}

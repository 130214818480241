import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class AllianceEventsService {

  private allianceEvents: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getAllianceEvents() {
    return this.allianceEvents;
  }

  getAllianceEventsId(id) {
    return this.allianceEvents[id];
  }

  getAllianceEventsService(searchFilter: string, limit, offset, lan: string, callback) {
    this.apollo.query<any>({
      query: this.getAllianceEventsQuery(searchFilter, limit, offset, lan),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getAllianceEventsQuery(filter: string, limit, offset, language: string): DocumentNode {
    return gql`
        query eventos_lacchain($limit: Int = ${limit}, $offset: Int = ${offset}){
          nodeQuery(
            limit: $limit,
            offset: $offset,
            sort: {field: "field_fecha_inicio_de_evento", direction: DESC},
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
                language: ${language}
              }, {
                operator: EQUAL
                field: "type"
                value: "Eventos_Lacchain"
              }${filter}]
            }
          ) {
            count
            entities {
              ...on NodeEventosLacchain {
                uuid
                title
                fieldAgendaEvento {
                  processed
                }
                body {
                  processed
                  summary
                }
                fieldFechaInicioDeEvento {
                  date
                }
                fieldFechaFinDeEvento {
                  date
                }
                fieldEventoLacchain {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldLugarEvento
                fieldOrganizadorEvento
                fieldPuntoDeContactoEvento
                fieldSitioWeb {
                  uri
                }
                fieldTipoEvento
                fieldTituloIngles
              }
            }
          }
        }
      `;

  }
}

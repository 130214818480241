import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-event-communities',
  templateUrl: './card-event-communities.component.html',
  styleUrls: ['./card-event-communities.component.scss']
})
export class CardEventCommunitiesComponent implements OnInit {

  @Input() title: string;
  @Input() date: string;
  @Input() images: string;
  @Input() text: string;
  formatDate: any;



  constructor() { }

  ngOnInit(): void {
    this.formatDate = this.date.replace(/-/g, '/');
  }

}

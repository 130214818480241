<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="eventsIdOpens">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 m-auto pt-5">
          <div class="card-event-container p-0 center">
            <img class="image-event img-org" [src]="eventsId['fieldImagenComunidad']['url']" alt="">
          </div>

        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-yellow">
            {{eventsId['title']}}
          </h2>
          <hr class="line-title-banner color-yellow">
          <p class="p-nomaice" [innerHTML]="eventsId['body']['processed']"></p>
          <div class="info-all mt-4">
            <span class="d-block color-yellow">
              {{'type' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldTipoEventoEcosistema']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'lugar'| translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldLugarEventoEcosistema']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'fecha'| translate}}:
              <p class="p-nomaice d-inline-block">
                {{date | date: 'dd/MM/yyyy' }} </p>
            </span>
            <span class="d-block color-yellow">
              {{'hora'| translate}}:
              <p class="p-nomaice d-inline-block"> {{date | date: 'h:mm a' }}
              </p>
            </span>
            <span class="d-block color-yellow">
              {{'puntocontacto'| translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldPuntoContactoEventoComu']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'sitioweb'| translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldSitioWeb']['uri']}} </p>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 pt-5 padding-section-responsive">
          <span class="d-block color-yellow pb-2" *ngIf="eventsId['fieldAgendaEventoEcosistema'] !== null">
            {{'agenda' | translate}}:
          </span>
          <span class="d-block color-yellow" *ngIf="eventsId['fieldAgendaEventoEcosistema'] !== null">
            <p class="p-nomaice d-inline-block" [innerHTML]="eventsId['fieldAgendaEventoEcosistema']['processed']"></p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies" *ngIf="eventsIdOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of events; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a
               (click)="goLinkNoDragging('/events',routerEvents(item.fieldTituloIngles))"
               class="btn  btn-block mt-2" role="button"
                >
                <app-card-event-communities [title]="item.title" [text]="item.body.summary"
                  [images]="item.fieldImagenPreview.url" [date]="item.fieldFechaInicioDeEvento.date">
                </app-card-event-communities>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

<div class="modal-academy">
  <button class="btn btn-link btn-close" mat-button [mat-dialog-close]="true" cdkFocusInitial>
    <em class="fas fa-times"></em>
  </button>
  <mat-dialog-content class="mat-typography">


    <div class="sect-logo w-100 d-flex justify-content-center">
      <div class="circle-blue">
        <em class="fas fa-graduation-cap"></em>
      </div>
    </div>


    <div class="sect-content">
      <h2>¿En qué te podemos ayudar?</h2>
      <p>Déjanos una breve descripción de en que te podría ayudar
        nuestro equipo de soporte.
      </p>
      <div class="form-group">
        <form [formGroup]="userEmails" (ngSubmit)="SendProyect()">
          <div class="form-group">

            <div class="nameinput mb-2">
              <input [class.is-invalid]="primName.invalid && primName.touched" type="text" class="form-control"
                placeholder="Nombre" formControlName="primaryName" />
              <div *ngIf="(primName.invalid && primName.touched) || primName.dirty">
                <small *ngIf="primName.errors?.required" class="text-danger d-block text-left">El nombre es
                  requerido</small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primName.errors?.pattern" class="text-danger d-block text-left">El formato de nombre no es
                  valido</small>
              </div>
            </div>

            <input [class.is-invalid]="primEmail.invalid && primEmail.touched" type="email" class="form-control"
              placeholder="Email" formControlName="primaryEmail" />
            <div *ngIf="(primEmail.invalid && primEmail.touched) || primEmail.dirty">
              <small *ngIf="primEmail.errors?.required" class="text-danger d-block text-left">El email es
                requerido</small>
              <!--question mark(?) is a safe navigation operator-->
              <small *ngIf="primEmail.errors?.pattern" class="text-danger d-block text-left">El formato de email no es
                valido</small>
            </div>
            <div class="areaText mt-2 mb-2">
              <textarea placeholder="Dejanos un mensaje..." class="form-control" id="exampleFormControlTextarea1"
                rows="3" formControlName="primaryAreaText"></textarea>
              <div *ngIf="(primAreaText.invalid && primAreaText.touched) || primEmail.dirty">
                <small *ngIf="primAreaText.errors?.required" class="text-danger d-block text-left">El mensaje es
                  requerido</small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primAreaText.errors?.pattern" class="text-danger d-block text-left">El formato de mensaje
                  no es valido</small>
              </div>
            </div>

            <button class="btn  btn-suscribe" type="submit">Enviar</button>
          </div>
        </form>
      </div>
    </div>







  </mat-dialog-content>

</div>

import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class StoreServices {

  constructor(private apollo: Apollo) { }

  getServices(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filters: string, limit: number, offset: number, lang): DocumentNode {
    return gql`
        query servicios {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "title", direction: ASC },
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Servicios"}
              ${filters}]}
          ) {
            count
            entities {
              ... on NodeServicios {
                title
                fieldTituloIngles
                uuid
                fieldCategoriaServicio
                body {
                  processed
                  summary
                }
                fieldEstado
                fieldIa {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldPuntosDeContacto
                fieldSedeLegal
                fieldSitioWebServicio {
                  uri
                }
                fieldTipoDeSolucion
                fieldIdiomasDisponibleServics
                fieldPlataformaServicio
                fieldSitioDescarga
              }
            }
          }
        }
      `;
  }
}

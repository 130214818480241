import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-digital-platform',
  templateUrl: './card-digital-platform.component.html',
  styleUrls: ['./card-digital-platform.component.scss']
})
export class CardDigitalPlatformComponent implements OnInit {

@Input() title: string;
@Input() text: string;
@Input() type: string;
@Input() images: string;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitiesService } from 'src/app/core/services/entities.service';

@Component({
  selector: 'app-entities-all',
  templateUrl: './entities-all.component.html',
  styleUrls: ['./entities-all.component.scss']
})
export class EntitiesAllComponent implements OnInit {

  entitiesId: any = {};
  entities: any[] = [];
  entitiesOpens = false;
  entitiesIdsOpens = false;
  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    nav: false,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  indrag = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private entitiesService: EntitiesService,
    private router: Router) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getEntities();
            this.getEntitiesId(true);
        }
    });
  }

  ngOnInit(): void {
    this.getEntities();
    this.getEntitiesId(false);
  }

  getEntitiesId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const entitiesId = this.getEvenIdByName(arg.title);
      this.entitiesIdsOpens = false;
      this.entitiesService.getEntities(`
        {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (entitiesId) : this.entitiesId.fieldTituloIngles }"
        },
      `,
      1,
      0,
      localStorage.getItem('lang').toUpperCase(), (events, total) => {
        this.entitiesId =  events[0];
        this.entitiesIdsOpens = true;
      });
    });
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, title]);
    }
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getEntities() {
    this.entitiesService.getEntities('',
      100,
      0,
      localStorage.getItem('lang').toUpperCase(), (_alliances, total) => {
        this.entities =  _alliances;
        this.entitiesOpens = true;
    });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  routerEntities(title: string){
    return title.replace(' ', '-');
  }

}

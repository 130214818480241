<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="resourcesDataBanner['title']" [description]="resourcesDataBanner['description']"
    [description1]="resourcesDataBanner['description1']" [description2]="resourcesDataBanner['description2']"
    [description3]="resourcesDataBanner['description3']" [image]="resourcesDataBanner['image']"
    [color]="resourcesDataBanner['color']" [isButton]="resourcesDataBanner['isButton']"
    [textButton]="resourcesDataBanner['textButton']" [urlRedirect]="urlRedirect"
    [isScroll]="resourcesDataBanner['isScroll']" [icon]="resourcesDataBanner['icon']">
  </app-banner-sites>

  <div class="container-fluid section-gray" (keyup.enter)="searchPress()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections p-3 mb-4 color-resources">{{'notasdeprensa' | translate}}</h2>
    </div>
    <div class="row container-sites padding-section-responsive" *ngIf="resourcesOpens">
      <div class="col-md-12 col-sm-12">
        <div class="search my-4">
          <input type="text" class="searchTerm" [(ngModel)]="searchPressValue"
            placeholder="{{'buscarprensa' | translate}}">
          <button type="button" (click)="searchPress()" class="searchButton">
            {{'buscar' | translate}} <em class="fa fa-search"></em>
          </button>
        </div>
      </div>
    </div>
    <div class="row container-sites">
      <pagination-controls id="resources" (pageChange)="getPageResources($event)"
        previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div *ngIf="inTransact.prensa" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of resources | paginate: {
              id: 'resources', itemsPerPage: limitResources, currentPage: currentPageResources, totalItems: totalResources
            }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/our-news', routerOurNews(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-our-news [title]="item.title" [images]="item.fieldImagenPreview.url" [text]="item.body.summary">
            </app-card-our-news>
          </a>
        </div>
        <div class="col-md-4 col-sm-12  p-sm-3 style-content-not-found"
          *ngIf="((resources.length !== undefined) && resources.length === 0) && !inTransact.prensa">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies-medios">
    <div class="sec-title container-carousel">
      <h2 class="title-sections text-left ">{{'lacchainenmedios' | translate}}</h2>
    </div>
    <div class="container-fuid  container-carousel pb-4" *ngIf="ourResoucesOpens">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of ourResources; let i = index">
          <div class="col-sm-12 p-sm-3 with-lacchain-medios">
            <a href="{{item.fieldEnlace.uri}}" target="_blank" rel="noreferrer" class="btn  btn-block mt-2" role="button">
              <app-card-our-news [title]="item.title" [images]="item.fieldImagenMedios.url"
                [text]="item.fieldDescripcionMedio" [color]="true">
              </app-card-our-news>
            </a>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

  <section class="sect-blog">
    <div class="container-fluid">
      <div class="row container-sites padding-section-responsive">
        <h2 class="title-sections-gray pt-3 mt-5 mb-4 pl-4 text-center">{{'blogs' | translate}}</h2>
      </div>
      <div class="row container-sites center padding-section-responsive" *ngIf="blogsOpens">
        <pagination-controls id="blogs" (pageChange)="getPageBlogs($event)" previousLabel="{{'anterior' | translate}}"
          nextLabel="{{'siguiente' | translate}}">
        </pagination-controls>
        <div class="col-12 d-flex align-itmes-center flex-wrap">
          <div class="col-12 col-md-4 px-md-1 p-sm-1 mt-2" *ngFor="let item of blogs | paginate: {
              id: 'blogs', itemsPerPage: limitBlogs, currentPage: currentPageBlogs, totalItems: totalBlogs
            }; let i = index;">
            <a href="{{item.fieldEnlaceBlog.uri}}" target="_blank" rel="noreferrer" class="btn  btn-block mt-2" role="button">
              <div class="card-blog center">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-5">
                    <img class="img-fluid image-blog-resources" [src]="item.fieldImagenBlog.url" alt="">
                  </div>
                  <div class=" col-12 col-sm-12 col-md-7">
                    <div class="sec-title content-paragraph" multilineDotdotdot>
                      <h3>{{item.title}}</h3>
                    </div>
                    <div>
                      <p>{{item.fieldDescripcionBlog}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container-fluid section-gray">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4 color-resources">{{'recomendaciones' | translate}}</h2>
    </div>
    <div class="row container-sites padding-section-responsive" *ngIf="publicationsOpens">
      <pagination-controls id="publications" (pageChange)="getPagePublications($event)"
        previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of publications | paginate: {
              id: 'publications', itemsPerPage: limitPublications, currentPage: currentPagePublications, totalItems: totalPublications
            }; let i = index" class="col-md-4 col-sm-12 px-md-0 p-sm-3">
          <a target="_blank" href="{{item.fieldLinkPublicacion.uri}}" class="btn  btn-block mt-2" role="button">
            <div class="card-publications ">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-5">
                  <img class="img-fluid" [src]="item.fieldImagenPublicacion.url" alt="">
                </div>
                <div class=" col-12 col-sm-12 col-md-7">
                  <div class="sec-title">
                    <h3>{{item.title}}</h3>
                  </div>
                  <div class="sec-text" multilineDotdotdot>
                    <p> {{item.fieldDescripcionPublicacion}}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="our-allies-webinars">
    <div class="sec-title container-carousel">
      <h2 class="title-sections-gray p-3">{{'webinars' | translate}}</h2>
    </div>
    <div class="container-fuid  container-carousel pb-4" *ngIf="webinarsOpens">

      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of webinars; let i = index">
          <div (click)="showWebinar(item.fieldEnlaceWebinar.uri)" class="modal-content-upper"></div>
          <div class="col-sm-12 px-md-3 p-sm-3">
            <iframe [src]="item.fieldEnlaceWebinar.uri | domSafe"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
              title="video-lacchain">
            </iframe>
          </div>
          <h4 class="text-center color-resources">{{item.title}}</h4>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div> -->


</div>

<app-footer></app-footer>

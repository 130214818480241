<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">

  <app-banner-sites [title]="allianceDataBanner['title']" [description]="allianceDataBanner['description']"
    [image]="allianceDataBanner['image']" [color]="allianceDataBanner['color']"
    [isButton]="allianceDataBanner['isButton']" [textButton]="allianceDataBanner['textButton']"
    [isScroll]="allianceDataBanner['isScroll']" [icon]="allianceDataBanner['icon']" [urlRedirect]="urlRedirect">

  </app-banner-sites>

  <div class="container-fluid section-gray how-are-we-organized">
    <div class="row container-sites">
      <div class="col-12 padding-section-responsive">
        <div class="sec-title">
          <h2 class="title-sections">{{'quienessomos' | translate}}</h2>
        </div>
        <p class="paragraph-banners">{{'quienessomosresp' | translate}}</p>
        <div>

        </div>
      </div>
    </div>
  </div>


  <div class="our-allies">
    <div class="sec-title">
      <h2 class="title-sections">{{'nuestroaliados' | translate}}</h2>
      <p class="paragraph-withe text-center">{{'nuestroaliadosdesc' | translate}}:</p>
    </div>
    <div class="container-fuid  container-carousel p-4">
      <owl-carousel-o [options]="customOptions" *ngIf="allianceOpens">
        <ng-template carouselSlide *ngFor="let item of alliances; let i = index">
          <div class="card-slider">
            <div class="row">
              <div class="col-md-12">
                <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
                  <img class="img-fluid logo-carousel" src="{{item.fieldLogoAliado.url}}" alt="bid">
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 d-flex justify-content-center align-items-center">
                <a href="{{item.fieldSitioWeb.uri}}" target="_blank" rel="noreferrer">
                  <p class="p-link"><strong>{{'sitioweb' | translate}}</strong></p>
                  <div class="global-sect">
                    <em class="fas fa-globe-americas"></em>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

  <div class="container-fluid my-5 the-blockchain-iframe">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 center padding-section-responsive">
          <div class="container-iframe">
            <iframe title="Visualización de datos" allowfullscreen="true" class="tableauViz"
              style="display: block; width: 100%; height: 900px; margin: 0px; padding: 0px; border: none;"
              src="https://public.tableau.com/views/LACChainpartners/LACChainPartnersandexpertise?:embed=y&amp;:showVizHome=no&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:embed_code_version=3&amp;:tabs=no&amp;:toolbar=yes&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=no&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid how-are-we-organized section-gray">
    <div class="row container-sites">
      <div class="col-12 padding-section-responsive">
        <div class="sec-title">
          <h2 class="title-sections">{{'comoapoya' | translate}}</h2>
        </div>
        <p class="paragraph-banners"><strong>{{'lineasdeaccioncomple' | translate}}:</strong>
          {{'lineasdeaccioncompledesc1' | translate}}</p>
        <p class="paragraph-banners"><strong>{{'Comecos' | translate}}:</strong> {{'Comecosdesc1' | translate}}</p>
        <p innerHTML="{{htmlStrText | translate }}"> </p>
      </div>
    </div>
  </div>

  <div class="container-fluid how-are-we-organized pt-3">
    <div class="row container-sites">
      <div class="col-12 padding-section-responsive">
        <div class="sec-title">
          <h2 class="title-sections">{{'comoestamosorganizados' | translate}}</h2>
        </div>
        <p class="paragraph-banners">{{'comoestamosorganizadosresp1' | translate}}</p>
        <p class="paragraph-banners">{{'comoestamosorganizadosresp2' | translate}}</p>
        <div class="content-info-how-are">
          <mat-tab-group>
            <mat-tab class="text-uppercase" label="{{'tecnologia' | translate}}">
              <div class="color-pink">
                <app-automatic-text [currentInfoLocal]="organizationAllianceLocal"></app-automatic-text>
              </div>
            </mat-tab>
            <mat-tab class="text-uppercase" label="{{'comunicacion' | translate}}">
              <div class="color-pink">
                <app-automatic-text [currentInfoLocal]="organizationAllianceLocalCommunication"></app-automatic-text>
              </div>

            </mat-tab>
            <mat-tab class="text-uppercase" label="{{'estandarizacion' | translate}}">

              <div class="color-pink">
                <app-automatic-text [currentInfoLocal]="organizationAllianceLocalStandard"></app-automatic-text>
              </div>

            </mat-tab>
            <mat-tab class="text-uppercase" label="{{'legalyfinanzas' | translate}}">

              <div class="color-pink">
                <app-automatic-text [currentInfoLocal]="organizationAllianceLocalLegal"></app-automatic-text>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>

  <!-- Section events -->
  <div class="container-fluid section-gray">
    <div class="row container-sites">
      <h2 class="title-sections color-alliance mb-4 pl-4">{{'participacioneventoslacchain' | translate}}</h2>
    </div>
    <div class="row container-sites">
      <pagination-controls id="allianceEvents" (pageChange)="getPage($event)" previousLabel="{{'anterior' | translate}}"
        nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div *ngIf="inTransact.events" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of allianceEvents | paginate: {
            id: 'allianceEvents', itemsPerPage: limit, currentPage: currentPage, totalItems: this.total
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/events-participacion', routerEventParticipation(item.fieldTituloIngles)]" class="btn  btn-block mt-2"
            role="button">
            <app-card-event-alliance [title]="item.title" [text]="item.body.summary"
              [images]="item.fieldImagenPreview.url" [date]="item.fieldFechaInicioDeEvento.date"
              [sponsor]="item.fieldOrganizadorEvento" [time]="item.fieldFechaInicioDeEvento">
            </app-card-event-alliance>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>

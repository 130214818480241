import { Component, OnInit } from '@angular/core';
import { entrepreneurshipData } from '../../core/mocks/data-banners';
import { FinancingOpportunitiesService } from 'src/app/core/services/financing-opportunities.service';
import { Router } from '@angular/router';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-entrepreneurship',
  templateUrl: './entrepreneurship.component.html',
  styleUrls: ['./entrepreneurship.component.scss']
})
export class EntrepreneurshipComponent implements OnInit {
public entrepreneurshipDataBanner: any[] = [];
public listItems: Array<string> = ["Nacional", "Local", "Regional", "Sectorial"];
financingOpportunities;
limit = 6;
total = 0;
typeOpen = false;
public inTransact = {
  opportunities: false,
};
searchOpportunitieValue = '';
public currentPage: number = 0;
fields = [];
private mainFilter = '';
public listTypeEntrepreneur: Array<any> = [
  {id: 'Todos/All', es: 'Todos', en: 'All', value: null},
  {id: 'Empresarial', es: 'Empresarial', en: 'Business', value: null},
];
public listTypeEntrepreneurSupport: Array<string> = ["Todos/All","Asistencia técnica", "Inversión", "Marketing"];
financingOpportunitiesOpen = false;
typeProyect = 'Todos/All';
typeSupport = 'Todos/All';


  constructor(
    private financingOpportunitiesService: FinancingOpportunitiesService,
    private router: Router,
    private seoService: SeoService,
    private title: Title
    ) {
    this.entrepreneurshipDataBanner = entrepreneurshipData[0];
    this.loadListLang();
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.ngOnInit();
            this.loadListLang();
        }
    });
   }

  showAdvancedSearchCommunitiesClic = false;

  private loadListLang(): void {
    this.typeOpen = false;
    setTimeout(() => {
      this.listTypeEntrepreneur = this.listTypeEntrepreneur.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.typeOpen = true;
    }, 200);
  }

  showAdvancedSearch() {
    this.showAdvancedSearchCommunitiesClic = !this.showAdvancedSearchCommunitiesClic;
  }

  ngOnInit(): void {
    this.getFinancingOpportunities(this.mainFilter);
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Emprendimiento | Al habilitar un protocolo normalizado y estándar, con las características de un bien común o de utilidad colectiva',
      site_name: 'LCChain',
      description: 'Al habilitar un protocolo normalizado y estándar, con las características de un bien común o de utilidad colectiva, la iniciativa LACChain establece un soporte único para el emprendimiento de la región...',
      slug: 'entrepreneurship',
      image: 'https://www.lacchain.net/assets/images/cities/emprendimiento.png'
    });
    this.title.setTitle('Emprendimiento | Al habilitar un protocolo normalizado y estándar, con las características de un bien común o de utilidad colectiva');
  }

  selectionType(event): void {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeProyect = value;
    this.purgeFilter('field_tipo_de_proyecto');
    if (value !== 'Todos/All') {
      this.getFinancingOpportunities(this.concatFilter(
        `{field:"field_tipo_de_proyecto", operator: EQUAL, value: "${value}"}`, 'field_tipo_de_proyecto'));
    } else {
      this.getFinancingOpportunities(this.mainFilter);
    }
    this.searchOpportunitie();
  }

  selectionTypeSupport(event): void {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeSupport = value;
    this.purgeFilter('field_tipo_de_apoyo_que_solicita');
    if (value !== 'Todos/All') {
      this.getFinancingOpportunities(this.concatFilter(
        `{field:"field_tipo_de_apoyo_que_solicita", operator: EQUAL, value: "${value}"}`, 'field_tipo_de_apoyo_que_solicita'));
    } else {
      this.getFinancingOpportunities(this.mainFilter);
    }
    this.searchOpportunitie();
  }

  getFinancingOpportunities(filter: string) {
    this.inTransact.opportunities = true;
    this.financingOpportunitiesService.getFinancingOpportunities(filter,
      this.limit,
      this.getOfsset(this.currentPage, this.limit),
      localStorage.getItem('lang').toUpperCase(),(_opportunnities, total) => {
      this.inTransact.opportunities = false;
      this.financingOpportunities =  _opportunnities;
      this.total = total;
      this.financingOpportunitiesOpen = true;
    });
  }

  private getOfsset(page, limit) {
    return (page === 1) ? 0 :
      (page === 2) ? limit : page * limit;
  }


  private concatFilter(filterSlabone: string, field: string) {
    if (this.fields.filter((x) => x.id === field).length === 0) {
      this.fields.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fields = this.fields.filter((x) => x !== field);
    if (this.fields.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilter === '') {
        this.mainFilter = this.mainFilter + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilter = this.mainFilter + filterSlabone;
      }
    }
    return this.mainFilter;
  }

  private purgeFilter(field: string): void {
    const fieldData = this.fields.filter((x) => x.id === field);
    if (fieldData.length > 0) {
      this.fields = this.fields.filter((x) => x.id !== field);
    }
  }

  searchOpportunitie() {
    this.purgeFilter('title');
    if (this.searchOpportunitieValue !== '') {
      this.getFinancingOpportunities(this.concatFilter(
        `{field:"title", operator: LIKE, value: "%${this.searchOpportunitieValue}%"}`, 'title'));
    } else {
      this.mainFilter = '';
      this.getFinancingOpportunities(this.mainFilter);
    }
  }

  getPage(page: number) {
    this.currentPage = page;
    this.searchOpportunitie();
  }

  routerFinancingOpportunities(title: string) {
    return title.replace(' ', '-');
  }

}

import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  private videos: any[] = [

  ];



  constructor(private apollo: Apollo) { }

  getVideosAcademy(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getVideosAcademyQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.nodeQuery.entities,
            result.data.nodeQuery.count
          );
        },
        error => { console.log(error) });
  }


  private getVideosAcademyQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
         query videos_educativos($limit: Int= ${limit}, $offset: Int = ${offset}){
          nodeQuery (
            limit:$limit,
            offset:$offset,
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
              },{
                operator: EQUAL
                field: "type"
                value: "Videos_Educativos"
              }${filter}]
            }
          ) {
            count
 							entities {
              ... on NodeVideosEducativos {
                type {
                  targetId
                }
                title
                uuid
                fieldDuracionVideo
                fieldDesVideoEducativo{
                  summary
                  processed
                }
                fieldIdmVideoEducativo {
                  entity {
                    name
                  }
                }
                fieldLinkVideoEducativo {
                  uri
                }
                fieldImagenVideoEducativo {
                  url
                }
                fieldTemporalVideoEducativo {
                  value
                  date
                }
                fieldIdmVideoEducativo {
                  targetId
                  entity {
                    name
                  }
                }
                fieldTagsTemaVideoEducativo {
                  targetId
                  entity {
                    name
                  }
                }
                fieldTagsPerVideoEducativo {
                  targetId
                  entity {
                    name
                  }
                }
                fieldTagsCatVideoEducativo {
                  targetId
                  entity {
                    name
                  }
                }
              }
            }
          }
        }

      `;
  }

  getVideosId(id) {
    return this.videos[id];
  }

}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private apollo: Apollo) { }

  getBlogs(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit , offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query blogs {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "created", direction: DESC},
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Blogs"}
              ${filter}]}
          ) {
            count
            entities {
              ... on NodeBlogs {
                title
                fieldDescripcionBlog
                fieldEnlaceBlog {
                  title
                  uri
                }
                fieldImagenBlog {
                  url
                }
              }
            }
          }
        }
      `;
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-blueprint',
  templateUrl: './card-blueprint.component.html',
  styleUrls: ['./card-blueprint.component.scss']
})
export class CardBlueprintComponent implements OnInit {

  constructor() { }

  @Input() title;
  @Input() description;
  @Input() url;

  ngOnInit(): void {
  }

}

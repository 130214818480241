import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TheBlockchainIframeComponent } from '../../components/the-blockchain-iframe/the-blockchain-iframe.component';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  defaultSpanish = true;
  activeLang = 'es';
  isMobile = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    localStorage.setItem('lang', this.activeLang);
    this.isMobile = this.deviceService.isMobile();
    this.initialLanguage();
  }


  initialLanguage(){
    this.route.queryParams.subscribe(params => {
      const lang = params['lang'];
      if (lang) {
        this.useLanguage(lang)
        localStorage.setItem('lang', lang);
        this.activeLang = lang;
      }
    });
  }

  useLanguage(lang: string) {
    if (lang === 'es') {
      this.defaultSpanish = true;
    } else {
      this.defaultSpanish = false;
    }
    this.activeLang = lang;
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.router.navigate([], {
      queryParams: { lang: localStorage.getItem('lang') },
    });

    TheBlockchainIframeComponent.updateLanguageSelected.next(true);
  }
}

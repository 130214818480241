// Core
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';


import { InputsModule } from '@progress/kendo-angular-inputs';

// Material
import { MatSliderModule } from '@angular/material/slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';


// Kendo
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';


// Translations
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { MultilineDotdotdotModule } from 'multiline-dotdotdot';

// Component
import { AppComponent } from './app.component';
import { LacchainComponent } from './modules/lacchain/lacchain.component';
import { NavMenuComponent } from './shared/components/nav-menu/nav-menu.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './modules/home/home.component';
import { AllianceComponent } from './modules/alliance/alliance.component';
import { EcosystemsComponent } from './modules/ecosystems/ecosystems.component';
import { EntrepreneurshipComponent } from './modules/entrepreneurship/entrepreneurship.component';
import { LStoreComponent } from './modules/l-store/l-store.component';
import { UseCasesComponent } from './modules/use-cases/use-cases.component';
import { ResourcesComponent } from './modules/resources/resources.component';
import { TheBlockchainComponent } from './modules/the-blockchain/the-blockchain.component';
import { BannerSitesComponent } from './shared/components/banner-sites/banner-sites.component';
import { CardCommunitiesComponent } from './shared/components/card-communities/card-communities.component';
import { CardEntitiesComponent } from './shared/components/card-entities/card-entities.component';
import { CardEventCommunitiesComponent } from './shared/components/card-event-communities/card-event-communities.component';
import { AutomaticTextComponent } from './shared/components/automatic-text/automatic-text.component';
import { DomSafePipe } from './core/pipes/dom-safe.pipe';
import { CommunitiesAllComponent } from './shared/components/communities-all/communities-all.component';
import { CardBlueprintComponent } from './shared/components/card-blueprint/card-blueprint.component';
import { CardEventAllianceComponent } from './shared/components/card-event-alliance/card-event-alliance.component';
import { EntitiesAllComponent } from './shared/components/entities-all/entities-all.component';
import { EventsAllComponent } from './shared/components/events-all/events-all.component';
import { CardOurNewsComponent } from './shared/components/card-our-news/card-our-news.component';
import { OurNewsAllComponent } from './shared/components/our-news-all/our-news-all.component';
import { CardServicesComponent } from './shared/components/card-services/card-services.component';
import { ServicesAllComponent } from './shared/components/services-all/services-all.component';
import { CardDigitalPlatformComponent } from './shared/components/card-digital-platform/card-digital-platform.component';
import { DigitalPlatformsAllComponent } from './shared/components/digital-platforms-all/digital-platforms-all.component';
import { CardAppsDappsComponent } from './shared/components/card-apps-dapps/card-apps-dapps.component';
import { AppsDappsAllComponent } from './shared/components/apps-dapps-all/apps-dapps-all.component';
import { CardFinancingOpportunitiesComponent } from './shared/components/card-financing-opportunities/card-financing-opportunities.component';
import { FinancingOpportunitienitiesAllComponent } from './shared/components/financing-opportunitienities-all/financing-opportunitienities-all.component';
import { CardProjectsComponent } from './shared/components/card-projects/card-projects.component';
import { ProjectsAllComponent } from './shared/components/projects-all/projects-all.component';
import { AllianceEventsAllComponent } from './shared/components/alliance-events-all/alliance-events-all.component';
import { CardTenderComponent } from './shared/components/card-tender/card-tender.component';
import { TendersAllComponent } from './shared/components/tenders-all/tenders-all.component';
import { TheBlockchainIframeComponent } from './shared/components/the-blockchain-iframe/the-blockchain-iframe.component';
import { InstructionComponent } from './shared/components/instruction/instruction.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CardContentComponent } from './shared/components/card-content/card-content.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalWebinarComponent } from './shared/components/modal-webinar/modal.webinar.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CitiesMapComponent } from './shared/components/cities-map/cities-map.component';
import { ModalHomeComponent } from './shared/components/modal-home/modal-home.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { AcademyComponent } from './modules/academy/academy.component';
import { CardSliderRecentComponent } from './shared/components/card-slider-recent/card-slider-recent.component';
import { RecentContentComponent } from './shared/components/recent-content/recent-content.component';
import { CertifiableCoursesComponent } from './shared/components/certifiable-courses/certifiable-courses.component';
import { AcademicPublicationsComponent } from './shared/components/academic-publications/academic-publications.component';
import { VideoRelatedInformationComponent } from './shared/components/video-related-information/video-related-information.component';
import { CourseDetailAcademyComponent } from './shared/components/course-detail-academy/course-detail-academy.component';
import { AcademicVideosComponent } from './shared/components/academic-videos/academic-videos.component';
import { ModalAcademyComponent } from './shared/components/modal-academy/modal-academy.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { SubscribeAcademyComponent } from './shared/components/subscribe-academy/subscribe-academy.component';
import '@progress/kendo-angular-intl/locales/es/all';
import { registerLocaleData } from '@angular/common';
// importar locales

import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEsAR from '@angular/common/locales/es-AR';
import { ScheduleCallBannerComponent } from './shared/components/schedule-call-banner/schedule-call-banner.component';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer

registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEsAR, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    LacchainComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    AllianceComponent,
    EcosystemsComponent,
    EntrepreneurshipComponent,
    LStoreComponent,
    UseCasesComponent,
    ResourcesComponent,
    TheBlockchainComponent,
    BannerSitesComponent,
    CardCommunitiesComponent,
    CardEntitiesComponent,
    CardEventCommunitiesComponent,
    AutomaticTextComponent,
    DomSafePipe,
    CommunitiesAllComponent,
    CardBlueprintComponent,
    CardEventAllianceComponent,
    EntitiesAllComponent,
    EventsAllComponent,
    CardOurNewsComponent,
    OurNewsAllComponent,
    CardServicesComponent,
    ServicesAllComponent,
    CardDigitalPlatformComponent,
    DigitalPlatformsAllComponent,
    CardAppsDappsComponent,
    AppsDappsAllComponent,
    CardFinancingOpportunitiesComponent,
    FinancingOpportunitienitiesAllComponent,
    CardProjectsComponent,
    ProjectsAllComponent,
    AllianceEventsAllComponent,
    CardTenderComponent,
    TendersAllComponent,
    TheBlockchainIframeComponent,
    InstructionComponent,
    CardContentComponent,
    ModalWebinarComponent,
    CitiesMapComponent,
    ModalHomeComponent,
    ModalAcademyComponent,
    ContactComponent,
    AcademyComponent,
    CardSliderRecentComponent,
    RecentContentComponent,
    CertifiableCoursesComponent,
    VideoRelatedInformationComponent,
    CourseDetailAcademyComponent,
    AcademicVideosComponent,
    SubscribeAcademyComponent,
    AcademicPublicationsComponent,
    ScheduleCallBannerComponent,
  ],
  imports: [
    InputsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    CarouselModule,
    DropDownsModule,
    ComboBoxModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxPaginationModule,
    GraphQLModule,
    HttpClientModule,
    MultilineDotdotdotModule,
    FormsModule,
    EllipsisModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DateInputsModule,
    ModalModule.forRoot(),
    // UploadModule,
    SimpleNotificationsModule.forRoot({
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        maxLength: 200,
        position: ['top', 'right'],
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es-Ar'
  }
  ],
  entryComponents: [
    ModalHomeComponent,
    ModalAcademyComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  const urlDinamicPrd = environment.dictionaryUrl;

  return new TranslateHttpLoader(http, urlDinamicPrd, '.json');
}

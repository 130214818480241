import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { ContactHelpService } from 'src/app/core/services/forms/contact.help.service';


@Component({
  selector: 'app-modal-academy',
  templateUrl: './modal-academy.component.html',
  styleUrls: ['./modal-academy.component.scss']
})
export class ModalAcademyComponent implements OnInit {
  submitted = false;
  coOtro = false;
  get primName() {return this.userEmails.get('primaryName');}
  get primEmail() { return this.userEmails.get('primaryEmail'); }
  get primAreaText() {
    return this.userEmails.get('primaryAreaText');
 }


  title = 'email-validation-tutorial';
  userEmails = new FormGroup({
    primaryName: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('[a-zA-Z ñáéíóú]{2,254}')]),
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9sinapsis iadb.-]+\\.[a-z global]{2,6}$')]),
    primaryAreaText: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
      Validators.pattern('[a-zA-Z0-9 ñáéíóú , .  + - * () ?¿ !¡]{2,254}')]),
  });

  constructor(private contactHelpService: ContactHelpService,
              private formBuilder: FormBuilder,
              private notificationService: NotificationsService,
              private dialogRef: MatDialogRef<ModalAcademyComponent>) {

               }

  ngOnInit(): void {
  }

  SendProyect(): void {
    this.submitted = true;

    if (this.userEmails.invalid) {
      return;
    }
    const form =
    {
        "data": {
        "type": "node--ayuda_academy",
        "attributes": {
          "title": "Formulario de Ayuda",
          "field_descripcion_ayuda": this.userEmails.value.primaryAreaText,
          "field_email_ayuda": this.userEmails.value.primaryEmail,
          "field_nombre_ayuda": this.userEmails.value.primaryName,
        }
      }
    };

    this.contactHelpService.sendHelp(form).subscribe((result: any) => {
      if (result.data.id !== null) {
        this.notificationService.success('Formulario enviado', '');
        this.dialogRef.close();
      } else {
        console.error('error on insert form');
      }
    });
  }


}

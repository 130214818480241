import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-automatic-text',
  templateUrl: './automatic-text.component.html',
  styleUrls: ['./automatic-text.component.scss']
})
export class AutomaticTextComponent implements OnInit {

  selectedIndex: number = null;
  htmlStr: string;
  showHtmlRecommendation = false;
  @Input() currentInfoLocal: any[] = [];
  constructor() { 

    this.selectedIndex = 0;
  }

  ngOnInit(): void {
    this.htmlStr = this.currentInfoLocal[0].detail;
  }

  setIndex(index: number) {
    this.selectedIndex = index;
    this.htmlStr = this.currentInfoLocal[index].detail;
  }
}



<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="lStoreDataBanner['title']" [description]="lStoreDataBanner['description']"
    [image]="lStoreDataBanner['image']" [color]="lStoreDataBanner['color']" [isButton]="lStoreDataBanner['isButton']"
    [textButton]="lStoreDataBanner['textButton']" [urlRedirect]="urlRedirect" [isScroll]="lStoreDataBanner['isScroll']"
    [icon]="lStoreDataBanner['icon']">
  </app-banner-sites>

  <!-- Section Services -->
  <div class="container-fluid section-gray" (keyup.enter)="searchService()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'servicios' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap">
        <div class="col-md-12 col-sm-12">
          <p class="paragraph-banners"></p>
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchServiceValue"
              placeholder="{{'buscarservicio' | translate}}">
            <button type="button" (click)="searchService()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
          <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
        </div>
        <div class="row container-sites">
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="row w-100">
                <div class="col-12 col-md-4">
                  <div class="content-dropdown dropdown-advanced-search align-items-center"
                    *ngIf="listCategoryServiceOpen">
                    <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'categoria' | translate}}:</p>
                    <kendo-dropdownlist (selectionChange)="selectionCategoryService($event)" class="drop-store"
                      [data]="listCategoryService" [valueField]="'id'" [textField]="'value'"></kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-4" *ngIf="listTypeServiceOpen">
                  <div class="content-dropdown dropdown-advanced-search align-items-center">
                    <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'state' | translate}}:</p>
                    <kendo-dropdownlist (selectionChange)="selectionTypeService($event)" [data]="listTypeService" class="drop-store"
                      [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites" *ngIf="servicesOpen">
      <pagination-controls id="opportunities" (pageChange)="getPageService($event)"
        previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div *ngIf="inTransact.services" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of services | paginate: {
            id: 'opportunities', itemsPerPage: limitService, currentPage: currentPageService, totalItems: totalService
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/services',routerService(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-services [title]="item.title" [text]="item.body.summary" [type]="item.fieldEstado"
              [images]="item.fieldImagenPreview.url">
            </app-card-services>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="services.length === 0 && !inTransact.services">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Section Digital-platforms -->
  <div class="container-fluid py-5 padding-pagination" (keyup.enter)="searchPlatform()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'plataformasdigitales' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap">
        <div class="col-md-12 col-sm-12">
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchPlatformValue"
              placeholder="{{'buscarplataforma' | translate}}">
            <button type="button" (click)="searchPlatform()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
          <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
        </div>
        <div class="row container-sites">
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="row w-100">
                <div class="col-12 col-md-4">
                  <div class="content-dropdown dropdown-advanced-search align-items-center"
                    *ngIf="listTypePlatformOpen">
                    <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'categoria' | translate}}:</p>
                    <kendo-dropdownlist [(ngModel)]="categoryApp" (selectionChange)="selectionTypePlatform($event)" class="drop-store"
                      [data]="listTypePlatform" [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="content-dropdown dropdown-advanced-search align-items-center"
                    *ngIf="listTypeStateOpen">
                    <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'estado' | translate}}:</p>
                    <kendo-dropdownlist (selectionChange)="selectionTypeState($event)" [data]="listTypeState" class="drop-store"
                      [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row container-sites" *ngIf="platformsOpen">
        <pagination-controls id="platform" (pageChange)="getPagePlatform($event)"
          previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
        </pagination-controls>
        <div *ngIf="inTransact.platforms" class="d-flex justify-content-center">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
          <div *ngFor="let item of digitalPrograms | paginate: {
            id: 'platform', itemsPerPage: limitPlatform, currentPage: currentPagePlatform, totalItems: totalPlatform
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
            <a [routerLink]="['/digitalPrograms',routerService(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
              <app-card-digital-platform [title]="item.title" [text]="item.body.summary" [type]="item.fieldTipoSolucion"
                [images]="item.fieldImagenPreview.url">
              </app-card-digital-platform>
            </a>
          </div>
          <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
            *ngIf="digitalPrograms.length === 0 && !inTransact.platforms">
            {{'noResults' | translate}}
          </div>
        </div>
      </div>
    </div>

    <!-- Section Apss-Dapps -->
    <div class="container-fluid section-gray pt-5" (keyup.enter)="searchApp()">
      <div class="row container-sites padding-section-responsive">
        <h2 class="title-sections mb-4 pl-4">{{'appsdapps' | translate}}</h2>
        <div class="col-12 d-flex  align-items-start flex-wrap">
          <div class="col-md-12 col-sm-12">
            <div class="search my-4">
              <input type="text" class="searchTerm" [(ngModel)]="searchAppValue"
                placeholder="{{'buscarproducto' | translate}}">
              <button type="button" (click)="searchApp()" class="searchButton">
                {{'buscar' | translate}} <em class="fa fa-search"></em>
              </button>
            </div>
            <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
          </div>
          <div class="row container-sites">
            <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
              <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">

                <div class="row w-100">
                  <div class="col-12 col-md-4" *ngIf="listCategoryTypeOpen">
                    <div class="content-dropdown dropdown-advanced-search align-items-center">
                      <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'tipodesolucion' | translate}}:</p>
                      <kendo-dropdownlist (selectionChange)="selectionTypeSolution($event)" [data]="listTypeSolution" class="drop-store"
                        [valueField]="'id'" [textField]="'value'">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                  <div class="col-12 col-md-4" *ngIf="listCategoryOpen">
                    <div class="content-dropdown dropdown-advanced-search align-items-center ml-md-3">
                      <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'categoria' | translate}}:</p>
                      <kendo-dropdownlist (selectionChange)="selectionCategorySolutionApp($event)" class="drop-store"
                        [data]="ListCategoryApps" [valueField]="'id'" [textField]="'value'">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                  <div class="col-12 col-md-4" *ngIf="listStateOpen">
                    <div class="content-dropdown dropdown-advanced-search align-items-center ml-md-3">
                      <p class="text-search-advanced p-0 m-0 mr-3 d-inline-block">{{'state' | translate}}:</p>
                      <kendo-dropdownlist (selectionChange)="selectionTypeSolutionApp($event)" class="drop-store"
                        [data]="ListTypeStateApps" [valueField]="'id'" [textField]="'value'">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row container-sites" *ngIf="appsOpens">
        <pagination-controls id="apps" (pageChange)="getPageApp($event)" previousLabel="{{'anterior' | translate}}"
          nextLabel="{{'siguiente' | translate}}">
        </pagination-controls>
        <div *ngIf="inTransact.apps" class="d-flex justify-content-center">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
          <div *ngFor="let item of appsDapps | paginate: {
            id: 'apps', itemsPerPage: limitApp, currentPage: currentPageApp, totalItems: totalApp
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
            <a [routerLink]="['/appsDapps',routerService(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
              <app-card-apps-dapps [title]="item.title" [text]="item.body.summary"
                [type]="item.fieldTipoSolucionAppsDapps" [images]="item.fieldImagenPreview.url">
              </app-card-apps-dapps>
            </a>
          </div>
          <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
            *ngIf="appsDapps.length === 0 && !inTransact.apps">
            {{'noResults' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid production-environment py-5">
      <div class="row container-sites padding-section-responsive">
        <div class="col-12 padding-section-responsive">
          <p class="paragraph-banners">{{'advertencialstore' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { academyItemsData } from 'src/app/core/mocks/data-banners';
import { CursesService } from 'src/app/core/services/curses.service';
import { GenericCodeService } from 'src/app/core/services/generic-code.service';

@Component({
  selector: 'app-certifiable-courses',
  templateUrl: './certifiable-courses.component.html',
  styleUrls: ['./certifiable-courses.component.scss']
})
export class CertifiableCoursesComponent implements OnInit {
  @ViewChild('gotoCurseList', { static: true }) gotoCurseList: ElementRef;
  public listItems: Array<string> = ['X-Small', 'Small', 'Todos', 'Medium', 'Large', 'X-Large', '2X-Large'];
  public languageItems = {
    open: false,
    data: null,
    total: 0,
  };
  public selectedValue = 'Todos';
  public selectedVLang = 'Todos';
  public selectedVDate = { start: 2021, end: null };
  public curses = {
    open: false,
    data: null,
    total: 0,
  };
  perfil = {
    open: false,
    data: null,
    total: 0,
  };
  category = {
    open: false,
    data: null,
    total: 0,
  };
  isMobile = false;
  isTablet = false;
  currentPageCurses = 0;
  publicationsOpens = false;
  totalCurses = 0;
  limitCurses = 6;
  mainFilterPlatform = [];

  topic = {
    open: false,
    data: null,
    total: 0,
  };
  dateItems = ['Todos', 2019, 2020, 2021];
  fieldsPlatsforms = [];
  searchCommunityValue = '';
  searchCurseValue = '';
  mainFilterService = '';
  fieldsServices = [];
  selectPerfilValue = [];
  selectCategoryValue = [];
  selectedTopicValue = [];
  firstLoad = true;
  searchItemsCurse: any = [{}];
  curseTitleSearch = '';
  searcherMultiple = [];

  listCommunityOpen = false;
  public academyDataBanner: any[] = [];
  urlRedirect = 'subscribe';
  constructor(
    public cursesService: CursesService, private genericService: GenericCodeService, private router: Router,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.academyDataBanner = academyItemsData[0];
    this.loadCurses('').then((x: boolean) => {
      this.curses.open = x;
    });
    this.router.events.subscribe((event: any) => {
      if (((typeof event.url !== 'undefined') && event.url.includes('lang')) || (this.firstLoad)) {
        this.firstLoad = false;
        this.selectedVLang = (localStorage.getItem('lang') === 'es') ? 'Todos' : 'All';
        this.loadComponent();
      }
    });
  }
  searchCursesByUuId(value: string) {
    this.purgeFilterService('title');
    if (value !== '') {
      this.loadCurses(this.concatFilterService(
        `{field:"uuid", operator: IN, value: [${
          this.searcherMultiple.map(d => `"${d.uuid}"`).join(',')}]}`, 'title'));
    } else {
      this.mainFilterService = '';
      this.loadCurses(this.mainFilterService);
    }
  }

  private putFilters(): void {
    if (this.selectedVLang !== undefined && this.selectedVLang !== 'Todos') {
      this.concatFilterPlatform(
        `{field:"field_idioma.entity.field_idioma_key", operator: EQUAL, value: "${
          (this.selectedVLang)}"}`, 'langcode');
    }
    if (this.selectedVDate.start !== null) {
      this.concatFilterPlatform(
        `{field:"field_temporal_curso", operator: BETWEEN, value: ["${
          moment((this.selectedVDate.start + '-01-01')).format('YYYY-MM-DD HH:mm:ss')}", "${
            moment(new Date()).add('years', 50).format('YYYY-MM-DD HH:mm:ss')}"]}`, 'entityChanged');
    }
    if (this.selectPerfilValue.length > 0) {
     this.concatFilterPlatform(
        `{field:"field_tags_perfil.entity.field_perfil_key", operator: IN, value: [${
          this.selectPerfilValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabel');
    }
    if (this.selectCategoryValue.length > 0) {
      this.concatFilterPlatform(
        `{field:"field_tags_categoria.entity.field_categoria_key", operator: IN, value: [${
          this.selectCategoryValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabelCategory');
    }
    if (this.selectedTopicValue.length > 0) {
      this.concatFilterPlatform(
        `{field:"field_tags_tema.entity.field_tema_key", operator: IN, value: [${
          this.selectedTopicValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabelTopic');
    }
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
  }

  loadFilters(): void {
    this.loadLang();
    this.loadPerfil();
    this.loadCategory();
    this.loadTopic();
  }

  loadLang(): void {
    this.languageItems.open = false;
    this.genericService.getLang('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      this.languageItems.data = [];
      if (data.length > 0) {
        this.languageItems.data = data;
        this.languageItems.data.push({
          id: 'Todos',
          value: (localStorage.getItem('lang') === 'es') ? 'Todos' : 'All',
        });
        this.languageItems.total = data.length;
        this.languageItems.open = true;
      }
    });
  }

  onChangeLanguage(event): void {
    if (event !== null && event !== '') {
      this.searchCurses(null);
    }
  }

  onChangeDate($event): void {
    this.searchCurses(null);
  }

  searchVideosFilterReset(): void {
    this.languageItems.open = false;
    this.mainFilterPlatform = [];
    this.selectedVLang = 'Todos';
    this.selectedVDate = { start: null, end: null };
    this.selectPerfilValue = [];
    this.selectCategoryValue = [];
    this.selectedTopicValue = [];
    this.searcherMultiple = [];
    this.searchCurses(null);
    setTimeout(() => {
      this.languageItems.open = true;
    }, 100);
  }

  loadPerfil(): void {
    this.perfil.data = [];
    this.perfil.open = false;
    this.genericService.getPerfil('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.perfil.data = data;
        this.perfil.total = data.length;
        this.perfil.open = true;
      }
    });
  }

  loadCategory(): void {
    this.category.data = [];
    this.category.open = false;
    this.genericService.getCategory('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.category.data = data;
        this.category.total = data.length;
        this.category.open = true;
      }
    });
  }



  loadTopic(): void {
    this.topic.data = [];
    this.topic.open = false;
    this.genericService.getTopic('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.topic.data = data;
        this.topic.total = data.length;
        this.topic.open = true;
      }
    });
  }
  pageChangedCurse(page: number) {
    this.currentPageCurses = page;
    this.loadCurses('');
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  loadCurses(filter) {
    if (this.curseTitleSearch !== '') {
      filter = filter + `{field:"title", operator: LIKE, value: "%${this.curseTitleSearch}%"}`;
    }
    return new Promise((resolve, reject) => {
      this.cursesService.getCurses(filter, this.limitCurses,
        this.getOfsset(this.currentPageCurses, this.limitCurses),
        localStorage.getItem('lang').toUpperCase()).then((data: any) => {
          this.curses.total = data.length;
          if (data.length > 0 && data[0] !== null) {
            this.curses.data = data;
            this.curses.open = true;
          } else {
            this.curses.open = false;
            this.curses.data = [];
          }
          resolve(true);
      });
  });
  }

  getLangNameById(id) {
    return (id === 'es') ? 'español' : 'inglés';
  }

  handleFilterCurse(searcher: string): void {
    if (searcher !== undefined && searcher.length > 3) {
      this.getCursesItems(`{field:"title", operator: LIKE, value: "%${searcher}%"}`);
    }
  }

  getCursesItems(filter) {
    return new Promise((resolve, reject) => {
      this.cursesService.getCurses(filter, this.limitCurses,
        this.getOfsset(this.currentPageCurses, this.limitCurses),
        localStorage.getItem('lang').toUpperCase()).then((data: any) => {
          this.searchItemsCurse = data.map((x) => {
            return {
              name: x.title,
              uuid: x.uuid,
            };
          });
          resolve(true);
        });
    });
  }

  valueChangeCurse(searcher: string): void {
    const value = this.searchItemsCurse.filter((x: any) => x.name === searcher);
    if (value.length > 0) {
      this.searchCurseValue = value[0].name;
      this.searchCursesByUuId(value[0].uuid);
    } else {
      this.searchCurseValue = '';
      this.searchCursesFilter();
    }
  }

  searchCursesFilter(): void {
    this.purgeAllFilterPlatform();
    this.putFilters();
    this.loadCurses(this.mainFilterPlatform.map((x) => x.key).join(''));
  }

  searchCurse() {
    this.purgeFilterService('title');
    if (this.searchCurseValue !== '') {
      this.loadCurses(this.concatFilterService(
        `{field:"uuid", operator: IN, value: [${
          this.searcherMultiple.map(d => `"${d.uuid}"`).join(',')}]}`, 'title'));
    } else {
      this.mainFilterService = '';
      this.loadCurses(this.mainFilterService);
    }
  }

   searchCurses(element): void {
    this.purgeAllFilterPlatform();
    this.putFilters();
    if (element !== null && (this.isMobile || this.isTablet)) {
      element
        .scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
    this.loadCurses(this.mainFilterPlatform.map((x) => x.key).join(''));
  }


  private concatFilterService(filterSlabone: string, field: string) {
    this.mainFilterService = '';
    if (this.fieldsServices.filter((x) => x.id === field).length === 0) {
      this.fieldsServices = this.fieldsServices.filter((x) => x.id !== 'title');
      this.fieldsServices.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsServices = this.fieldsServices.filter((x) => x !== field);
    if (this.fieldsServices.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterService === '') {
        this.mainFilterService = this.mainFilterService + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterService = this.mainFilterService + filterSlabone;
      }
    }
    return this.mainFilterService;
  }

  private purgeFilterService(field: string): void {
    const fieldDataService = this.fieldsServices.filter((x) => x.id === field);
    if (fieldDataService.length > 0) {
      this.fieldsServices = this.fieldsServices.filter((x) => x.id !== field);
    }
  }

  private purgeAllFilterPlatform(): void {
    this.mainFilterPlatform = [];
    this.fieldsServices = [];
    this.fieldsPlatsforms = [];
  }

  concatFilterPlatform(filterSlabone: string, field: string) {
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length === 0) {
      this.fieldsPlatsforms.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsPlatsforms = this.fieldsPlatsforms.filter((x) => x !== field);
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterPlatform.length === 0) {
        this.mainFilterPlatform[this.mainFilterPlatform.length + 1] = {
          key: filterSlabone,
          fieldV: field,
        };
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterPlatform[this.mainFilterPlatform.length + 1] = {
          key: filterSlabone,
          fieldV: field,
        };
      }
    }
  }

  routerEventCurse(title: string) {
    return title.replace(' ', '-');
  }

  searchEntity(): void { }
  searchCommunity() { }

  private loadComponent(): void {
    this.loadFilters();
  }

}

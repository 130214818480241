import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-academic-publications',
  templateUrl: './academic-publications.component.html',
  styleUrls: ['./academic-publications.component.scss']
})
export class AcademicPublicationsComponent implements OnInit {

  currentPagePublications = 0;
  publicationsOpens = false;
  totalPublications = 0;
  limitPublications = 6;
  publications: any;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.getPublicationAcademy('')
  }

  pageChanged(page: number) {
    this.currentPagePublications = page;
    this.getPublicationAcademy('');
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
  }

  getPublicationAcademy(filter: string) {
    this.eventsService.getPublicationAcademy(filter, this.limitPublications,
      this.getOfsset(this.currentPagePublications, this.limitPublications),
      localStorage.getItem('lang').toUpperCase(), (publication, total) => {
        this.publications = publication;
        this.totalPublications = total;
        this.publicationsOpens = true;

      });
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

}

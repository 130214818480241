<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="theBlockchainDataBanner['title']" [description]="theBlockchainDataBanner['description']"
    [image]="theBlockchainDataBanner['image']" [color]="theBlockchainDataBanner['color']"
    [isButton]="theBlockchainDataBanner['isButton']" [textButton]="theBlockchainDataBanner['textButton']"
    [isScroll]="theBlockchainDataBanner['isScroll']" [icon]="theBlockchainDataBanner['icon']">
  </app-banner-sites>

  <!-- <div class="container-fluid section-gray pt-5">
    <div class="row container-sites padding-section-responsive">
      <div class="col-12">
        <div class="sec-title p-3">
          <h2 class="title-sections">{{'entornotestenet' | translate}}</h2>
        </div>
        <p class="paragraph-banners">{{'entornotestenetdesc1' | translate}}</p>
        <p class="paragraph-banners">{{'entornotestenetdesc2' | translate}}</p>
        <a class="btn btn-register" href="https://github.com/lacchain/besu-network/blob/master/PERMISSIONING_PROCESS.md"
          target="_blank" rel="noopener noreferrer">
          {{'procesoRegistro' | translate}}
        </a>
        <app-the-blockchain-iframe></app-the-blockchain-iframe>
      </div>
    </div>
  </div> -->

  <!-- <div class="entities">
    <div class="sec-title">
      <h2 class="title-sections">{{'entidadBlockchain' | translate}}</h2>
      <p class="paragraph-withe text-center">{{'entidadBlockchainDesc' | translate}}:</p>
    </div>
    <div class="container-fuid  container-carousel p-4">
      <owl-carousel-o [options]="customOptions" *ngIf="entitiesOpens">
        <ng-template carouselSlide *ngFor="let item of entities; let i = index">
          <div class="card-slider">
            <div class="row">
              <div class="col-md-12">
                <div class="sec-logo-carousel d-flex flex-wrap align-content-center">
                  <img class="img-fluid logo-carousel" src="{{item.fieldLogoEntidad.url}}" alt="bid">
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 d-flex justify-content-center align-items-center">
                <a href="{{item.fieldSitioWebEntidad.uri}}" target="_blank" rel="noreferrer">
                  <p class="p-link"><strong>{{'sitioweb' | translate}}</strong></p>
                  <div class="global-sect">
                    <em class="fas fa-globe-americas"></em>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div> -->



  <div class="container-fluid production-environment" id="production-environment">
    <div class="row container-sites padding-section-responsive">
      <div class="col-12">
        <!-- <div class="sec-title">
          <h2 class="title-sections">{{'entornoenproduccion' | translate}}</h2>
        </div> -->
        <!-- <div class="content-info-how-are d-flex flex-wrap align-content-center">
          <div class="color-blue">
            <app-automatic-text [currentInfoLocal]="productionTheBlochainLocal"></app-automatic-text>
          </div>
        </div> -->
        <div class="col-12">
          <div class="col-md-12">
            <p class="paragraph-banners text-center">{{'enterpriselacchaindesc1' | translate}}</p>
          </div>
          <div class="col-md-12 text-center">
            <a class="btn btn-register" *ngIf="lang === 'es'"
              href="https://lacnet.com" target="_blank"
              rel="noopener noreferrer">
              Ir a lacnet.com
            </a>
            <a class="btn btn-register" *ngIf="lang === 'en'"
              href="https://lacnet.com/lacnet-eng/" target="_blank"
              rel="noopener noreferrer">
              Go to lacnet.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>

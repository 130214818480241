import { Component, OnInit, Input } from '@angular/core';
import { ecosystemsData } from '../../core/mocks/data-banners';
import { CommunitiesService } from 'src/app/core/services/communities.service';
import { EntitiesService } from 'src/app/core/services/entities.service';
import { EventsService } from 'src/app/core/services/events.service';
import { Icommunity } from 'src/app/shared/interfaces/Icommunities';
import { BluePrintsService } from 'src/app/core/services/blueprints.service';
import { Router } from '@angular/router';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ecosystems',
  templateUrl: './ecosystems.component.html',
  styleUrls: ['./ecosystems.component.scss']
})
export class EcosystemsComponent implements OnInit {
  @Input() communities: Icommunity[];
  public communitiesOpen = false;
  public ecosystemDataBanner: any[] = [];
  listCommunityOpen = false;
  listTypeEventsOpen = false;
  public listItems: Array<string> = ["Nacional", "Local", "Regional", "Sectorial"];
  public listTypeCommunities: Array<any> = [
    {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
    {id: 'Regional', es: 'Regional', en: 'Regional', value: null },
    {id: 'Nacional', es: 'Nacional', en: 'National', value: null },
    {id: 'Local', es: 'Local', en: 'Local', value: null },
    {id: 'Internacional', es: 'Internacional', en: 'International', value: null },
  ];
  public listTypeEvents: Array<any> = [
    {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
    {id: 'Evento virtual', es: 'Evento virtual', en: 'Virtual Event', value: null},
    {id: 'Meetup', es: 'Meetup', en: 'Meetup', value: null},
    {id: 'Simposio', es: 'Simposio', en: 'Simposio', value: null},
    {id: 'Workshop interactivo', es: 'Workshop interactivo', en: 'Interactive workshop', value: null},
    {id: 'Hackathon', es: 'Hackathon', en: 'Hackathon', value: null},
    {id: 'Summit', es: 'Summit', en: 'Summit', value: null},
    {id: 'Webinar', es: 'Webinar', en: 'Webinar', value: null},
  ];

  public informationCommunities = [
    {
      description: 'quiereslanzartucomudesc1',
    },
  ];
  htmlStr:string;
  entities;
  events;
  blueprints;
  private mainFilter = '';
  private mainFilterEvent = '';
  valueFilterStartIn: Date;
  currentPageCommunities: number = 0;
  currentPageEntities: number = 0;
  currentPageEvents: number = 0;
  searchCommunityValue = '';
  searchEntitieValue = '';
  totalCommunities = 0;
  eventsOpen = false;
  totalEvents = 0;
  limitEvents = 6;
  limit = 6;
  totalEntities = 0;
  searchEventValue = '';
  limitEntities = 6;
  entitiesOpen = false;
  bluePrintOpens = false;
  typeCommunity = 'Seleccionar';
  typeEvent = 'Seleccionar';
  showAdvancedSearchCommunitiesClic = false;
  showAdvancedSearchEntitiesClic = false;
  public inTransact = {
    communities: false,
    entities: false,
    events: false,
  };
  // communities: any[] = [];

  constructor(
    private communitiesService: CommunitiesService,
    private entitiesService: EntitiesService,
    private eventsService: EventsService,
    private blueprintService: BluePrintsService,
    private router: Router,
    private seoService: SeoService,
    private title: Title
    ) {
    this.ecosystemDataBanner = ecosystemsData[0];

    this.loadListLang();

    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.ngOnInit();
            this.loadListLang();
        }
    });
  }

  private loadListLang(): void {
    this.listCommunityOpen = false;
    this.listTypeEventsOpen = false;

    setTimeout(() => {
      this.listTypeCommunities = this.listTypeCommunities.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listCommunityOpen = true;

      this.listTypeEvents = this.listTypeEvents.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listTypeEventsOpen = true;
    }, 200);
  }

  public onChangeEventInitCommunities(event) {
    if (event !== null) {
      this.getCommunities(this.concatFilter(`{field:"field_fecha_comunidad", operator: GREATER_THAN_OR_EQUAL, value: "${event}"}`));
    }
  }

  private concatFilter(filterSlabone: string) {
    if (this.mainFilter === '') {
      this.mainFilter = this.mainFilter + filterSlabone;
    } else {
      this.mainFilter = this.mainFilter + ',' + filterSlabone;
    }
    return this.mainFilter;
  }

  private concatFilterEvent(filterSlabone: string) {
    if (this.mainFilterEvent === '') {
      this.mainFilterEvent = this.mainFilterEvent + filterSlabone;
    } else {
      this.mainFilterEvent = this.mainFilterEvent + ',' + filterSlabone;
    }
    return this.mainFilterEvent;
  }


  showAdvancedSearch() {
    this.showAdvancedSearchCommunitiesClic = !this.showAdvancedSearchCommunitiesClic;
  }

  showAdvancedSearchEntities() {
    this.showAdvancedSearchEntitiesClic = !this.showAdvancedSearchEntitiesClic;
  }

  ngOnInit(): void {
    this.getCommunities(this.mainFilter);
    this.getEntities('');
    this.getEvents(this.mainFilterEvent);
    this.getBluePrints();
    this.htmlStr = this.informationCommunities[0].description;
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Ecosistema | LACChain es la acción conjunta de sus aliados para habilitar para la región una infraestructura dirigida todo tipo de personas',
      site_name: 'LCChain',
      description: 'LACChain es la acción conjunta de sus aliados para habilitar para la región una infraestructura dirigida todo tipo de personas y organizaciones que consideren que la tecnología block...',
      slug: 'ecosystems',
      image: 'https://www.lacchain.net/assets/images/cities/ecosistema.png'
    });
    this.title.setTitle('Ecosistema | LACChain es la acción conjunta de sus aliados para habilitar para la región una infraestructura dirigida todo tipo de personas');
  }

  selectionTypeEventChange(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeEvent = value;
    if (value !== 'Seleccionar') {
      this.getEvents(this.concatFilterEvent(`{field:"field_tipo_evento_ecosistema", operator: EQUAL, value: "${value}"}`));
    } else {
      this.getEvents(this.mainFilterEvent);
    }
    this.searchEvent();
  }

  selectionTypeCommunityChange(event) {
    this.mainFilter = '';
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeCommunity = value;
    if (value !== 'Seleccionar') {
      this.getCommunities(this.concatFilter(`{field:"field_tipo_de_comunidad", operator: EQUAL, value: "${value}"}`));
    } else {
      this.getCommunities(this.mainFilter);
    }
    this.searchCommunity();
  }

  getBluePrints(): void {
    this.blueprintService.getBluePrints(localStorage.getItem('lang').toUpperCase(), (_prints, total) => {
      this.blueprints = _prints;
      this.bluePrintOpens = true;
    });
  }

  getCommunities(filter: string) {
    this.inTransact.communities = true;
    this.communitiesService.getCommunities(filter,
      this.limit,
      this.getOfsset(this.currentPageCommunities, this.limit),
      localStorage.getItem('lang').toUpperCase(),(_communities, total) => {
      this.communities =  _communities;
      this.totalCommunities = total;
      this.inTransact.communities = false;
      this.communitiesOpen = true;
    });
  }

  getEntities(filter: string) {
    this.inTransact.entities = true;
    this.entitiesService.getEntities(filter,
      this.limitEntities,
      this.getOfssetEntities(this.currentPageEntities, this.limitEntities),
      localStorage.getItem('lang').toUpperCase(),(_entities, total) => {
      this.entities =  _entities;
      this.totalEntities = total;
      this.inTransact.entities = false;
      this.entitiesOpen = true;
    });
  }
  getEvents(filter: string) {
    this.inTransact.events = true;
    this.eventsService.getEvents(filter,
      this.limitEvents,
      this.getOfsset(this.currentPageEvents, this.limitEvents),
      localStorage.getItem('lang').toUpperCase(),(_events, total) => {
      this.events =  _events;
      this.totalEvents = total;
      this.inTransact.events = false;
      this.eventsOpen = true;
    });
  }

  getPageCommunities(page: number) {
      this.currentPageCommunities = page;
      this.searchCommunity();
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  private getOfssetEntities(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }


  getPageEntities(page: number) {
      this.currentPageEntities = page;
      this.getEntities('');
  }

  getPageEvents(page: number) {
      this.currentPageEvents = page;
      this.getEvents('');
  }

  searchCommunity(): void {
    if (this.searchCommunityValue !== '') {
      this.getCommunities(this.concatFilter(`{field:"title", operator: LIKE, value: "%${this.searchCommunityValue}%"}`));
    } else {
      this.mainFilter = '';
      this.selectionTypeCommunityChange(this.typeCommunity);
      this.getCommunities(this.mainFilter);
    }
  }

  searchEntity(): void {
    if (this.searchEntitieValue !== '') {
      this.getEntities(`{field:"title", operator: LIKE, value: "%${this.searchEntitieValue}%"}`);
    } else {
      this.getEntities('');
    }
  }

  searchEvent(): void {
    if (this.searchEventValue !== '') {
      this.getEvents(this.concatFilterEvent(`{field:"title", operator: LIKE, value: "%${this.searchEventValue}%"}`));
    } else {
      this.mainFilterEvent = '';
      this.getEvents(this.mainFilterEvent);
      this.selectionTypeEventChange(this.typeEvent);
    }
  }

  routerCommunities(title: string) {
    return title.replace(' ', '-');
  }


  /*onChangeTypeCommunitie(event): void {
    this.getEvents(this.concatFilter(`{field:"field_tipo_evento_ecosistema", operator: EQUAL, value: "${event}"}`));
  }*/

}

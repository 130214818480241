<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="eventDetailOpen">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 m-auto pt-5">
          <div class="card-event-container p-0 center">
            <img class="image-event img-org" [src]="eventsId.fieldImagenAcademy.url" alt="">
          </div>
          <br>
          <div *ngIf="eventsId.fieldImagenInstuctor" class="d-flex color-academy justify-content-center p-0">
            <div *ngFor="let image of eventsId.fieldImagenInstuctor" class="mr-3">
              <span class="d-block color-academy center mb-2">{{ image.entity.name }}</span>
              <img [src]="image.entity.fieldImagenInstructor.url" class="img-instructor" [alt]="image.entity.name">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-academy ">
            {{eventsId.title}}
          </h2>
          <hr class="line-title-banner color-academy">
          <div class="info-all mt-4">
            <span class="d-block color-academy">
              {{'fechaevento' | translate}}:
              <p class="p-nomaice d-inline-block"> {{date | date:'dd MMM yyyy'}}.
              </p>
            </span>
            <span class="d-block color-academy">
              {{'hora' | translate}}:
              <!-- <p class="p-nomaice d-inline-block"> {{date | date:'h:mm a'}} </p> -->
              <p class="p-nomaice d-inline-block" [innerHTML]="eventsId.fieldHoraPersonalizada.processed"></p>
            </span>
            <span class="d-block color-academy">
              <p class="p-nomaice d-inline-block" [innerHTML]="eventsId.fieldDescripcionEvento.processed"
                [ngClass]="{'overflowHidden': eventsId.fieldDescripcionEvento.processed.length > 400 && idHiddenShowDescription}">
              </p>
            </span>
            <button class="btn btn-seeMore" type="button" *ngIf="eventsId.fieldDescripcionEvento.processed.length > 400"
              (click)="hiddenShowDescription()">
              <span *ngIf="idHiddenShowDescription">
                {{'vermas' | translate}}
                <em class="fas fa-angle-down"></em>
              </span>
              <span *ngIf="!idHiddenShowDescription">
                {{'vermenos' | translate}}
                <em class="fas fa-angle-up"></em>
              </span>
            </button>
            <span class="d-block color-academy">
              {{'impartidopor' | translate}}:
              <br>
              <p class="p-nomaice d-inline-block" [innerHTML]="eventsId.fieldImpartido.processed"
                [ngClass]="{'overflowHidden': eventsId.fieldImpartido.processed.length > 140 && idHiddenShowImpartial}">
              </p>
            </span>
            <button class="btn btn-seeMore" type="button" *ngIf="eventsId.fieldImpartido.processed.length > 140"
              (click)="hiddenShowImpartial()">
              <span *ngIf="idHiddenShowImpartial">
                {{'vermas' | translate}}
                <em class="fas fa-angle-down"></em>
              </span>
              <span *ngIf="!idHiddenShowImpartial">
                {{'vermenos' | translate}}
                <em class="fas fa-angle-up"></em>
              </span>
            </button>
            <br>
            <button class="btn button-banner" type="button" [ngStyle]="{'background-color': '#09B89D'}" >
              <a [href]="eventsId.fieldLinkEvento.uri" [ngStyle]="{'color': '#FFFFFF'}" target="_blank" rel="noopener noreferrer">{{ "irevento" | translate}}</a>
            </button>


            <!-- <span class="d-block color-academy">
              {{'type' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldTipoEvento']}} </p>
            </span>
            <span class="d-block color-academy">
              {{'lugar' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldLugarEvento']}} </p>
            </span> -->

            <!-- <span class="d-block color-academy">
              {{'puntoContacto' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldPuntoDeContactoEvento']}} </p>
            </span>
            <span class="d-block color-academy">
              {{'sitioweb' | translate}}:
              <p *ngIf="eventsId['fieldSitioWeb'] !== null" class="p-nomaice d-inline-block">
                {{eventsId['fieldSitioWeb']['uri']}} </p>
            </span> -->
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12 col-md-12 pt-5 padding-section-responsive">
          <span class="d-block color-academy pb-2" *ngIf="eventsId['fieldAgendaEvento'] !== null">
            {{'agenda' | translate}}:
          </span>
          <span class="d-block color-academy" *ngIf="eventsId['fieldAgendaEvento'] !== null">
            <p class="p-nomaice d-inline-block" [innerHTML]="eventsId['fieldAgendaEvento']['processed']"></p>
          </span>
        </div>
      </div> -->
    </div>
  </div>

  <div class="our-allies" *ngIf="eventsRouletteOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of events; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/recent-content', routerEventParticipation(item.fieldTituloIngles))"
                class="btn  btn-block mt-2" role="button">
                <app-card-content [title]="item.title" [formatDate]="item.fieldFechaInicioEvento.date"
                  [text]="item.fieldDescripcionEvento.summary" [images]="getImageTypeContent(item)"
                  [colorAcademy]="item.type.targetId">
                </app-card-content>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>

<app-footer></app-footer>

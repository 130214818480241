import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-apps-dapps',
  templateUrl: './card-apps-dapps.component.html',
  styleUrls: ['./card-apps-dapps.component.scss']
})
export class CardAppsDappsComponent implements OnInit {

@Input() title: string;
@Input() text: string;
@Input() type: string;
@Input() images: string;

  constructor() { }

  ngOnInit(): void {
  }

}

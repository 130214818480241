<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" (click)="servicesOpensId">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 center pt-5">
          <img class="img-fluid img-org" [src]="servicesId['fieldIa']['url']" alt="">
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-l-store ">
            {{servicesId['title']}}
          </h2>
          <hr class="line-title-banner color-l-store">
          <p class="p-nomaice" [innerHTML]="servicesId['body']['processed']"></p>
          <p class="p-nomaice">{{servicesId['text']}}</p>

          <span class="d-block color-l-store">
            {{'tipodesolucion' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldTipoDeSolucion']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'plataforma' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldPlataformaServicio']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'categoria' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldCategoriaServicio']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'estado' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldEstado']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'sede' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldSedeLegal']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'idiomasdisponibles' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldIdiomasDisponibleServics'].join(', ')}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'puntoContacto' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldPuntosDeContacto']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'sitioweb' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldSitioWebServicio']['uri']}} </p>
          </span>
          <span class="d-block color-l-store">
            {{'sitiodescarga' | translate}}:
            <p class="p-nomaice d-inline-block"> {{servicesId['fieldSitioDescarga']}} </p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies" *ngIf="servicesOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of services; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/services',routerService(item.fieldTituloIngles))" class="btn btn-block mt-2 maxw28rem"
                role="button">
                <app-card-services [title]="item.title" [text]="item.body.summary" [type]="item.fieldTipoDeSolucion"
                  [images]="item.fieldImagenPreview.url">
                </app-card-services>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { theBlockchainData, productionTheBlochain } from '../../core/mocks/data-banners';
import { SeoService } from '../../core/services/seo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TheBlockchainService } from 'src/app/core/services/the-blockchain.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-the-blockchain',
  templateUrl: './the-blockchain.component.html',
  styleUrls: ['./the-blockchain.component.scss']
})
export class TheBlockchainComponent implements OnInit {
  public theBlockchainDataBanner: any[] = [];
  public productionTheBlochainLocal: any[] = [];

  statusDots: boolean;
  statusNav: boolean;
  isMobile: boolean;
  isTablet: boolean;
  customOptions: OwlOptions = {};
  selectedIndex: number = null;
  entitiesOpens = false;
  lang = 'en';

  @Input() entities: any[];

  constructor(
    private seoService: SeoService,
    private title: Title,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private blockChainServices: TheBlockchainService,
  ) {
    this.theBlockchainDataBanner = theBlockchainData[0];
    this.productionTheBlochainLocal = productionTheBlochain;
    this.selectedIndex = 0;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.detectMobilCarrusel();

    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
  }

  ngOnInit(): void {
    this.flags();
    this.getEntities();
    this.lang = localStorage.getItem('lang');
  }

  flags() {
    this.seoService.generateFlags({
      title: 'La Blockchain | Las redes de blockchain LACChain son la infraestructura que habilita el desarrollo del ecosistema blockchain en América Latina y el Caribe',
      site_name: 'LCChain',
      description: 'Las redes de blockchain LACChain son la infraestructura que habilita el desarrollo del ecosistema blockchain en América Latina y el Caribe que promueve...',
      slug: 'the-blockchain',
      image: 'https://www.lacchain.net/assets/images/cities/blockchain.png'
    });
    this.title.setTitle('La Blockchain | Las redes de blockchain LACChain son la infraestructura que habilita el desarrollo del ecosistema blockchain en América Latina y el Caribe');
  }

  detectMobilCarrusel(){
    if (this.isMobile === true || this.isTablet === true) {
      this.statusDots = false;
      this.statusNav = true;
      this.customOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };
    }else{
      this.statusDots = true;
      this.statusNav = false;
      this.customOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };
    }
  }

  getEntities() {
    this.blockChainServices.getEntities(localStorage.getItem('lang').toUpperCase(),(_rntities, total) => {
      this.entities = _rntities;
      this.entitiesOpens = true;
    });
  }

}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class NewContentLacchainService {

  constructor(private apollo: Apollo) { }

  getNewContent(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit , offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query nuevo_contenido_lacchain {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "created", direction: DESC},
            filter: {conjunction: AND,
            groups: [
              {conjunction: OR, conditions: [
                { operator: EQUAL,
                  field: "type",
                  value: "Eventos_Lacchain"},
                {operator: EQUAL,
                  field: "type",
                  value: "Eventos_ecosistema"},
                {operator: EQUAL,
                  field: "type",
                  value: "Comunidades"},
                {operator: EQUAL,
                  field: "type",
                  value: "Entidades"},
                {operator: EQUAL,
                  field: "type",
                  value: "Proyectos"},
                {operator: EQUAL,
                  field: "type",
                  value: "Noticias"}
              ]},
              {conditions: [
                {operator: EQUAL,
                  field: "status",
                  value: "1",
                  language: ${lang}
                }
              ${filter}]},
            ]}) {
            count
            entities {
              ...on Node {
                title
                uuid
                entityId
                type {
                  targetId
                }
              }
              ...on NodeEventosLacchain {
                fieldTituloIngles
                fieldFechaInicioDeEvento {
                  date
                  value
                }
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
              ...on NodeEventosEcosistema {
                fieldTituloIngles
                fieldFechaInicioDeEvento {
                  date
                  value
                }
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
              ...on NodeEntidades {
                fieldTituloIngles
                fieldFechaConstitucion {
                  date
                  value
                }
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
              ...on NodeComunidades {
                fieldTituloIngles
                fieldFechaComunidad
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
              ...on NodeProyectos {
                fieldTituloIngles
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
              ...on NodeNoticias {
                fieldTituloIngles
                fieldFechaPublicacionNoticia {
                  date
                  value
                }
                body {
                  summary
                }
                fieldImagenPreview {
                  url
                }
              }
            }
          }
        }
      `;
  }

  getRecentContent(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getRecentContentQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.nodeQuery.entities,
            result.data.nodeQuery.count
          );
        },
        error => { console.log(error) });
  }
  private getRecentContentQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query contenido_reciente {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "created", direction: DESC},
            filter: {conjunction: AND,
            groups: [
              {conjunction: OR, conditions: [
                { operator: EQUAL,
                  field: "type",
                  value: "Curso"},
                {operator: EQUAL,
                  field: "type",
                  value: "Videos_Educativos"}
              ]},
              {conditions: [
                {operator: EQUAL,
                  field: "status",
                  value: "1",
                  language: ES
                }
              ${filter}]},
            ]}) {
            count
            entities {
              ...on Node {
                title
                uuid
                entityId
                type {
                  targetId
                }
              }
              ...on NodeCurso {
                fieldDescripcionCurso {
                  summary
                }
                fieldImagenPreviewCurso {
                  url
                }
              }
              ...on NodeVideosEducativos {
                fieldDesVideoEducativo {
                  summary
                }
                fieldImagenVideoEducativo {
                  url
                }
              }
            }
          }
        }
      `;
  }
}

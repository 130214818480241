<div class="container-fluid section-contact-us st-suscribe p-0">
  <div class="back-contact-us">
    <div class="container-entrepreneurship h-100 d-flex flex-wrap align-content-center justify-content-end">
      <div class="container-contact-us">
        <h3 class="suscribe-principal">{{'suscribir' | translate}}</h3>
        <h3 class="suscribe-secondary">{{'suscribirme' | translate}}</h3>
        <form role="form" [formGroup]="form" (ngSubmit)="SendProyect()" novalidate>
          <div class="form-scroll">

            <div class="form-group form-group-lstore">
              <label for="name">{{'nombre' | translate}}:</label>
              <input [class.is-invalid]="primName.invalid && primName.touched" type="text" formControlName="name"
                maxlength="255" class="form-control form-control-lstore" placeholder="">

              <div *ngIf="((primName.invalid && primName.touched) || primName.dirty) || submitted">
                <small *ngIf="primName.errors?.required" class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primName.errors?.pattern" class="text-danger d-block text-left">
                  {{'fomatonovalido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="email">{{'correoelectronico' | translate}}:</label>
              <input [class.is-invalid]="primEmail.invalid && primEmail.touched" type="email" class="form-control"
                placeholder="" formControlName="email" />
              <div *ngIf="((primEmail.invalid && primEmail.touched) || primEmail.dirty) || submitted">
                <small *ngIf="primEmail.errors?.required" class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primEmail.errors?.pattern" class="text-danger d-block text-left">
                  {{'fomatonovalido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="contactPhone">{{'pais' | translate}}:</label>
              <input [class.is-invalid]="primContries.invalid && primContries.touched" type="text"
                formControlName="contriesInp" maxlength="255" class="form-control form-control-lstore" placeholder="">

              <div *ngIf="((primContries.invalid && primContries.touched) || primContries.dirty) || submitted">
                <small *ngIf="primContries.errors?.required" class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primContries.errors?.pattern" class="text-danger d-block text-left">
                  {{'fomatonovalido' | translate}}
                </small>
              </div>

            </div>


            <div class="form-group form-group-lstore">
              <label for="contactPhone">{{'idioma' | translate}}:</label>
              <kendo-combobox (selectionChange)="selectionChangeLang($event)" [filterable]="true" [data]="lengItem"
                [allowCustom]="allowCustom">
              </kendo-combobox>
              <div *ngIf="language ===  undefined && submitted">
                <small class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="contactPhone">{{'audiencia' | translate}}:</label>
              <kendo-combobox (selectionChange)="selectionChangeProfile($event)" [filterable]="true"
                [data]="profileItem" [allowCustom]="allowCustom">
              </kendo-combobox>
              <div *ngIf="profile ===  undefined && submitted">
                <small class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="contactPhone">{{'tema' | translate}}:</label>
              <kendo-combobox (selectionChange)="selectionChangeTheme($event)" [filterable]="true" [data]="temeItem"
                [allowCustom]="allowCustom">
              </kendo-combobox>
              <div *ngIf="theme ===  undefined && submitted">
                <small class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="businessRepresent">{{'industria' | translate}}:</label>
              <input [class.is-invalid]="primIndustry.invalid && primIndustry.touched" type="text"
                formControlName="industryInp" maxlength="255" class="form-control form-control-lstore" placeholder="">

              <div *ngIf="(primIndustry.invalid && primIndustry.touched) || primIndustry.dirty">
                <small *ngIf="primIndustry.errors?.required" class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primIndustry.errors?.pattern" class="text-danger d-block text-left">
                  {{'fomatonovalido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label for="businessRepresent">{{'cargo' | translate}}:</label>
              <input [class.is-invalid]="primPosition.invalid && primPosition.touched" type="text"
                formControlName="positionInp" maxlength="255" class="form-control form-control-lstore" placeholder="">

              <div *ngIf="(primPosition.invalid && primPosition.touched) || primPosition.dirty">
                <small *ngIf="primPosition.errors?.required" class="text-danger d-block text-left">
                  {{'esRequerido' | translate}}
                </small>
                <!--question mark(?) is a safe navigation operator-->
                <small *ngIf="primPosition.errors?.pattern" class="text-danger d-block text-left">
                  {{'fomatonovalido' | translate}}
                </small>
              </div>
            </div>

            <div class="form-group form-group-lstore">
              <label><a href="https://live-lacchain.pantheonsite.io/sites/default/files/2020-06/T%C3%89RMINOS%20Y%20CONDICIONES_ESPA%C3%91OL_compressed.pdf"
                rel="noopener noreferrer" target="_blank"
              for="nameComplet">{{'terminoscondiciones' | translate}}:</a></label>
              <section class="example-section">
                <mat-checkbox class="example-margin" (change)="aliado = $event.checked">
                  {{'aceptar' | translate}}
                </mat-checkbox>
              </section>
              <div *ngIf="submitted && aliado ==  false " class="invalid-field">
                <div>{{'esRequerido' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="content-btn">
            <button type="submit" class="btn btn-send btn-lg btn-block">{{'enviar' | translate}}</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>




<app-footer></app-footer>

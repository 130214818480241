<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="communitiesIdOpens">
    <div class="container-sites">
      <div class="row">
        <div class="col-12 col-md-6 center pt-5">
          <img class="img-fluid img-org" [src]="communitiesId['fieldImagenComunidad']['url']" alt="">
        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-yellow ">
            {{communitiesId['title']}}
          </h2>
          <hr class="line-title-banner color-yellow">
          <p class="p-nomaice" [innerHTML]="communitiesId['body']['processed']"></p>
          <div class="info-all mt-4">
            <span class="d-block color-yellow">
              {{'type' | translate}}:
              <p class="p-nomaice d-inline-block"> {{communitiesId['fieldTipoDeComunidad']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'sedesolo' | translate}}:
              <p class="p-nomaice d-inline-block"> {{communitiesId['fieldSedeComunidad']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'fechaconstruccion' | translate}}:
              <p class="p-nomaice d-inline-block"> {{communitiesId['fieldFechaComunidad']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'puntocontacto' | translate}}:
              <p class="p-nomaice d-inline-block"> {{communitiesId['fieldPuntoDeContactoComunida']}} </p>
            </span>
            <span class="d-block color-yellow">
              {{'sitioweb' | translate}}:
              <p class="p-nomaice d-inline-block">{{communitiesId['fieldSitioWeb']['uri']}} </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies">
    <div class="container-fuid  container-carousel pb-4 pt-5" *ngIf="communitiesOpens">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of communities; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/communities', routerCommunities(item.fieldTituloIngles))" class="btn  btn-block mt-2"
                role="button">
                <app-card-communities [title]="item.title" [text]="item.body.summary" [type]="item.fieldTipoDeComunidad"
                  [images]="item.fieldImagenPreview.url">
                </app-card-communities>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

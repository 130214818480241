import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancingOpportunitiesService } from 'src/app/core/services/financing-opportunities.service';

@Component({
  selector: 'app-financing-opportunitienities-all',
  templateUrl: './financing-opportunitienities-all.component.html',
  styleUrls: ['./financing-opportunitienities-all.component.scss']
})
export class FinancingOpportunitienitiesAllComponent implements OnInit {

  financingOpportunitiesId: any = {};
  financingOpportunities: any[] = [];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  indrag = false;
  financingOpportunitiesIdsOpens = false;
  financingOpportunitiesOpens = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private financingOpportunitiesService: FinancingOpportunitiesService,
    private router: Router) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getFinancingOpportunitiesId(true);
            this.getFinancingOpportunities();
        }
    });
     }

  ngOnInit(): void {
    this.getFinancingOpportunitiesId(false);
    this.getFinancingOpportunities();
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, title]);
    }
  }

  getFinancingOpportunitiesId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const entitiesId = this.getEvenIdByName(arg.title);
      this.financingOpportunitiesIdsOpens = false;
      this.financingOpportunitiesService.getFinancingOpportunities(`
        {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (entitiesId) : this.financingOpportunitiesId.fieldTituloIngles }"
        },
      `,
      1,
      0,
      localStorage.getItem('lang').toUpperCase(),(events, total) => {
        this.financingOpportunitiesId =  events[0];
        this.financingOpportunitiesIdsOpens = true;
      });
    });
  }


  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getFinancingOpportunities() {
    this.financingOpportunitiesService.getFinancingOpportunities('',
      100,
      0,
      localStorage.getItem('lang').toUpperCase(),(_alliances, total) => {
        this.financingOpportunities =  _alliances;
        this.financingOpportunitiesOpens = true;
    });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  routerFinancingOpportunities(title: string) {
    return title.replace(' ', '-');
  }


}

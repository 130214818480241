import { Component, OnInit } from '@angular/core';
import { lStoreData } from '../../core/mocks/data-banners';
import { DigitalProgramsService } from 'src/app/core/services/digital-programs.service';
import { AppsDappsService } from 'src/app/core/services/apps-dapps.service';
import { StoreServices } from 'src/app/core/services/store.services.service';
import { Router } from '@angular/router';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-l-store',
  templateUrl: './l-store.component.html',
  styleUrls: ['./l-store.component.scss']
})
export class LStoreComponent implements OnInit {
public lStoreDataBanner: any[] = [];
public listItems: Array<string> = ["Nacional", "Local", "Regional", "Sectorial"];
services;
digitalPrograms;
appsDapps: any[] = [];
servicesOpen = false;
limitService = 6;
currentPageService = 0;
totalService = 0;
categoryApp = null;
totalPlatform = 0;
currentPagePlatform = 0;
limitPlatform = 6;
searchServiceValue = '';
platformsOpen = false;
searchPlatformValue = '';
limitApp = 6;
totalApp = 0;
currentPageApp = 0;
searchAppValue = '';
appsOpens = false;
mainFilterService = '';
mainFilterPlatform = '';
mainFilterApp = '';
typeService = 'Seleccionar';
categoryService = 'Seleccionar';
typePlatform = 'Seleccionar';
typeState = 'Seleccionar';
typeSolution = 'Seleccionar';
categorySolution = 'Seleccionar';
typeSolutionState = 'Seleccionar';
listCategoryServiceOpen = false;
listStateOpen = false;
listCategoryOpen = false;
ListTypeStateApps: Array<any> = [
  {id: 'Todos/All', es: 'Todos', en: 'All', value: null},
  {id: 'Beta', es: 'Beta', en: 'Beta', value: null },
  {id: 'Live', es: 'Live', en: 'Live', value: null }
];
ListCategoryApps: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Identidad digital', es: 'Identidad digital', en: 'Digital identity', value: null }
];
listTypeServiceOpen = false;
listTypePlatformOpen = false;
listTypeStateOpen = false;
listCategoryTypeOpen = false;
public listTypeService: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Beta', es: 'Beta', en: 'Beta', value: null },
  {id: 'En diseño', es: 'En diseño', en: 'In Desing', value: null },
  {id: 'En desarrollo', es: 'En desarrollo', en: 'In developing', value: null },
  {id: 'En producción', es: 'En producción', en: 'In production', value: null }
];
public listCategoryService: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Corporaciones', es: 'Corporaciones', en: 'Corporations', value: null },
  {id: 'Pagos y finanzas', es: 'Pagos y finanzas', en: 'Payments and Finances', value: null }
];
public listTypePlatform: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Certificación de documentos', es: 'Certificación de documentos', en: 'Document certification', value: null },
];
public listTypeState: Array<any> = [
  {id: 'Seleccionar', es: 'Todos', en: 'All', value: null},
  {id: 'Beta', es: 'Beta', en: 'Beta', value: null },
];
public listTypeSolution: Array<any> = [
  {id: 'Todos/All', es: 'Todos', en: 'All', value: null},
  {id: 'App', es: 'App', en: 'App', value: null },
  {id: 'Dapp', es: 'Dapp', en: 'Dapp', value: null},
];
  urlRedirect = 'contact';

  inTransact = {
    apps: false,
    services: false,
    platforms: false,
  };

  fieldsPlatsforms = [];
  fieldsServices = [];
  fieldsApps = [];

  constructor(
    private digitalProgramsService: DigitalProgramsService,
    private appsDappsService: AppsDappsService,
    private storeServices: StoreServices,
    private router: Router,
    private seoService: SeoService,
    private title: Title
    ) {
    this.lStoreDataBanner = lStoreData[0];
    this.loadListLang();


    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.ngOnInit();
            this.loadListLang();
        }
    });
  }

  private loadListLang(): void {
    this.listTypeServiceOpen = false;
    this.listCategoryServiceOpen = false;
    this.listTypeStateOpen = false;
    this.listTypePlatformOpen = false;
    this.listCategoryOpen = false;
    this.listStateOpen = false;
    this.listCategoryTypeOpen = false;

    setTimeout(() => {
      this.listTypeService = this.listTypeService.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listTypeServiceOpen = true;

      this.listCategoryService = this.listCategoryService.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listCategoryServiceOpen = true;

      this.listTypePlatform = this.listTypePlatform.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listTypePlatformOpen = true;

      this.listTypeState = this.listTypeState.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listTypeStateOpen = true;

      this.ListCategoryApps = this.ListCategoryApps.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listCategoryOpen = true;

      this.ListTypeStateApps = this.ListTypeStateApps.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listStateOpen = true;

      this.listTypeSolution = this.listTypeSolution.map(x => {
          x.value = x[localStorage.getItem('lang')];
          return x;
        });
      this.listCategoryTypeOpen = true;
    }, 200);
  }

  showAdvancedSearchCommunitiesClic = false;

  showAdvancedSearch() {
    this.showAdvancedSearchCommunitiesClic = !this.showAdvancedSearchCommunitiesClic;
  }

  searchPlatform() {
    this.purgeFilterPlatform('title');
    if (this.searchPlatformValue !== '') {
      this.getdigitalPrograms(this.concatFilterPlatform(
        `{field:"title", operator: LIKE, value: "%${this.searchPlatformValue}%"}`, 'title'));
    } else {
      this.mainFilterPlatform = '';
      this.getdigitalPrograms(this.mainFilterPlatform);
    }
  }

  private purgeFilterPlatform(field: string): void {
    const fieldData = this.fieldsPlatsforms.filter((x) => x.id === field);
    if (fieldData.length > 0) {
      this.fieldsPlatsforms = this.fieldsPlatsforms.filter((x) => x.id !== field);
    }
  }

  private purgeFilterService(field: string): void {
    const fieldDataService = this.fieldsServices.filter((x) => x.id === field);
    if (fieldDataService.length > 0) {
      this.fieldsServices = this.fieldsServices.filter((x) => x.id !== field);
    }
  }

  private purgeFilterApp(field: string): void {
    const fieldDataApp = this.fieldsApps.filter((x) => x.id === field);
    if (fieldDataApp.length > 0) {
      this.fieldsApps = this.fieldsApps.filter((x) => x.id !== field);
    }
  }

  searchApp() {
    this.purgeFilterApp('title');
    if (this.searchAppValue !== '') {
      this.getAppsDapps(this.concatFilterApp(
        `{field:"title", operator: LIKE, value: "%${this.searchAppValue}%"}`, 'title'));
    } else {
      this.mainFilterApp = '';
      this.getAppsDapps(this.mainFilterPlatform);
    }
  }

  getPagePlatform(page: number) {
    this.currentPagePlatform = page;
    this.getdigitalPrograms('');
  }

  selectionTypeService(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeService = value;
    this.purgeFilterService('field_estado');
    if (value !== 'Seleccionar') {
      this.getServices(this.concatFilterService(
        `{field:"field_estado", operator: EQUAL, value: "${value}"}`, 'field_estado'));
    } else {
      this.getServices(this.mainFilterService);
    }
    this.searchService();
  }

  selectionCategoryService(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.categoryService = value;
    this.purgeFilterService('field_categoria_servicio');
    if (value !== 'Seleccionar') {
      this.getServices(this.concatFilterService(
        `{field:"field_categoria_servicio", operator: EQUAL, value: "${value}"}`, 'field_categoria_servicio'));
    } else {
      this.getServices(this.mainFilterService);
    }
    this.searchService();
  }

  selectionTypePlatform(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typePlatform = value;
    this.purgeFilterPlatform('field_categoria_soluciones');
    if (value !== 'Seleccionar') {
      this.getdigitalPrograms(this.concatFilterPlatform(
        `{field:"field_categoria_soluciones", operator: EQUAL, value: "${value}"}`, 'field_categoria_soluciones'));
    } else {
      this.getdigitalPrograms(this.mainFilterPlatform);
    }
    this.searchPlatform();
  }

  selectionTypeSolution(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.purgeFilterApp('field_tipo_solucion_apps_dapps');
    this.typeSolution = value;
    if (value !== 'Todos/All') {
      this.getAppsDapps(this.concatFilterApp(
        `{field:"field_tipo_solucion_apps_dapps", operator: EQUAL, value: "${value}"}`, 'field_tipo_solucion_apps_dapps'));
    } else {
      this.getAppsDapps(this.mainFilterApp);
    }
    this.searchApp();
  }

  selectionTypeSolutionApp(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.purgeFilterApp('field_estado_app');
    this.typeSolutionState = value;
    if (value !== 'Todos/All') {
      this.getAppsDapps(this.concatFilterApp(
        `{field:"field_estado_app", operator: EQUAL, value: "${value}"}`, 'field_estado_app'));
    } else {
      this.getAppsDapps(this.mainFilterApp);
    }
    this.searchApp();
  }

  public selectionCategorySolutionApp(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.purgeFilterApp('field_categoria_app_dapp');
    this.categorySolution = value;
    if (value !== 'Seleccionar') {
      this.getAppsDapps(this.concatFilterApp(
        `{field:"field_categoria_app_dapp", operator: EQUAL, value: "${value}"}`, 'field_categoria_app_dapp'));
    } else {
      this.getAppsDapps(this.mainFilterApp);
    }
    this.searchApp();
  }

  selectionTypeState(event) {
    const value = (typeof event.id !== 'undefined') ? event.id : event;
    this.typeState = value;
    this.purgeFilterPlatform('field_estado_solucion');
    if (value !== 'Seleccionar') {
      this.getdigitalPrograms(this.concatFilterPlatform(
        `{field:"field_estado_solucion", operator: EQUAL, value: "${value}"}`,
        'field_estado_solucion'
        ));
    } else {
      this.getdigitalPrograms(this.mainFilterPlatform);
    }
    this.searchPlatform();
  }


  private concatFilterService(filterSlabone: string, field: string) {
    if (this.fieldsServices.filter((x) => x.id === field).length === 0) {
      this.fieldsServices.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsServices = this.fieldsServices.filter((x) => x !== field);
    if (this.fieldsServices.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterService === '') {
        this.mainFilterService = this.mainFilterService + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterService = this.mainFilterService + filterSlabone;
      }
    }
    return this.mainFilterService;
  }

  private concatFilterPlatform(filterSlabone: string, field: string) {
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length === 0) {
      this.fieldsPlatsforms.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsPlatsforms = this.fieldsPlatsforms.filter((x) => x !== field);
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterPlatform === '') {
        this.mainFilterPlatform = this.mainFilterPlatform + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterPlatform = this.mainFilterPlatform + filterSlabone;
      }
    }
    return this.mainFilterPlatform;
  }

  private concatFilterApp(filterSlabone: string, field: string) {
    if (this.fieldsApps.filter((x) => x.id === field).length === 0) {
      this.fieldsApps.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsApps = this.fieldsApps.filter((x) => x !== field);
    if (this.fieldsApps.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterApp === '') {
        this.mainFilterApp = this.mainFilterApp + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterApp = this.mainFilterApp + filterSlabone;
      }
    }
    return this.mainFilterApp;
  }

  getPageApp(page: number) {
    this.currentPageApp = page;
    this.getAppsDapps('');
  }

  ngOnInit(): void {
    this.getServices('');
    this.getdigitalPrograms('');
    this.getAppsDapps('');
    this.flags();
  }

  flags() {
    this.seoService.generateFlags({
      title: 'L-Store | Publicaremos los productos y soluciones que sus promotores quieran divulgar establecidos sobre las infraestructuras de LACChain',
      site_name: 'LCChain',
      description: 'Publicaremos los productos y soluciones que sus promotores quieran divulgar establecidos sobre las infraestructuras de LACChain. Desde aquí accederás a...',
      slug: 'l-store',
      image: 'https://www.lacchain.net/assets/images/cities/l-store.png'
    });
    this.title.setTitle('L-Store | Publicaremos los productos y soluciones que sus promotores quieran divulgar establecidos sobre las infraestructuras de LACChain');
  }

  searchService() {
    this.purgeFilterService('title');
    if (this.searchServiceValue !== '') {
      this.getServices(this.concatFilterService(
        `{field:"title", operator: LIKE, value: "%${this.searchServiceValue}%"}`, 'title'));
    } else {
      this.mainFilterService = '';
      this.getServices(this.mainFilterService);
    }
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  getServices(filter: string) {
    this.inTransact.services = true;
    this.storeServices.getServices(filter,
    this.limitService,
    this.getOfsset(this.currentPageService, this.limitService),
    localStorage.getItem('lang').toUpperCase(),(_services, total) => {
    this.inTransact.services = false;
    this.services =  _services;
    this.totalService = total;
    this.servicesOpen = true;
    });
  }

  getPageService(page: number) {
    this.currentPageService = page;
    this.getServices('');
  }

  getdigitalPrograms(filter: string) {
    this.inTransact.platforms = true;
    this.digitalProgramsService.getPlatforms(filter,
      this.limitPlatform,
      this.getOfsset(this.currentPageService, this.limitPlatform),
      localStorage.getItem('lang').toUpperCase(),(_platforms, total) => {
      this.inTransact.platforms = false;
      this.digitalPrograms =  _platforms;
      this.totalPlatform = total;
      this.platformsOpen = true;
    });
  }

  getAppsDapps(filter: string) {
    this.inTransact.apps = true;
    this.appsDappsService.getAppsDapps(filter,
      this.limitApp,
      this.getOfsset(this.currentPageApp, this.limitApp),
      localStorage.getItem('lang').toUpperCase()).subscribe((result: any) => {
        this.inTransact.apps = false;
        const apps = result.data.nodeQuery.entities;
        const total = result.data.nodeQuery.count;
        this.appsDapps =  apps;
        this.totalApp = total;
        this.appsOpens = true;
    });
  }

  routerService(title: string) {
    return title.replace(' ', '-');
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

  constructor(public translateService: TranslateService) {
    console.log("Browser lang: ",translateService.getBrowserLang());
    this.translateService.currentLang = 'es';
    translateService.addLangs(['en', 'es']);
    translateService.setDefaultLang('es');
  }
  title = 'LACChain';

  ngOnInit(){
    AOS.init({
      disable: 'mobile'
    });
  }
}


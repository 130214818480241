<div
  data-aos="fade-zoom-in"
  data-aos-easing="ease-in-back"
  data-aos-delay="0"
  data-aos-offset="0"
  data-aos-duration="500"
>
  <app-schedule-call-banner></app-schedule-call-banner>
  <div class="container-fluid">
    <div class="container-sites">
      <div class="row">
        <div
          [ngClass]="{ 'col-md-12': !hiddenShowButton }"
          class="col-12 col-md-9 d-block mx-auto col-lg-12"
        >
          <app-cities-map
            [activeCitiesInput]="activeCitiesCard"
          ></app-cities-map>
        </div>
        <!-- <button [ngClass]="{'rotate-click': !hiddenShowButton}" class="btn btn-circle-blue floating-button"
          (click)="hiddenShoBtn()">
          <em class="fas fa-arrow-alt-circle-right"></em>
        </button> -->
        <div
          *ngIf="hiddenShowButton"
          class="col-md-3 container-instructions floating-menu"
        >
          <app-instruction
            (cardPositionEmit)="activeCitiesCard = $event"
          ></app-instruction>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid home-two mt-4">
    <div class="row container-sites back-lacchain">
      <div class="col-12 padding-section-responsive">
        <div class="sec-title-blue">
          <h1 class="title-sections network-real">LACChain</h1>
        </div>
        <div class="sect-tex-video d-flex justify-content-end">
          <iframe
            *ngIf="lang === 'es'"
            [src]="videoUrl | domSafe"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="video-lacchain"
          ></iframe>
          <iframe
            *ngIf="lang === 'en'"
            [src]="videoUrlEn | domSafe"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="video-lacchain"
          ></iframe>
          <div class="sec-p d-flex flex-wrap align-content-center">
            <div class="text-video-home" id="scrollBlu">
              <p>
                {{ "lacchaines" | translate }}
                <a
                  [routerLink]="['/alliance']"
                  class="text-link text-link-home"
                  >{{ "alianzaglobal" | translate }}</a
                >
                {{ "lacchaindesc1" | translate }} (<a
                  href="https://bidlab.org/{{ lang }}"
                  rel="noreferrer"
                  target="_blank"
                  class="text-link text-link-home"
                  >{{ "bidlab" | translate }}</a
                >) {{ "lacchaindesc2" | translate }}
              </p>
              <p>
                {{ "lacchaindesc3" | translate }}:
                <strong>{{ "lacchaindesc4" | translate }}</strong>
              </p>
              <p>{{ "lacchaindesc5" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sec-bid container-fluid">
    <div class="container-sites">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="card-bid">
            <div class="row">
              <div class="col-md-12">
                <div class="sect-logo d-flex flex-wrap align-content-center">
                  <img
                    src="../../../assets/images/animacion-BID-Lab.gif"
                    alt="bid"
                    class="img-fluid logo-carousel"
                  />
                </div>
                <p id="scrollBlu">
                  <a
                    href="https://bidlab.org/{{ lang }}"
                    target="_blank"
                    rel="noreferrer"
                    class="text-link"
                    >{{ "bidlab" | translate }}</a
                  >{{ "bidlabdesc2" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid production-environment pt-2">
    <div class="row container-sites">
      <div class="col-12 padding-section-responsive">
        <div class="sec-title">
          <h2 class="title-sections text-center color-projects">
            {{ "caracteristicasslacchain" | translate }}
          </h2>
        </div>
        <div class="content-info-how-are">
          <div class="color-orange">
            <app-automatic-text
              [currentInfoLocal]="homeLacchainLocal"
            ></app-automatic-text>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section
    *ngIf="homeStadistics.open"
    class="section-banner-one d-flex align-items-center justify-content-center"
  >
    <div class="d-block">
      <div class="row pt-5 padding-movile-default">
        <div class="col-12 col-md-4 mb-3 mb-responsive-small">
          <div class="sec-number">
            <span>{{ homeStadistics.data.fieldNumeroNodos }}</span>
          </div>
          <div class="text-info">
            <h3># {{ "nodos" | translate }}</h3>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-3 mb-responsive-small">
          <div class="sec-number">
            <span>{{ homeStadistics.data.fieldNumeroEntidades }}</span>
          </div>
          <div class="text-info">
            <h3># {{ "entidadesnodos" | translate }}</h3>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-3 mb-responsive-small">
          <div class="sec-number">
            <span>{{ homeStadistics.data.fieldNumeroBloques }}</span>
          </div>
          <div class="text-info">
            <h3># {{ "bloques" | translate }}</h3>
          </div>
        </div>
      </div>
      <div class="row pt-5 padding-movile-default">
        <div class="col-12 col-md-6 mb-3 mb-responsive-small">
          <div class="sec-number">
            <span>{{ homeStadistics.data.fieldAliadosStadistics }}</span>
          </div>
          <div class="text-info">
            <h3># {{ "aliados" | translate }}</h3>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3 mb-responsive-small">
          <div class="sec-number">
            <span>{{ homeStadistics.data.fieldNumeroPaisesEcosistema }}</span>
          </div>
          <div class="text-info">
            <h3># {{ "paiseseco" | translate }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="new-LACChain-content card-content-home">
    <div class="section-gray">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 col-md-5 d-flex flex-wrap align-content-center padding-section-responsive"
          >
            <div class="content-new">
              <div class="sec-title">
                <h2 class="title-sections color-l-store mb-4">
                  {{ "nuevocontenidolacchain" | translate }}
                </h2>
              </div>
              <p>{{ "nuevocontenidolacchaindesc" | translate }}</p>
            </div>
          </div>
          <div class="col-12 col-md-7" *ngIf="eventsOpens">
            <owl-carousel-o
              [options]="customOptionOne"
              (dragging)="dragging($event)"
            >
              <ng-template
                carouselSlide
                *ngFor="let item of events; let i = index"
              >
                <div class="row">
                  <div class="col-sm-12 px-md-3 p-sm-3">
                    <a
                      (click)="
                        goLinkNoDragging(
                          '/' + getUrlTypeContent(item.type.targetId),
                          routerEntities(item.fieldTituloIngles)
                        )
                      "
                      class="btn btn-block mt-2"
                      role="button"
                    >
                      <app-card-content
                        [title]="item.title"
                        [formatDate]="getDateEvent(item)"
                        [text]="item.body.summary"
                        [images]="getImageTypeContent(item)"
                      >
                      </app-card-content>
                    </a>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="launchcommunity" class="network-real padding-movile-default">
    <div class="container-fluid padding-movile-default">
      <div class="row container-sites padding-movile-default">
        <div class="col-12 padding-movile-default">
          <div class="sec-title">
            <h2 class="title-sections text-center color-the-blockchain">
              {{ "laredentiemporeal" | translate }}
            </h2>
          </div>
          <div class="end-section">
            <div class="button-sec">
              <a href="https://lacnet.com/dashboard-lacchain-mainnet-omega" target="_blank" rel="noopener noreferrer"
                class="btn btn-dash">{{'seedashboard' | translate}}</a>
            </div>
          </div>
          <!-- <div class="content-what-iframe">
            <app-the-blockchain-iframe></app-the-blockchain-iframe>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'angular2-notifications';
import { ContactSubscribeService } from 'src/app/core/services/forms/contact.subscribe.service';
import { SeoService } from '../../../core/services/seo.service';

@Component({
  selector: 'app-subscribe-academy',
  templateUrl: './subscribe-academy.component.html',
  styleUrls: ['./subscribe-academy.component.scss']
})
export class SubscribeAcademyComponent implements OnInit {

  showHidenInput: boolean;

  status = false;

  contact = {
    typeProyect: '',
    state: '',
  };
  support = {
    aliado: 'Aceptar',
    data: []
  };
  aliado = false;


  submitted = false;

  language: string;
  profile: string;
  theme: string;
  checked = true;

  public allowCustom = true;
  get primName() { return this.form.get('name'); }
  get primEmail() { return this.form.get('email'); }
  get primContries() { return this.form.get('contriesInp'); }
  get primIndustry() { return this.form.get('industryInp'); }
  get primPosition() { return this.form.get('positionInp'); }

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern('[a-zA-Z ñáéíóú]{2,100}')]),
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9sinapsis iadb.-]+\\.[a-z global]{2,6}$')]),
    contriesInp: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern('[a-zA-Z ñáéíóú]{2,100}')]),
    industryInp: new FormControl('', [
      Validators.maxLength(100),
      Validators.pattern('[a-zA-Z ñáéíóú]{2,100}')]),
    positionInp: new FormControl('', [
      Validators.maxLength(100),
      Validators.pattern('[a-zA-Z ñáéíóú]{2,100}')]),
  });

  public lengItem:
  Array<string> = ['Español', 'Portugúes', 'Inglés'];
  public temeItem:
    Array<string> = ['Gobernanza', 'Estandarización', 'Regulación', 'Tecnología'];
  public profileItem:
    Array<string> = ['Estudiante', 'Desarrollador', 'Emprendedor', 'Regulador', 'Sector público/Gobierno',
                     'Sector privado/Negocio'];

  constructor(
    private contactSubscribeService: ContactSubscribeService,

    private notificationService: NotificationsService,
    private seoService: SeoService,
    private title: Title
  ) {
    this.loadValidations();
  }

  ngOnInit(): void {
    this.flags();
  }
  public selectionChangeLang(value: any): void {
    this.language = value;
  }

  public selectionChangeProfile(value: any): void {
    this.profile = value;
  }

  public selectionChangeTheme(value: any): void {
    this.theme = value;
  }

  flags() {
    this.seoService.generateFlags({
      title: 'LACChain Contacto',
      site_name: 'LCChain',
      description: 'LACChain Contacto',
      slug: 'contact',
      image: ''
    });
    this.title.setTitle('LACChain Contacto');
  }


  private loadValidations(): void {
    // this.form = this.formBuilder.group({
    //   name: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
    //   contactPhone: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
    //   businessRepresent: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
    //   description: ['', Validators.compose([Validators.required, Validators.maxLength(1024)])],
    //   email: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
    //   categoriaOtro: ['', Validators.compose([Validators.maxLength(50)])],
    // });
  }



  SendProyect(): void {

    this.submitted = true;
    if (this.language ===  undefined) {
       return;
    } else if (this.profile === undefined){
      return;

    } else if (this.theme === undefined) {
      return;
    }

    if (this.aliado === false) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const form =
    {
      'data': {
        'type': 'node--suscriptores_academy',
        'attributes': {
          'title': 'Formulario de suscriptores_academy',
          'field_nombre_suscriptor': this.form.value.name,
          'field_email_suscriptores_academy': this.form.value.email,
          'field_pais_suscriptor': this.form.value.contriesInp,
          'field_idioma_suscriptor': this.language,
          'field_perfil_suscriptor': this.profile,
          'field_tema_suscriptor': this.theme,
          'field_industria_suscriptor': this.form.value.industryInp,
          'field_cargo_suscriptor': this.form.value.positionInp
        }
      }
    };

    this.contactSubscribeService.sendSubscribe(form).subscribe((result: any) => {
      if (result.data.id !== null) {
        this.notificationService.success('Formulario enviado', '');
        setTimeout(() => {
          window.location.reload();
        }, 4500);
      } else {
        console.error('error on insert form');
      }
    });
  }



  showInput() {
    this.showHidenInput = !this.showHidenInput;
  }

}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class AlliancesService {


  constructor(private apollo: Apollo) { }

  getAlliances(lang: string, callback) {
    this.apollo.query<any>({
      query: this.getAlliancesQuery(lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getAlliancesQuery(lang: string): DocumentNode {
    return gql`
        query nuestros_aliados {
          nodeQuery (
            limit: 100
            offset: 0
            sort: {field: "title", direction: ASC}
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
                language: ${lang}
              }, {
                operator: EQUAL
                field: "type"
                value: "Nuestros_Aliados"
              }]
            }
          ) {
            count
            entities {
              ...on NodeNuestrosAliados {
                title
                fieldLogoAliado {
                  url
                }
                fieldSitioWeb {
                  uri
                }
              }
            }
          }
        }
      `;
  }
}

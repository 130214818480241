<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="banner-back">
    <app-banner-sites [title]="academyDataBanner['title']" [description]="academyDataBanner['description']"
      [image]="academyDataBanner['image']" [color]="academyDataBanner['color']"
      [isButton]="academyDataBanner['isButton']" [textButton]="academyDataBanner['textButton']"
      [isScroll]="academyDataBanner['isScroll']" [icon]="academyDataBanner['icon']" [urlRedirect]="urlRedirect">
    </app-banner-sites>
  </div>



  <section class="certifiable-courses-content">
    <div class="container-fluid container-sites mx-w100">
      <div class="row">

        <div class="section-gray col-12 col-lg-4 col-xl-3">
          <div class="container-fluid  pt-5 content-one-certifiable-courses">
            <div class="container-sites">
              <div class="col-12">
                <p class="paragraph-banners">
                  {{'laccacademiatextdesarrolloredes' | translate}}
                </p>
                <h2 class="mt-4">
                  {{'seleccionetematicasinteres' | translate}}:
                </h2>
              </div>
              <button class="btn btn-retourns" [routerLink]="['/academy']">
                <em class="fas fa-chevron-left"></em>
                {{'regresar' | translate}}
              </button>
              <div class="row mx-1175px mb-4">
                <div class="col-12 col-md-12 mb-4" *ngIf="languageItems.open">
                  <div class="example-wrapper mb-4 mt-3">
                    <span class="label-dropdownlist mb-2">{{'idioma' | translate}}:</span>
                    <kendo-dropdownlist [data]="languageItems.data" [(ngModel)]="selectedVLang"
                      [valueField]="'keyValue'" [textField]="'value'" [valuePrimitive]="true"
                      (valueChange)="onChangeLanguage($event)">
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-4">
                    <span class="label-dropdownlist mb-2">{{'fecha' | translate}}:</span>
                    <kendo-numerictextbox [(value)]="selectedVDate.start" [min]="1900" [max]="3000"
                      (valueChange)="onChangeDate($event);" [format]="'#'" [step]=1>
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <div class="row mx-1175px">
                <div class="col-12 col-md-12">
                  <!-- <div class="example-wrapper mb-2">
                        <span class="label-dropdownlist">Tipo de contenido:</span>
                        <kendo-dropdownlist [data]="listItems" [(ngModel)]="selectedValue">
                        </kendo-dropdownlist>
                      </div> -->
                  <div class="example-wrapper mb-5">
                    <span class="label-dropdownlist mb-2">{{'audiencia' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchCurses(null)" [data]="perfil.data"
                      [(ngModel)]="selectPerfilValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'"></kendo-multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-4" *ngIf="category.open">
                    <span class="label-dropdownlist mb-2">{{'categoria' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchCurses(null)" [data]="category.data"
                      [(ngModel)]="selectCategoryValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'"></kendo-multiselect>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-3" *ngIf="topic.open">
                    <span class="label-dropdownlist">{{'tema' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchCurses()" [data]="topic.data"
                      [(ngModel)]="selectedTopicValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'"></kendo-multiselect>
                  </div>
                </div> -->
              </div>
              <div class="center">
                <button type="button" (click)="searchCurses(gotoC)" name="" id=""
                  class="btn btn-searchButton mt-4 mb-2 mr-4">
                  {{'buscar' | translate}}
                </button>
                <button type="button" name="" id="" (click)="searchVideosFilterReset()"
                  class="btn btn-searchButton mt-4 mb-5">{{'borrar' | translate}} </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8 col-xl-9 d-flex flex-wrap padding-section-responsive mtp-3">
          <div class="content-new w-100">
            <div class="sec-title">
              <h2 class="title-sections color-academy mb-4">
                {{'cursoscertificables' | translate}}
              </h2>
            </div>
            <p>
              {{'descubreproximoseventos' | translate}}
            </p>

            <div class="col-12 col-resposive" (keyup.enter)="searchCurse()">
              <div class="search my-4 video-search autocomplete-to-multiselect">
                <kendo-multiselect [data]="searchItemsCurse" [valueField]="'name'" [filterable]="true"
                  (filterChange)="handleFilterCurse($event)"
                  [textField]="'name'"
                  (valueChange)="valueChangeCurse($event)"
                  [(ngModel)]="searcherMultiple" placeholder="{{'buscarportitulo' | translate}}">
                </kendo-multiselect>
                <button type="submit" class="searchButton" (click)="searchCurse()">
                  {{'buscar' | translate}} <em class="fa fa-search"></em>
                </button>
              </div>
              <div id="gotoCurses" #gotoC class="sec-title">
                <div class="row container-sites" *ngIf="curses.open">

                  <pagination-controls id="curceTwo" (pageChange)="pageChangedCurse($event)" responsive="true"
                    previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
                  </pagination-controls>

                  <div class="card-certifiable-courses" *ngFor="let curse of curses.data | paginate: {
                      id: 'curceTwo', itemsPerPage: limitCurses, currentPage: currentPageCurses, totalItems: totalCurses
                    }; let i = index">
                    <a [routerLink]="['/course-detail', routerEventCurse(curse.title) ]">
                      <div class="row">
                        <div class="col-12 col-md-2 d-flex flex-wrap align-content-center align-content-center">
                          <img class="img-fluid img-certifiable"
                            src="{{(curse.fieldImagenPreviewCurso.url !== undefined) ? curse.fieldImagenPreviewCurso.url : ''}}"
                            alt="">
                        </div>
                        <div class="col-12 col-md-8">
                          <div class="sec-title d-flex justify-content-between">
                            <h2>{{curse.title}}</h2>
                            <p><span>{{'idioma' | translate}}:</span> {{getLangNameById(curse.langcode.language.id)}}
                            </p>
                          </div>
                          <p>{{curse.summary}}</p>
                          <p class="update"> <span>{{'actualizado' | translate}}:</span>
                            {{curse.entityChanged | date:'dd/MM/yyyy'}}
                          </p>
                          <p class="category"> <span>{{'categoria' | translate}}:</span>
                            {{curse.fieldTagsCategoriaName.split(', ')}}</p>
                        </div>
                        <div
                          class="col-12 col-md-2 sect-start d-flex flex-wrap align-content-center justify-content-center">
                          <h2 class="start">{{'comenzar' | translate}}</h2>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>

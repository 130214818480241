<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="ecosystemDataBanner['title']" [description]="ecosystemDataBanner['description']"
    [image]="ecosystemDataBanner['image']" [color]="ecosystemDataBanner['color']"
    [isButton]="ecosystemDataBanner['isButton']" [textButton]="ecosystemDataBanner['textButton']"
    [isScroll]="ecosystemDataBanner['isScroll']" [icon]="ecosystemDataBanner['icon']">
  </app-banner-sites>
  <!-- Section communities -->
  <div class="container-fluid section-gray" (keyup.enter)="searchCommunity()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'comunidades' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap padding-section-responsive">
        <div class="col-md-10 col-sm-12">
          <p class="paragraph-banners">{{'comunidadesdesc1' | translate}}</p>
          <p class="paragraph-banners">{{'comunidadesdesc2' | translate}}</p>
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchCommunityValue"
              placeholder="{{'buscarcomunidad' | translate}}">
            <button type="submit" class="searchButton" (click)="searchCommunity()">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 d-flex justify-content-lg-end flex-column mb-2">
          <a type="button" class="btn button-banner button-section m-0 text-uppercase"
            [routerLink]="['/contact']">{{'contactanos' | translate}}</a>
        </div>
        <div class="row container-sites">
          <h3 class=" pl-3 text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="content-dropdown dropdown-advanced-search d-flex align-items-center">
                <p class="text-search-advanced p-0 m-0 mr-3">{{'type' | translate}}:</p>
                <div *ngIf="listCommunityOpen">
                  <kendo-dropdownlist [data]="listTypeCommunities" [valueField]="'id'" [textField]="'value'"
                    (selectionChange)="selectionTypeCommunityChange($event)">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites">
      <pagination-controls id="communities" (pageChange)="getPageCommunities($event)" previousLabel="Anterior"
        nextLabel="Siguiente">
      </pagination-controls>
      <div *ngIf="inTransact.communities" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3" *ngIf="communitiesOpen">
        <div *ngFor="let item of communities | paginate: {
            id: 'communities', itemsPerPage: limit, currentPage: currentPageCommunities, totalItems: this.totalCommunities
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3  min-width-card-componentes">
          <a [routerLink]="['/communities', routerCommunities(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-communities [title]="item.title" [text]="item.body.summary" [type]="item.fieldTipoDeComunidad"
              [images]="item.fieldImagenPreview.url">
            </app-card-communities>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="communities.length === 0 && !inTransact.communities">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Section entities -->

  <div class="container-fluid pt-5 padding-pagination" (keyup.enter)="searchEntity()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'entidades' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap padding-section-responsive">
        <div class="col-md-10 col-sm-12">
          <p class="paragraph-banners">{{'entidadesdesc' | translate}}</p>
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchEntitieValue"
              placeholder="{{'buscarentidad' | translate}}">
            <button type="button" class="searchButton" (click)="searchEntity()">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 d-flex justify-content-lg-end">
          <button type="button" class="btn button-banner button-section m-0 text-uppercase"
            [routerLink]="['/contact']">{{'contactanos' | translate}}</button>
        </div>
        <div class="row container-sites">
        </div>
      </div>
    </div>
    <div class="row container-sites">
      <pagination-controls id="entities" (pageChange)="getPageEntities($event)" previousLabel="Anterior"
        nextLabel="Siguiente">
      </pagination-controls>
      <div *ngIf="inTransact.entities" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3" *ngIf="entitiesOpen">
        <div *ngFor="let item of entities | paginate: {
            id: 'entities', itemsPerPage: limitEntities, currentPage: currentPageEntities, totalItems: totalEntities
           }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/entities',routerCommunities(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-entities [title]="item.title" [text]="item.body.summary" [type]="item.type"
              [images]="item.fieldImagenPreview.url">
            </app-card-entities>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="entities.length === 0 && !inTransact.entities">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Section events -->
  <div class="container-fluid section-gray" (keyup.enter)="searchEvent()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'eventos' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap padding-section-responsive">
        <div class="col-md-10 col-sm-12">
          <p class="paragraph-banners">{{'eventosdesc' | translate}}</p>
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchEventValue"
              placeholder="{{'buscarevento' | translate}}">
            <button type="button" (click)="searchEvent()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
          <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
        </div>
        <div class="col-md-2 col-sm-12 d-flex justify-content-lg-end">
          <button type="button" class="btn button-banner button-section m-0 text-uppercase"
            [routerLink]="['/contact']">{{'contactanos' | translate}}</button>
        </div>
        <div class="row container-sites">
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="content-dropdown dropdown-advanced-search d-flex align-items-center">
                <p class="text-search-advanced p-0 m-0 mr-3">{{'type' | translate}}:</p>
                <div *ngIf="listTypeEventsOpen">
                  <kendo-dropdownlist [data]="listTypeEvents" (selectionChange)="selectionTypeEventChange($event)"
                    [valueField]="'id'" [textField]="'value'">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites">
      <pagination-controls id="events" (pageChange)="getPageEvents($event)" previousLabel="Anterior"
        nextLabel="Siguiente">
      </pagination-controls>
      <div *ngIf="inTransact.events" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3" *ngIf="eventsOpen">
        <div *ngFor="let item of events | paginate: {
              id: 'events', itemsPerPage: limitEvents, currentPage: currentPageEvents, totalItems: totalEvents
            }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/events',routerCommunities(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-event-communities [title]="item.title" [images]="item.fieldImagenPreview.url"
              [date]="item.fieldFechaInicioDeEvento.date">
            </app-card-event-communities>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="events.length === 0 && !inTransact.events">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Section Blueprint -->

  <div class="container-fluid py-5" *ngIf="bluePrintOpens">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections-gray mb-4 pl-4">{{'quiereslanzartucomunidad' | translate}}</h2>
      <p class="paragraph-banners pl-4 htmltext" innerHTML="{{htmlStr | translate }}">
      </p>
      <p class="paragraph-banners pl-4">{{'quiereslanzartucomudesc2' | translate}}</p>
      <p class="paragraph-banners pl-4">{{'recursosqueprop' | translate}}</p>
      <div class="row container-sites">
        <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
          <div *ngFor="let item of blueprints" class="col-md-3 col-sm-12 p-sm-3">
            <app-card-blueprint [title]='item.title' [description]='item.fieldDescripcionBlueprint'
              [url]="item.fieldEnlaceBlueprint.uri"></app-card-blueprint>
          </div>
        </div>
      </div>
      <div class="row container-sites">
        <p class="paragraph-banners pl-4">{{'recursosquepropdesc1' | translate}}</p>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>

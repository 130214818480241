import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class GenericCodeService {

  private videos: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getLang(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getLangQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.taxonomyTermQuery.entities.map((x) => {
              return {
                id: x.entityId,
                value: x.entityLabel,
                keyValue: x.fieldIdiomaKey
              };
            }),
            result.data.taxonomyTermQuery.count
          );
        },
        error => { console.log(error) });
  }

  getPerfil(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getPerfilQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.taxonomyTermQuery.entities.map((x) => {
              return {
                id: x.entityId,
                value: x.entityLabel,
                keyValue: x.fieldPerfilKey,
              };
            }),
            result.data.taxonomyTermQuery.count
          );
        },
        error => { console.log(error) });
  }

  getCategory(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getCategoryQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.taxonomyTermQuery.entities.map((x) => {
              return {
                id: x.entityId,
                value: x.entityLabel,
                keyValue: x.fieldCategoriaKey,
              };
            }),
            result.data.taxonomyTermQuery.count
          );
        },
        error => { console.log(error) });
  }

  getTopic(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getTopicQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
      .subscribe(
        result => {
          callback(
            result.data.taxonomyTermQuery.entities.map((x) => {
              return {
                id: x.entityId,
                value: x.entityLabel,
                keyValue: x.fieldTemaKey
              };
            }),
            result.data.taxonomyTermQuery.count
          );
        },
        error => { console.log(error) });
  }

  private getLangQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
      query idiomas{
        taxonomyTermQuery(
          limit: 10,
          offset: 0,
          filter: {
            conditions:
            [{operator: EQUAL,
              field: "vid",
              value: ["idiomas"]
              language: ${lang}
            }
            ${filter}
            ]}) {
          count
          entities {
            entityId
            entityLabel
            ... on TaxonomyTermIdiomas{
              fieldIdiomaKey
            }
          }
        }
      }
      `;
  }


  private getPerfilQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query tags_perfil{
          taxonomyTermQuery(
            limit: 10,
            offset: 0,
            filter: {
              conditions:
              [{operator: EQUAL,
                field: "vid",
                value: ["tags_perfil"]
                language: ${lang}
              }
              ]}) {
            count
            entities {
              entityLabel
              ... on TaxonomyTermTagsPerfil{
                fieldPerfilKey
              }
              entityId
              entityLabel
            }
          }
        }
      `;
  }

  private getCategoryQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query tags_categoria{
          taxonomyTermQuery(
            limit: 10,
            offset: 0,
            filter: {
              conditions:
              [{operator: EQUAL,
                field: "vid",
                value: ["tags_categoria"]
                language: ${lang}
              }
              ]}) {
            count
            entities {
              entityId
              entityLabel
              ... on TaxonomyTermTagsCategoria{
                fieldCategoriaKey
              }
            }
          }
        }
      `;
  }

  private getTopicQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query tags_tema{
          taxonomyTermQuery(
            limit: 10,
            offset: 0,
            filter: {
              conditions:
              [{operator: EQUAL,
                field: "vid",
                value: ["tags_tema"]
                language: ${lang}
              }
              ]}) {
            count
            entities {
              entityId
              entityLabel
              ... on TaxonomyTermTagsTema{
                fieldTemaKey
              }
            }
          }
        }
      `;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StoreServices } from 'src/app/core/services/store.services.service';

@Component({
  selector: 'app-services-all',
  templateUrl: './services-all.component.html',
  styleUrls: ['./services-all.component.scss']
})

export class ServicesAllComponent implements OnInit {

  servicesId: any = {};
  services: any[] = [];

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  servicesOpens = false;
  servicesIdsOpens = false;
  servicesOpensId = false;
  indrag = false;

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private storeServices: StoreServices,
    private router: Router) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
        if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
            this.getServicesId(true);
            this.getServices();
        }
    });
    }

  ngOnInit(): void {
    this.getServicesId(false);
    this.getServices();
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, (title) ]);
    }
  }

  getServicesId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const entitiesId = this.getEvenIdByName(arg.title);
      this.servicesOpensId = false;
      this.storeServices.getServices(`
        {
          operator: EQUAL
          field: "field_titulo_ingles"
          value: "${(!status) ? (entitiesId) : this.servicesId.fieldTituloIngles }"
        },
      `,
      1,
      0,
      localStorage.getItem('lang').toUpperCase(), (events, total) => {
        this.servicesId =  events[0];
        this.servicesOpensId = true;
      });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getServices() {
    this.storeServices.getServices('',
      100,
      0,
      localStorage.getItem('lang').toUpperCase(),(_alliances, total) => {
        this.services =  _alliances;
        this.servicesOpens = true;
    });
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
  routerService(title: string) {
    return title.replace(' ', '-');
  }

}

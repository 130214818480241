import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class BluePrintsService {

  constructor(private apollo: Apollo) { }

  getBluePrints(lang, callback) {
    this.apollo.query<any>({
      query: this.getBluePrintsQuery(lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getBluePrintsQuery(lang: string): DocumentNode {
    return gql`
        query blue_print {
          nodeQuery(filter: {conditions: [{operator: EQUAL, field: "status", value: "1" language: ${lang} }, {operator: EQUAL, field: "type", value: "Blueprint_para_montar_tu_comunid"}]}) {
            count
            entities {
              ... on NodeBlueprintParaMontarTuComunid {
                title
                fieldDescripcionBlueprint
                fieldEnlaceBlueprint {
                  uri
                }
              }
            }
          }
        }
      `;
  }
}

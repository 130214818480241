import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-banner-sites',
  templateUrl: './banner-sites.component.html',
  styleUrls: ['./banner-sites.component.scss']
})
export class BannerSitesComponent implements OnInit {
  @Input() image: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;
  @Input() description1: string;
  @Input() description2: string;
  @Input() description3: string;
  @Input() color: string;
  @Input() isButton: boolean;
  @Input() textButton: string;
  @Input() isScroll: boolean;
  @Input() longDescription: boolean;
  @Input() urlRedirect: string;


  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  redirect() {
    this.router.navigate([`/${this.urlRedirect}`]);

  }

}

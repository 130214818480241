import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class AppsDappsService {

  private appsDapps: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getAppsDapps(searchFilter: string, limit, offset, lang) {
    return this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    });
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query apps_dapps {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "title", direction: ASC },
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Apps_Dapps"}
              ${filter}]}
          ) {
            count
            entities {
              ... on NodeAppsDapps{
                title
                fieldTituloIngles
                uuid
                fieldCategoriaAppDapp
                body {
                  processed
                  summary
                }
                fieldEstadoApp
                fieldGithubApp
                fieldIdiomasDisponibles
                fieldImagenApp {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldPlataformaApp
                fieldPuntoContactoApp
                fieldSedeLegalApp
                fieldSitioDescargaApp {
                  uri
                }
                fieldSitioWebApp {
                  uri
                }
                fieldTipoSolucionAppsDapps
                fieldTitularidad
              }
            }
          }
        }
      `;
  }

  getAppsDappsId(id) {
    return this.appsDapps[id];
  }

}

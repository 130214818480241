import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-financing-opportunities',
  templateUrl: './card-financing-opportunities.component.html',
  styleUrls: ['./card-financing-opportunities.component.scss']
})
export class CardFinancingOpportunitiesComponent implements OnInit {

@Input() title: string;
@Input() text: string;
@Input() type: string;
@Input() images: string;
@Input() date: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}

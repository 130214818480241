<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid mt-5">
    <div class="row container-sites">
      <div class="col-12">
        <h2 class="title-banners color-academy text-center">{{videosId.title}}</h2>

        <div class="sect-video">
          <iframe [src]="videosId.fieldLinkVideoEducativo.uri | domSafe" width="100%" title="Video" height="360" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style=" border-radius: 10px;"></iframe>
        </div>

        <div class="col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-md-5 col-12 card-items-internal m-1">
              <span class="d-block color-academy">
                {{'idioma' | translate}}:
                <p class="p-nomaice d-inline-block m-0"> {{videosId.fieldIdmVideoEducativo.entity.name}}</p>
              </span>
            </div>
            <div class="col-md-6 col-12 card-items-internal m-1">
              <span class="d-block color-academy">
                {{'fechadecreacion' | translate}}:
                <p class="p-nomaice d-inline-block m-0">
                  {{videosId.fieldTemporalVideoEducativo.date | date:'dd MMM yyyy'}} </p>
              </span>
            </div>
            <div class="col-md-5 col-12 card-items-internal m-1">
              <span class="d-block color-academy">
                {{'audiencia' | translate}}:
                <div class=" d-inline-block m-0" *ngFor="let item of videosId.fieldTagsPerVideoEducativo">
                  <p *ngIf="item.entity.name" class="p-nomaice d-inline-block m-0">{{item.entity.name}},&nbsp;</p>
                  <p *ngIf="!item.entity.name" class="p-nomaice d-inline-block m-0">,&nbsp;</p>
                </div>
              </span>
            </div>
            <div class="col-md-6 col-12 card-items-internal m-1">
              <span class="d-block color-academy">
                {{'categoria' | translate}}:
                <div class=" d-inline-block m-0" *ngFor="let item of videosId.fieldTagsCatVideoEducativo">
                  <p *ngIf="item.entity.name" class="p-nomaice d-inline-block m-0">{{item.entity.name}},&nbsp;</p>
                  <p *ngIf="!item.entity.name" class="p-nomaice d-inline-block m-0">,&nbsp;</p>
                </div>
              </span>
            </div>
            <div class="col-md-6 col-12 card-items-internal m-1">
              <span class="d-block color-academy">
                {{'duracion' | translate}}:
                <div class=" d-inline-block m-0" >
                  <p class="p-nomaice d-inline-block m-0">{{videosId.fieldDuracionVideo}}&nbsp;</p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="sect-text-info mt-5 mb-5">
          <p class="color-text-academy" [innerHTML]="videosId.fieldDesVideoEducativo.processed"></p>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid pt-5 pb-5 recent-content-video section-gray">
  <div class="row container-sites padding-section-responsive">
    <h2 class="title-sections color-academy mb-4 pl-4 text-center">
      {{'informacionrelacionada' | translate}}
    </h2>
  </div>
  <div class="row container-sites">
    <owl-carousel-o [options]="customOptionTwoAcademy" (dragging)="dragging($event)">
      <ng-template carouselSlide *ngFor="let item of eventsSlider; let i = index">
        <div class="row">
          <div class="col-12 col-sm-11 px-md-3 p-sm-3">

            <a (click)="goLinkNoDragging('/'+getUrlTypeContent(item.type.targetId),getEvenIdByName(item.title))"
              class="btn  btn-block mt-2" role="button">
              <app-card-slider-recent [year]="item.fieldTemporalVideoEducativo.date" [title]="item.title"
                [text]="item.fieldDesVideoEducativo.summary" [images]="getImageTypeContent(item)"
                [isVideoCurse]="item.type.targetId"></app-card-slider-recent>
            </a>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>


<app-footer></app-footer>

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="banner-back">
    <app-banner-sites [title]="academyDataBanner['title']" [description]="academyDataBanner['description']"
      [image]="academyDataBanner['image']" [color]="academyDataBanner['color']"
      [isButton]="academyDataBanner['isButton']" [textButton]="academyDataBanner['textButton']"
      [isScroll]="academyDataBanner['isScroll']" [icon]="academyDataBanner['icon']" [urlRedirect]="urlRedirect">
    </app-banner-sites>
  </div>

  <section class="certifiable-courses-content">
    <div class="container-fluid container-sites mx-w100">
      <div class="row">

        <div class="section-gray col-12 col-lg-4 col-xl-3">
          <div class="container-fluid  pt-5 content-one-certifiable-courses">
            <div class="container-sites">
              <div class="col-12">
                <p class="paragraph-banners">
                  {{'laccacademiatextdesarrolloredes' | translate}}
                </p>
                <h2 class="mt-4">
                  {{'seleccionetematicasinteres' | translate}}:
                </h2>
              </div>
              <button class="btn btn-retourns" [routerLink]="['/academy']">
                <em class="fas fa-chevron-left"></em>
                {{'regresar' | translate}}
              </button>
              <div class="row mx-1175px mb-4">
                <div class="col-12 col-md-12 mb-4">
                  <div class="example-wrapper mb-4 mt-3" *ngIf="languageItems.open">
                    <span class="label-dropdownlist mb-2">{{'idioma' | translate}}:</span>
                    <kendo-dropdownlist [data]="languageItems.data" [(ngModel)]="selectedVLang"
                      [valueField]="'keyValue'" [textField]="'value'" [valuePrimitive]="true"
                      (valueChange)="onChangeLanguage($event)">
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-1">
                    <span class="label-dropdownlist mb-2">{{'fecha' | translate}}:</span>
                    <label class="w5105 mb-4">
                      <kendo-numerictextbox [(value)]="selectedVDate.start" [min]="1900" [max]="3000"
                        (valueChange)="onChangeDate($event);" [format]="'#'" [step]=1>
                      </kendo-numerictextbox>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mx-1175px">
                <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-5" *ngIf="perfil.open">
                    <span class="label-dropdownlist mb-2">{{'audiencia' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchVideosFilter(null)" [data]="perfil.data"
                      [(ngModel)]="selectPerfilValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'">
                    </kendo-multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-4">
                    <span class="label-dropdownlist mb-2">{{'categoria' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchVideosFilter(null)" [data]="category.data"
                      [(ngModel)]="selectCategoryValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'">
                    </kendo-multiselect>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-12">
                  <div class="example-wrapper mb-3">
                    <span class="label-dropdownlist">{{'tema' | translate}}:</span>
                    <kendo-multiselect (valueChange)="searchVideosFilter()" [data]="topic.data"
                      [(ngModel)]="selectedTopicValue" [valuePrimitive]="true" [valueField]="'keyValue'"
                      [textField]="'value'"></kendo-multiselect>
                  </div>
                </div> -->
              </div>
              <div class="center">
                <button type="button" name="" id="" (click)="searchVideosFilter(gotov)"
                  class="btn btn-searchButton mt-4 mb-2 mr-4">{{'buscar' | translate}}
                </button>
                <button type="button" name="" id="" (click)="searchVideosFilterReset()"
                  class="btn btn-searchButton mt-4 mb-5">
                  {{'borrar' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8 col-xl-9 d-flex flex-wrap align-content-center padding-section-responsive">
          <div class="content-new w-100">
            <div class="sec-title">
              <h2 class="title-sections color-academy mb-4 mt-5">
                {{'videoacademia' | translate}}
              </h2>
            </div>
            <p>
              {{'descubreproximoseventos' | translate}}
            </p>
            <div class="col-12">
              <div class="search my-4 video-search autocomplete-to-multiselect">
                <kendo-multiselect [data]="searchItemsVideo" [valueField]="'name'" [filterable]="true"
                  (filterChange)="handleFilterVideo($event)" [textField]="'name'" (valueChange)="valueChangeVideo($event)"
                  [(ngModel)]="searcherMultiple"
                  placeholder="{{'buscarportitulo' | translate}}">
                </kendo-multiselect>
                <button type="submit" class="searchButton" (click)="searchVideos()">
                  {{'buscar' | translate}} <em class="fa fa-search"></em>
                </button>
              </div>

              <div class="container-sites" id="gotoVideos" class="sec-title" #gotov>
                <pagination-controls id="videosTwo" (pageChange)="pageChangedVideo($event)" responsive="true"
                  previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
                </pagination-controls>

                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-4" *ngFor="let item of videos | paginate: {
                    id: 'videosTwo', itemsPerPage: limitPublications, currentPage: currentPagePublications, totalItems: totalPublications
                  }; let i = index">
                    <div class="card-certifiable-courses">
                      <a [routerLink]="['/video-related-information', routerEventCurse(item.title)]">
                        <div
                          class="col-12 col-md-12 pt-2 pb-2 d-flex flex-wrap align-content-center align-content-center position-relative">
                          <img class="img-fluid img-certifiable" src="{{item.fieldImagenVideoEducativo.url}}" alt="">
                          <em class="fa fa-play-circle" aria-hidden="true"></em>
                        </div>
                        <div class="col-12 col-md-12">
                          <div class="sec-title">
                            <h2>
                              {{item.title}}
                            </h2>
                            <p class="update"> {{item.fieldTemporalVideoEducativo.date | date:'dd MMM yyyy'}}
                              <em class="fab fa-youtube"></em>
                            </p>
                          </div>
                          <p style="width: 100%; height: 100px;" ellipsis>
                            {{item.fieldDesVideoEducativo.summary}}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


</div>
<app-footer></app-footer>

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { homeOcdeGov, homeLacchain } from '../../core/mocks/data-banners';
import { StadisticsHomeService } from 'src/app/core/services/stadistics.home.service';
import { NewContentLacchainService } from 'src/app/core/services/new.content.lacchain.service';
import { Router } from '@angular/router';
import { ModalHomeComponent } from 'src/app/shared/components/modal-home/modal-home.component';
import { MatDialog } from '@angular/material/dialog';
import { SeoService } from '../../core/services/seo.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { HomeService } from 'src/app/core/services/home.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public homeOcdeLocal: any[] = [];
  public homeLacchainLocal: any[] = [];
  @ViewChild('home', { static: true }) homeModal: ElementRef;
  titleURL: string;
  events;
  activeCitiesCard: any;
  dialogRef: any;
  videoUrl: string =
    'https://player.vimeo.com/video/593066045?title=0&byline=0&portrait=0';
  videoUrlEn: string =
    'https://player.vimeo.com/video/593065761?title=0&byline=0&portrait=0';
  hiddenShowButton = false;
  customOptionOne: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
  };

  homeStadistics = {
    open: false,
    data: null,
  };

  eventsOpens = false;

  lang = 'en';
  indrag = false;

  constructor(
    private stadisticsService: StadisticsHomeService,
    private newContentService: NewContentLacchainService,
    private router: Router,
    public dialog: MatDialog,
    private seoService: SeoService,
    private title: Title,
    public location: Location,
    private renderer: Renderer2,
    private homeService: HomeService
  ) {
    this.homeOcdeLocal = homeOcdeGov;
    this.homeLacchainLocal = homeLacchain;

    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        const path = location.path();
        if (path === '/home' && this.homeModal !== undefined) {
          this.renderer.removeClass(this.homeModal.nativeElement, 'hide-class');
        } else {
          if (this.homeModal !== undefined) {
            this.renderer.addClass(this.homeModal.nativeElement, 'hide-class');
          }
        }
        if (event.url.includes('lang')) {
          this.ngOnInit();
        }
      }
    });
    this.modalView();
  }

  dragging(event): void {
    this.indrag = event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.router.navigate([link, title]);
    }
  }

  getImageTypeContent(target) {
    let image = 'fieldImagenPreview';
    let result = '';
    if (image !== '') {
      const newData = target[image];
      result = typeof newData.url !== 'undefined' ? newData.url : '';
    }
    return result;
  }

  getUrlTypeContent(target) {
    let url = '';
    switch (target) {
      case 'eventos_lacchain':
        url = 'events-participacion';
        break;
      case 'eventos_ecosistema':
        url = 'events';
        break;
      case 'comunidades':
        url = 'communities';
        break;
      case 'entidades':
        url = 'entities';
        break;
      case 'proyectos':
        url = 'projects';
        break;
      case 'noticias':
        url = 'our-news';
        break;
    }

    return url;
  }

  ngOnInit(): void {
    this.getStadisticsHome();
    this.getContents();
    this.lang = localStorage.getItem('lang');
    this.flags();
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth',
    });
  }

  modalView() {
    this.homeService.getInformation().then((item: any) => {
      // if (item !== undefined && item.data !== undefined) {
      item = item.data;
      // if (item.length > 0 ) {
      item = item[0];
      // if (active) {
      if (item !== undefined) {
        this.dialogRef = this.dialog.open(ModalHomeComponent, {
          data: {
            image:
              typeof item.fieldImagenModalHome.url !== undefined
                ? item.fieldImagenModalHome.url
                : null,
            link: item.fieldEnlaceModalHome.uri,
          },
        });
      }
      // }
      // }
      // }
    });
  }

  flags() {
    this.seoService.generateFlags({
      title: 'LACChain',
      site_name: 'LCChain',
      description:
        'LACChain es una alianza global integrada por diferentes actores del entorno blockchain, liderada por el Laboratorio de Innovación del Grupo BID (BID Lab)...',
      slug: 'home',
      image: 'https://www.lacchain.net/assets/images/ciudad-espa%C3%B1ol.png',
    });
    this.title.setTitle('LACChain');
  }

  private getStadisticsHome(): void {
    this.stadisticsService.getStadistics((data, total) => {
      if (total > 0) {
        this.homeStadistics.data = data[0];
        this.homeStadistics.open = true;
      }
    });
  }

  getContents() {
    this.newContentService.getNewContent(
      '',
      9,
      0,
      localStorage.getItem('lang').toUpperCase(),
      (data, total) => {
        this.events = data;
        this.eventsOpens = true;
      }
    );
  }

  hiddenShoBtn() {
    this.hiddenShowButton = !this.hiddenShowButton;
  }

  getDateEvent(target) {
    let result = '';
    let fieldDate = '';
    if (target.type.targetId === 'eventos_lacchain') {
      fieldDate = 'fieldFechaInicioDeEvento';
      if (fieldDate !== '') {
        const newData = target[fieldDate];
        result =
          typeof newData !== undefined &&
          newData !== null &&
          typeof newData.date !== undefined
            ? newData.date
            : '';
      }
    } else if (target.type.targetId === 'eventos_ecosistema') {
      fieldDate = 'fieldFechaInicioDeEvento';
      if (fieldDate !== '') {
        const newData = target[fieldDate];
        result =
          typeof newData !== undefined &&
          newData !== null &&
          typeof newData.date !== undefined
            ? newData.date
            : '';
      }
    } else if (target.type.targetId === 'entidades') {
      fieldDate = 'fieldFechaConstitucion';
      if (fieldDate !== '') {
        const newData = target[fieldDate];
        result =
          typeof newData !== undefined &&
          newData !== null &&
          typeof newData.date !== undefined
            ? newData.date
            : '';
      }
    }
    return result;
  }

  // openModal() {
  //   this.dialogRef = this.dialog.open(ModalHomeComponent, {
  //     // disableClose: true
  //   });
  //   this.dialogRef.afterClosed().subscribe(_ => {

  //   });
  //   window.scrollTo({
  //     top: 1,
  //     left: 1,
  //     behavior: 'smooth'
  //   });
  // }

  routerEntities(title: string) {
    return title.replace(' ', '-');
  }
}

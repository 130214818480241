import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { academyItemsData } from 'src/app/core/mocks/data-banners';
import { EventsService } from 'src/app/core/services/events.service';
import { NewContentLacchainService } from 'src/app/core/services/new.content.lacchain.service';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.scss']
})
export class AcademyComponent implements OnInit {
  public academyDataBanner: any[] = [];
  minHeightHv = '';
  isMobile: boolean;
  isTablet: boolean;
  statusDots: boolean;
  statusNav: boolean;
  customOptionOne: OwlOptions = {};
  customOptionTwo: OwlOptions = {};

  eventsOpensSlider = false;
  eventsSlider;
  recentContent: any;
  recentContentOpens = false;
  searchCommunityValue = '';
  showAdvancedSearchCommunitiesClic = false;
  listCommunityOpen = false;
  public listTypeCommunities: Array<any> = [
    { id: 'Seleccionar', es: 'Todos', en: 'All', value: null },
    { id: 'Regional', es: 'Regional', en: 'Regional', value: null },
    { id: 'Nacional', es: 'Nacional', en: 'National', value: null },
    { id: 'Local', es: 'Local', en: 'Local', value: null },
    { id: 'Internacional', es: 'Internacional', en: 'International', value: null },
  ];
  urlRedirect = 'subscribe';

  currentPagePublications = 0;
  publicationsOpens = false;
  totalPublications = 0;
  limitPublications = 6;
  publications: any;
  indrag = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private newContentService: NewContentLacchainService,
    private eventsService: EventsService,
    private router: Router,
    private seoService: SeoService,
    private title: Title
    ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.academyDataBanner = academyItemsData[0];
    this.detectMobilCarrusel();
  }

  ngOnInit(): void {
    this.getContents();
    this.getEventsAcademy();
    this.getPublicationAcademy('');
    this.flags();
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.router.navigate([link, this.routerEntities(title) ]);
    }
  }

  flags() {
    this.seoService.generateFlags({
      title: 'Academy | LACChain Academy es el programa de formación académica de LACChain que busca fortalecer capacidades y brindar conocimiento en tecnología blockchain',
      site_name: 'LCChain',
      description: 'LACChain Academy es el programa de formación académica de LACChain que busca fortalecer capacidades y brindar conocimiento en tecnología blockchain sobre las redes LACChain.',
      slug: 'academy',
      image: 'https://www.lacchain.net/assets/images/cities/Academy.png'
    });
    this.title.setTitle('Academy | LACChain Academy es el programa de formación académica de LACChain que busca fortalecer capacidades y brindar conocimiento en tecnología blockchain');
  }


  dragging(event): void {
    this.indrag =  event.dragging;
  }

  detectMobilCarrusel() {
    if (this.isMobile === true || this.isTablet === true) {
      this.statusDots = false;
      this.statusNav = true;
      this.customOptionOne = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };
      this.customOptionTwo = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: this.statusDots,
        nav: this.statusNav,
        navSpeed: 700,
        dotsSpeed: 700,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1200: {
            items: 4,
          },
        },
      };

    } else {
      this.statusDots = true;
      this.statusNav = false;
      this.customOptionOne = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: true,
        nav: false,
        navSpeed: 700,
        dotsSpeed: 500,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          740: {
            items: 2,
          },
          940: {
            items: 3,
          },
        },
    };
      this.customOptionTwo = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      nav: true,
      margin: 15,
      navSpeed: 700,
      dotsSpeed: 500,
      navText: ['<', '>'],
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        740: {
          items: 2,
          nav: false,
        },
        940: {
          items: 3,
        },
      },
    };
  }
}


  getEventsAcademy() {
    this.eventsService.getEventsAcademy('', 100, 0,
      localStorage.getItem('lang').toUpperCase(), (academy, total) => {
        this.eventsSlider = academy;
        this.eventsOpensSlider = true;
      });
  }


  pageChanged(page: number) {
    this.currentPagePublications = page;
    this.getPublicationAcademy('');
  }

  getPublicationAcademy(filter: string) {
    this.eventsService.getPublicationAcademy(filter, this.limitPublications,
      this.getOfsset(this.currentPagePublications, this.limitPublications),
      localStorage.getItem('lang').toUpperCase(), (publication, total) => {
        this.publications = publication;
        this.totalPublications = total;
        this.publicationsOpens = true;

      });
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }


  getContents() {
    this.newContentService.getRecentContent('', 9, 0, localStorage.getItem('lang').toUpperCase(), (data, total) => {
      this.recentContent = data;
      this.recentContentOpens = true;
    });
  }


  getImageTypeContent(target) {
    // tslint:disable-next-line:prefer-const
    let image = 'fieldImagenPreviewAcademy';
    let result = '';
    if (image !== '') {
      const newData = target[image];
      result = (typeof newData.url !== 'undefined') ? newData.url : '';
    }
    return result;
  }

  getImageTypeContentRecent(target) {
    let result = '';
    if (target.type.targetId === 'curso') {
      let image = 'fieldImagenPreviewCurso';
      if (image !== '') {
        const newData = target[image];
        result = (typeof newData.url !== 'undefined') ? newData.url : '';
      }
    } else if (target.type.targetId === 'videos_educativos') {
      let imageTwo = 'fieldImagenVideoEducativo';
      if (imageTwo !== '') {
        const newData = target[imageTwo];
        result = (typeof newData.url !== 'undefined') ? newData.url : '';
      }
    }
    return result;
  }

  getTextTypeContentRecent(target) {
    let result = '';
    if (target.type.targetId === 'curso') {
      let text = 'fieldDescripcionCurso';
      if (text !== '') {
        const newData = target[text];
        result = (typeof newData.summary !== 'undefined') ? newData.summary : '';
      }
    } else if (target.type.targetId === 'videos_educativos') {
      let textTwo = 'fieldDesVideoEducativo';
      if (textTwo !== '') {
        const newData = target[textTwo];
        result = (typeof newData.summary !== 'undefined') ? newData.summary : '';
      }
    }
    return result;
  }

  getUrlTypeContent(target) {
    let url = '';
    switch (target) {
      case 'eventos_lacchain':
        url = 'recent-content';
        break;
      case 'eventos_academia':
        url = 'recent-content';
        break;
      case 'curso':
        url = 'course-detail';
        break;
      case 'videos_educativos':
        url = 'video-related-information';
        break;
    }

    return url;
  }

  encodeURIComponent(str) {
    return encodeURI(str);
  }

  routerEntities(title: string) {
    return title.replace(' ', '-');
  }

  selectionTypeCommunityChange(event) {

  }

  searchEntity(): void {}

  searchCommunity(){}
  showAdvancedSearch(){}
  }



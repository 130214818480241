<div class="container-fluid pt-5 pb-5 onBottom">
  <div class="row container-sites padding-section-responsive">
    <h1 class="title-sections mt-5 mb-0 pl-4 color-academy">{{'publicaciones' | translate}}</h1>
  </div>
  <div class="row container-sites padding-section-responsive" *ngIf="publicationsOpens">
    <pagination-controls id="publicationsTwo" (pageChange)="pageChanged($event)" responsive="true"
      previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
    </pagination-controls>

    <div class="w-100 d-flex align-itmes-center flex-wrap mt-0">
      <div *ngFor="let item of publications | paginate: {
            id: 'publicationsTwo', itemsPerPage: limitPublications, currentPage: currentPagePublications, totalItems: totalPublications
          }; let i = index" class="col-md-4 col-sm-12 px-md-0 p-sm-3">
        <a target="_blank" href="{{item.fieldLinkPublicacionAcademy.uri}}" class="btn btn-block mt-2" role="button">
          <div class="card-publications">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-5">
                <img class="img-fluid" [src]="item.fieldImgPublicacionAcademy.url" alt="">
              </div>
              <div class=" col-12 col-sm-12 col-md-7">
                <div class="sec-title">
                  <h3>{{item.title}}</h3>
                </div>
                <div class="sec-text" multilineDotdotdot>
                  <p> {{item.fieldDesPublicacionAcademy}}</p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<app-footer class="footer"></app-footer>

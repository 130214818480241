<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid mt-4 the-blockchain-iframe">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 center col-responsive-pad">
          <div class="container-iframe p-0 m-0">
            <div class="color-blue-tag" *ngFor="let item of data; let i = index">
              <mat-tab-group>
                <mat-tab label="TESTNET NODES" class="p-0 m-0">
                  <div class="color-blue pt-2">
                    <a href="{{ item.fieldLinkDashboard.uri }}" target="_blank" rel="noopener noreferrer"><img class="image-iframe" src="{{ item.fieldImagenDashboard.url }}" alt=""> </a>
                  </div>
                  <div class="end-section">
                    <div class="text-vis">
                      <p [innerHTML]="item.fieldTextoDashboard.processed"></p>
                    </div>
                    <div class="button-sec">
                      <a href="{{ item.fieldLinkBtnDashboard.uri }}" target="_blank" rel="noopener noreferrer"
                        class="btn btn-dash">{{'irpanelenvivo' | translate}}</a>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="TESTNET TRANSACTIONS" class="p-0 m-0">
                  <div class="color-blue pt-2">
                    <a href="{{ item.fieldLinkExplorer.uri }}" target="_blank" rel="noopener noreferrer"><img class="image-iframe" src="{{ item.fieldImagenExplorer.url }}" alt=""></a>
                  </div>
                  <div class="end-section">
                    <div class="text-vis">
                      <p [innerHTML]="item.fieldTextoExplorer.processed"></p>
                    </div>
                    <div class="button-sec">
                      <a href="{{ item.fieldLinkBtnExplorer.uri }}" target="_blank" rel="noopener noreferrer"
                        class="btn btn-dash">{{'irpanelenvivo' | translate}}</a>
                    </div>
                  </div>
                </mat-tab>
                <ng-container *ngIf="item.fieldImagenMainnetNodes">
                  <mat-tab label="MAINNET NODES" class="p-0 m-0">
                    <div class="color-blue pt-2">
                      <a href="{{ item.fieldLinkMainnetNodes.uri }}" target="_blank" rel="noopener noreferrer"><img class="image-iframe" src="{{ item.fieldImagenMainnetNodes.url }}" alt=""></a>
                    </div>
                    <div class="end-section">
                      <div class="text-vis" *ngIf="item.fieldTextoMainnetNodes">
                        <p [innerHTML]="item.fieldTextoMainnetNodes.processed"></p>
                      </div>
                      <div class="button-sec" *ngIf="item.fieldLinkBtnMainNodes">
                        <a href="{{ item.fieldLinkBtnMainNodes.uri }}" target="_blank" rel="noopener noreferrer"
                          class="btn btn-dash">{{'irpanelenvivo' | translate}}</a>
                      </div>
                    </div>
                  </mat-tab>
                </ng-container>
                <ng-container *ngIf="item.fieldImagenMainnetTransaction">
                  <mat-tab label="MAINNET TRANSACTIONS" class="p-0 m-0">
                    <div class="color-blue pt-2">
                      <a href="{{ item.fieldLinkMainnetTransactions.uri }}" target="_blank" rel="noopener noreferrer"><img class="image-iframe" src="{{ item.fieldImagenMainnetTransaction.url }}" alt=""></a>
                    </div>
                    <div class="end-section">
                      <div class="text-vis" *ngIf="item.fieldTextomainnetTransactions">
                        <p [innerHTML]="item.fieldTextomainnetTransactions.processed"></p>
                      </div>
                      <div class="button-sec" *ngIf="item.fieldLinkBtnMainnetTransact">
                        <a href="{{ item.fieldLinkBtnMainnetTransact.uri }}" target="_blank" rel="noopener noreferrer"
                          class="btn btn-dash">{{'irpanelenvivo' | translate}}</a>
                      </div>
                    </div>
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

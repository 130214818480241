<footer>
  <div class="section-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="cont-img-footer d-flex flex-wrap align-content-center h-100 center-mobil">
            <img class="img-fluid" src="../../../../assets/images/logo-lacchain-footer.png" alt="">
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="col-12 d-flex flex-wrap align-content-center justify-content-end center-mobil">
            <button class="btn btn-link btn-footer">
              <a href="https://lacchain.medium.com/" rel="noopener noreferrer" target="_blank"><em
                  class="fab fa-medium"></em></a>
            </button>
            <button class="btn btn-link btn-footer">
              <a href="https://www.linkedin.com/company/lacchain-ecosystem " rel="noopener noreferrer" target="_blank"><em
                  class="fab fa-linkedin"></em></a>
            </button>
            <button class="btn btn-link btn-footer">
              <a href="https://github.com/lacchain" rel="noopener noreferrer" target="_blank"><em
                  class="fab fa-github btn-footer mg-top--5x "></em></a>
            </button>
            <button class="btn btn-link btn-footer">
              <a href="https://twitter.com/LACChain" rel="noopener noreferrer" target="_blank"><em
                  class="fab fa-twitter"></em></a>
            </button>
          </div>
          <div class="col-12 d-flex flex-wrap align-content-center justify-content-end center-mobil"
            id="launchcommunity">
            <a class="hvr-shrink" href="{{'politicaprivalink' | translate}}" rel="noreferrer"
              target="_blank">{{'politicasdeprivacidad' | translate}}</a>
            <span class="lin-sep">|</span>
            <a class="hvr-shrink" href="{{'terminoscondilink' | translate}}" rel="noreferrer"
              target="_blank">{{'terminosycondiciones' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

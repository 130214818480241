<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid" *ngIf="eventDetailOpen">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-6 m-auto pt-5">
          <div class="card-event-container p-0 center">
            <img class="image-event img-org" [src]="eventsId['fieldEventoLacchain']['url']" alt="">
          </div>

        </div>
        <div class="col-12 col-md-6 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-alliance ">
            {{eventsId['title']}}
          </h2>
          <hr class="line-title-banner color-alliance">
          <p class="p-nomaice">{{eventsId['text']}}</p>
          <div class="info-all mt-4">
            <span class="d-block color-alliance">
              <p class="p-nomaice d-inline-block" [innerHTML]="eventsId['body']['processed']"></p>
            </span>
            <span class="d-block color-alliance">
              {{'organizador' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldOrganizadorEvento']}} </p>
            </span>
            <span class="d-block color-alliance">
              {{'type' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldTipoEvento']}} </p>
            </span>
            <span class="d-block color-alliance">
              {{'lugar' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldLugarEvento']}} </p>
            </span>
            <span class="d-block color-alliance">
              {{'fecha' | translate}}:
              <p class="p-nomaice d-inline-block"> {{date | date:'dd/MM/yyyy'}}
              </p>
            </span>
            <span class="d-block color-alliance">
              {{'hora' | translate}}:
              <p class="p-nomaice d-inline-block"> {{date | date:'h:mm a'}} </p>
            </span>
            <span class="d-block color-alliance">
              {{'puntoContacto' | translate}}:
              <p class="p-nomaice d-inline-block"> {{eventsId['fieldPuntoDeContactoEvento']}} </p>
            </span>
            <span class="d-block color-alliance">
              {{'sitioweb' | translate}}:
              <p *ngIf="eventsId['fieldSitioWeb'] !== null" class="p-nomaice d-inline-block">
                {{eventsId['fieldSitioWeb']['uri']}} </p>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 pt-5 padding-section-responsive">
          <span class="d-block color-alliance pb-2" *ngIf="eventsId['fieldAgendaEvento'] !== null">
            {{'agenda' | translate}}:
          </span>
          <span class="d-block color-alliance" *ngIf="eventsId['fieldAgendaEvento'] !== null">
            <p class="p-nomaice d-inline-block" [innerHTML]="eventsId['fieldAgendaEvento']['processed']"></p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies" *ngIf="eventsRouletteOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of events; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/events-participacion',  routerEventParticipation(item.fieldTituloIngles))"
                class="btn  btn-block mt-2" role="button">
                <app-card-event-alliance [title]="item.title" [text]="item.body.summary"
                  [images]="item.fieldImagenPreview.url" [date]="item.fieldFechaInicioDeEvento.date">
                </app-card-event-alliance>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500" class="mt-1 mobile-float-left">
  <button class="btn btn-card" *ngFor="let item of informationCommunities; let i= index"
    [class.card-blue]="selectedIndex === i && !participatesShow" (click)="activeCard(i)">
    <div class="instruction" *ngIf="(selectedIndexMore === i && participatesShow) || initalLoad">
      <div class="card-instruction">
        <h4><em class="fas fa-long-arrow-alt-right"></em> {{item.title_lang | translate}}</h4>
        <ul>
          <li *ngFor="let option of item.options">
            <p><a [routerLink]="['/' + option.value]">{{option.name | translate}}.</a></p>
          </li>
        </ul>
        <div class="instruction-description" *ngIf="selectedIndexMore === i && participatesShow">
          <p>
          </p>
          <div class="d-inline-block pr-1" innerHTML="{{item.description | translate }}">
          </div>
          <p class="mb-0 d-inline-block">
            <a [routerLink]='"/ecosystem"' fragment="launchcommunity">{{item.descriptionII | translate }}</a>
          </p>
          <div innerHTML="{{item.descriptionIII | translate }}">
          </div>
        </div>
        <button *ngIf="!participatesShow" class="btn btn-link btn-more" [class.more]="selectedIndexMore === i"
          (click)="shoMore(i)">{{'vermas' | translate}}...</button>
        <button *ngIf="selectedIndexMore === i  && participatesShow" class="btn btn-link btn-more"
          (click)="backMe()">{{'back' | translate}}...</button>
      </div>
    </div>
  </button>
</div>

import { Component, OnInit, Input,   } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-cities-map',
  templateUrl: './cities-map.component.html',
  styleUrls: ['./cities-map.component.scss']
})
export class CitiesMapComponent implements OnInit {

  numerCities: number;
  @Input()
  activeCitiesInput: string;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    navSpeed: 900,
    dotsSpeed: 900,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  public innerWidth: any;
  isMobile = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  activeCities(num){
    this.numerCities = num;

  }

}

import { Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class CursesService {

  private videos: any[] = [];

  constructor(private apollo: Apollo) { }

  getCurses(searchFilter: string, limit, offset, lang) {
    return new Promise((resolve: any, reject) => {
      this.apollo.query<any>({
        query: this.getVideosCursesQuery(searchFilter, limit, offset, lang),
        fetchPolicy: 'no-cache',
      })
        .subscribe(
          result => {
            resolve(
              result.data.nodeQuery.entities.map((j) => {
                j.fieldTagsCategoria.map((x) => {
                  j.fieldTagsCategoriaName = x.entity.name;
                  return x;
                });
                return j;
              }),
              result.data.nodeQuery.count
            );
          },
          error => { console.log(error); resolve([null, 0]); });
      });
  }

  getCursesById(searchFilter: string, limit, offset, lang) {
    return new Promise((resolve: any, reject) => {
      this.apollo.query<any>({
        query: this.getCursesQuery(searchFilter, limit, offset, lang),
        fetchPolicy: 'no-cache',
      })
        .subscribe(
          result => {
            resolve(
              result.data.nodeQuery.entities,
              result.data.nodeQuery.count
            );
          },
          error => { console.log(error); resolve([null, 0]); });
      });
  }

  getModulesByCurseId(searchFilter: string, limit, offset, lang) {
    return new Promise((resolve: any, reject) => {
      this.apollo.query<any>({
        query: this.getModulesByCurseQuery(searchFilter, limit, offset, lang),
        fetchPolicy: 'no-cache',
      })
        .subscribe(
          result => {
            resolve(
              result.data.nodeQuery.entities,
              result.data.nodeQuery.count
            );
          },
          error => { console.log(error); resolve([null, 0]); });
      });
  }

  getClasesByCurseId(searchFilter: string, limit, offset, lang) {
    return new Promise((resolve: any, reject) => {
      this.apollo.query<any>({
        query: this.getClasesByCurseQuery(searchFilter, limit, offset, lang),
        fetchPolicy: 'no-cache',
      })
        .subscribe(
          result => {
            resolve(
              result.data.nodeQuery.entities,
              result.data.nodeQuery.count
            );
          },
          error => { console.log(error); resolve([null, 0]); });
      });
  }


  private getVideosCursesQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
         query ListaCursos {
          nodeQuery(
            limit: 4,
            offset: 0,
            sort: {field: "created", direction: DESC},
            filter: {
              conditions:
              [{
            operator: EQUAL,
            field: "status",
            value: "1",
            language: ES},
            {operator: EQUAL,
              field: "type",
              value: "Curso"},
                ${filter}]}
            ) {
              count
              entities {
                ... on NodeCurso {
                  title
                  uuid
                   type {
                    targetId
                  }
                    langcode{
                    language {
                      id
                    }
                  }
                  fieldDescripcionCurso {
                    summary
                  }
                  entityCreated
                  entityChanged
                  fieldTagsCategoria{
                    entity {
                      name
                    }
                  }
                  fieldTemporalCurso {
                    value
                    date
                  }
                  fieldImagenPreviewCurso{
                    url
                  }
                }
              }
            }
          }
      `;
  }

  private getCursesQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
         query cursos($limit: Int= ${limit}, $offset: Int = ${offset}) {
          nodeQuery(
            limit:$limit,
            offset:$offset,
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
                language: ES
              },
                {
                  operator: EQUAL
                  field: "type"
                  value: "curso"
                },
                ${filter}]
            }
          ) {
            count
            entities {
              ... on NodeCurso {
                title
                fieldDescripcionCurso {
                  summary
                  processed
                }
                fieldImagenPreviewCurso {
                  url
                }
                fieldTemporalCurso {
                  value
                  date
                }
                fieldInstructores {
                  entity {
                    name
                    description {
                      processed
                    }
                  }
                }
                fieldIdioma {
                  entity {
                    name
                  }
                }
                fieldTagsCategoria {
                  entity {
                    name
                  }
                }
                fieldTagsTema {
                  entity {
                    name
                  }
                }
                fieldTagsPerfil {
                  entity {
                    name
                  }
                }
              }
            }
          }
        }
      `;
  }

  private getModulesByCurseQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
         query modulos($limit: Int= ${limit}, $offset: Int = ${offset}) {
          nodeQuery(
            limit:$limit,
            offset:$offset,
          sort: {field: "field_orden_modulo", direction: ASC},
          filter: {
          conditions: [{
          operator: EQUAL
          field: "status"
          value: "1"
          language: ES
          },
          {
          operator: EQUAL
          field: "type"
          value: "modulos"
          },${filter} ]
          }
          ) {
          count
          entities {
          ... on NodeModulos {
          fieldOrdenModulo
          title
          body {
          summary
          processed
          }
          fieldCursoModulo {
          entity {
          title
          }
          }
          }
          }
          }
          }
      `;
  }

  private getClasesByCurseQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query clases($limit: Int= ${limit}, $offset: Int = ${offset}) {
          nodeQuery(
            limit:$limit,
            offset:$offset,
            sort: {field: "field_orden_clase", direction: ASC},
            filter: {
              conditions: [{
                operator: EQUAL
                field: "status"
                value: "1"
                language: ES
              },
                {
                  operator: EQUAL
                  field: "type"
                  value: "clases"
                },
                ${filter}
              ]
            }
          ) {
            count
            entities {
              ... on NodeClases {
                fieldOrdenClase
                title
                fieldDuracionClase
                body {
                  summary
                  processed
                }
                fieldLink {
                  uri
                }
                fieldModuloClase {
                  entity {
                    title
                  }
                }
                fieldAdjuntosClase {
                  entity {
                  filename
                    url
                  }
                }
                fieldCursoClase {
                  entity {
                    title
                  }
                }
              }
            }
          }
        }
      `;
  }

  getVideosId(id) {
    return this.videos[id];
  }

}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class TendersService {


  private tenders: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getTenders(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset, lang): DocumentNode {
    return gql`
        query licitaciones {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "created", direction: DESC},
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1"
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Licitaciones"}
                  ${filter}
              ]}
          ) {
            count
            entities {
              ... on NodeLicitaciones {
                title
                fieldTituloIngles
                uuid
                body {
                  processed
                  summary
                }
                fieldEnlaceLicitacion {
                  uri
                }
                fieldImagenLicitacion {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldPresupuesto
              }
            }
          }
        }
      `;
  }

  getTendersId(id) {
    return this.tenders[id];
  }
}

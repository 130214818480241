import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-lacchain',
  templateUrl: './lacchain.component.html',
  styleUrls: ['./lacchain.component.scss']
})
export class LacchainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      AOS.init();
  }

  scrollTop() {
    window.scroll({
      top: 1,
      left: 0,
      behavior: 'smooth'
    });




  }

}

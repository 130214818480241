import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { academyItemsData } from 'src/app/core/mocks/data-banners';
import { GenericCodeService } from 'src/app/core/services/generic-code.service';
import { VideosService } from 'src/app/core/services/videos.service';

@Component({
  selector: 'app-academic-videos',
  templateUrl: './academic-videos.component.html',
  styleUrls: ['./academic-videos.component.scss']
})
export class AcademicVideosComponent implements OnInit {

  @ViewChild('gotoVideoList', { static: true }) gotoVideoList: ElementRef;
  public listItems: Array<string> = ['X-Small', 'Small', 'Todos', 'Medium', 'Large', 'X-Large', '2X-Large'];
  public selectedValue = 'Todos';
  public languageItems = {
    open: false,
    data: null,
    total: 0,
  };
  public selectedVLang = 'Todos';
  searchCommunityValue = '';
  public selectedVDate = { start: 2021, end: null };
  public curses = {
    open: false,
    data: null,
    total: 0,
  };
  perfil = {
    open: false,
    data: null,
    total: 0,
  };
  category = {
    open: false,
    data: null,
    total: 0,
  };
  topic = {
    open: false,
    data: null,
    total: 0,
  };
  dateItems = ['Todos', 2019, 2020, 2021];

  listCommunityOpen = false;
  public academyDataBanner: any[] = [];
  videos: any;
  videosOpens = false;
  fieldsServices = [];
  searchServiceValue = '';
  mainFilterService = '';
  selectPerfilValue = [];
  selectCategoryValue = [];
  selectedTopicValue = [];
  fieldsPlatsforms = [];
  mainFilterPlatform = [];
  urlRedirect = 'subscribe';

  currentPagePublications = 0;
  publicationsOpens = false;
  totalPublications = 0;
  limitPublications = 6;
  publications: any;
  firstLoad = true;
  searchItemsVideo: any = [{}];
  isMobile = false;
  isTablet = false;
  videoTitleSearch = '';
  searcherMultiple = [];

  constructor(
    private deviceService: DeviceDetectorService,
    private videosService: VideosService,
    private genericService: GenericCodeService, private router: Router) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.academyDataBanner = academyItemsData[0];
  }

  loadFilters(): void {
    this.loadLang();
    this.loadPerfil();
    this.loadCategory();
    this.loadTopic();
  }

  loadLang(): void {
    this.languageItems.data = [];
    this.languageItems.open = false;

    this.genericService.getLang('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.languageItems.data = data;
        this.languageItems.data.push({
          id: 'Todos',
          value: (localStorage.getItem('lang') === 'es') ? 'Todos' : 'All',
        });
        this.languageItems.total = data.length;
        this.languageItems.open = true;
      }
    });
  }

  loadPerfil(): void {
    this.perfil.data = [];
    this.perfil.open = false;
    this.genericService.getPerfil('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.perfil.data = data;
        this.perfil.total = data.length;
        this.perfil.open = true;
      }
    });
  }

  loadCategory(): void {
    this.category.data = [];
    this.category.open = false;
    this.genericService.getCategory('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.category.data = data;
        this.category.total = data.length;
        this.category.open = true;
      }
    });
  }

  loadTopic(): void {
    this.topic.data = [];
    this.topic.open = false;
    this.genericService.getTopic('', null, null, localStorage.getItem('lang').toUpperCase(), (data) => {
      if (data.length > 0) {
        this.topic.data = data;
        this.topic.total = data.length;
        this.topic.open = true;
      }
    });
  }

  ngOnInit(): void {
    this.getVideos('').then((x) => {
      this.loadFilters();
    });
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
    this.router.events.subscribe((event: any) => {
      if (((typeof event.url !== 'undefined') && event.url.includes('lang')) || (this.firstLoad)) {
        this.firstLoad = false;
        this.loadFilters();
      }
    });
  }

  onChangeLanguage(event): void {
    if (event !== null && event !== '') {
      this.searchVideosFilter(null);
    }
  }

  searchVideosFilterReset(): void {
    this.languageItems.open = false;
    this.mainFilterPlatform = [];
    this.selectedVLang = 'Todos';
    this.selectedVDate = { start: null, end: null };
    this.selectPerfilValue = [];
    this.selectCategoryValue = [];
    this.selectedTopicValue = [];
    this.searcherMultiple = [];
    this.searchVideosFilter(null);
    setTimeout(() => {
      this.languageItems.open = true;
    }, 100);
  }

  onChangeDate($event): void {
    this.searchVideosFilter(null);
  }

  handleFilterVideo(searcher: string): void {
    if (searcher !== undefined && searcher.length > 3) {
      this.getVideosItems(`{field:"title", operator: LIKE, value: "%${searcher}%"}`);
    }
  }

  valueChangeVideo(searcher: any): void {
    if (this.searcherMultiple !== null && this.searcherMultiple.length > 0) {
      this.searchVideosByUuId(searcher);
    } else {
      this.searchVideosFilter(null);
    }
  }

  searchVideosFilter(element): void {
    this.purgeFilterAllPlatform();
    this.putFilters();
    if (element !== null && (this.isMobile || this.isTablet)) {
      element
        .scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
    this.getVideos(this.mainFilterPlatform.map((x) => x.key).join(''));
  }

  pageChangedVideo(page: number) {
    this.currentPagePublications = page;
    this.getVideos('');
  }

  getVideos(filter) {
    if (this.searcherMultiple !== null && this.searcherMultiple.length > 0) {
      filter = filter +
        `{field:"uuid", operator: IN, value: [${
          this.searcherMultiple.map(d => `"${d.uuid}"`).join(',')}]}`;
    }
    return new Promise((resolve, reject) => {
      this.videosService.getVideosAcademy(filter, this.limitPublications,
        this.getOfsset(this.currentPagePublications, this.limitPublications),
        localStorage.getItem('lang').toUpperCase(), (data, total) => {
          this.videos = data;
          this.totalPublications = total;
          resolve(true);
          this.videosOpens = true;
        });
    });
  }

  getVideosItems(filter) {
    return new Promise((resolve, reject) => {
      this.videosService.getVideosAcademy(filter, this.limitPublications,
        this.getOfsset(this.currentPagePublications, this.limitPublications),
        localStorage.getItem('lang').toUpperCase(), (data, total) => {
          this.searchItemsVideo = data.map((x) => {
            return {
              name: x.title,
              uuid: x.uuid,
            };
          });
          resolve(true);
        });
    });
  }

  private getOfsset(page, limit) {
    return (page === 0) ? 0 :
      (page * limit) - limit;
  }

  concatFilterPlatform(filterSlabone: string, field: string) {
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length === 0) {
      this.fieldsPlatsforms.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsPlatsforms = this.fieldsPlatsforms.filter((x) => x !== field);
    if (this.fieldsPlatsforms.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterPlatform.length === 0) {
        this.mainFilterPlatform[this.mainFilterPlatform.length + 1] = {
          key: filterSlabone,
          fieldV: field,
        };
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterPlatform[this.mainFilterPlatform.length + 1] = {
          key: filterSlabone,
          fieldV: field,
        };
      }
    }
  }


  routerEventCurse(title: string) {
    return title.replace(' ', '-');
  }

  searchEntity(): void { }
  searchVideos() {
    this.purgeFilterService('title');
    if (this.searcherMultiple !== null && this.searcherMultiple.length > 0) {
      this.getVideos(this.concatFilterService(
        `{field:"uuid", operator: IN, value: [${
          this.searcherMultiple.map(d => `"${d.uuid}"`).join(',')}]}`, 'title'));
    } else {
      this.mainFilterService = '';
      this.getVideos(this.mainFilterService);
    }
  }

  searchVideosByUuId(value: any) {
    this.purgeFilterService('title');
    if (value.length > 0) {
      this.getVideos(this.concatFilterService(
        `{field:"uuid", operator: IN, value: [${
          value.map(d => `"${d.uuid}"`).join(',')}]}`, 'title'));
    } else {
      this.mainFilterService = '';
      this.getVideos(this.mainFilterService);
    }
  }

  private concatFilterService(filterSlabone: string, field: string) {
    this.mainFilterService = '';
    if (this.fieldsServices.filter((x) => x.id === field).length === 0) {
      this.fieldsServices = this.fieldsServices.filter((x) => x.id !== 'title');
      this.fieldsServices.push({ id: field, value: btoa(filterSlabone.toString())});
    }
    this.fieldsServices = this.fieldsServices.filter((x) => x !== field);
    if (this.fieldsServices.filter((x) => x.id === field).length !== 0) {
      if (this.mainFilterService === '') {
        this.mainFilterService = this.mainFilterService + filterSlabone;
      } else {
        filterSlabone = ',' + filterSlabone;
        this.mainFilterService = this.mainFilterService + filterSlabone;
      }
    }

    return this.mainFilterService;
  }

  private purgeFilterService(field: string): void {
    const fieldDataService = this.fieldsServices.filter((x) => x.id === field);
    if (fieldDataService.length > 0) {
      this.fieldsServices = this.fieldsServices.filter((x) => x.id !== field);
    }
  }

  private putFilters(): void {
    if (this.selectedVLang !== undefined && this.selectedVLang !== 'Todos') {
      this.concatFilterPlatform(
        `{field:"field_idm_video_educativo.entity.field_idioma_key", operator: EQUAL, value: "${
          (this.selectedVLang)}"}`, 'langcode');
    }
    if (this.selectedVDate.start !== null) {
      this.concatFilterPlatform(
        `{field:"field_temporal_video_educativo", operator: BETWEEN, value: ["${
          moment((this.selectedVDate.start + '-01-01')).format('YYYY-MM-DD HH:mm:ss')}", "${
            moment(new Date()).add('years', 50).format('YYYY-MM-DD HH:mm:ss')}"]}`, 'entityChanged');
    }
    if (this.selectPerfilValue.length > 0) {
     this.concatFilterPlatform(
        `{field:"field_tags_per_video_educativo.entity.field_perfil_key", operator: IN, value: [${
          this.selectPerfilValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabel');
    }
    if (this.selectCategoryValue.length > 0) {
      this.concatFilterPlatform(
        `{field:"field_tags_cat_video_educativo.entity.field_categoria_key", operator: IN, value: [${
          this.selectCategoryValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabelCategory');
    }
    if (this.selectedTopicValue.length > 0) {
      this.concatFilterPlatform(
        `{field:"field_tags_tema_video_educativo.entity.field_tema_key", operator: IN, value: [${
          this.selectedTopicValue.map(d => `"${d}"`).join(',')}]}`, 'entityLabelTopic');
    }
  }


  private purgeFilterPlatform(field: string): void {
    this.fieldsPlatsforms = this.fieldsPlatsforms.filter((x) => x.id !== field);
  }

  private purgeFilterAllPlatform(): void {
    this.fieldsPlatsforms = [];
    this.fieldsServices = [];
    this.mainFilterPlatform = [];
  }

  private purgeFilterSearchByName(): void {
    this.searchItemsVideo = [{}];
  }

}

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <div class="container-fluid pt-5" *ngIf="ourNewsIdOpens">
    <div class="container-sites ">
      <div class="row">
        <div class="col-12 col-md-12 center">
          <img class="img-fluid img-org" [src]="ourNewsId['fieldImagenNoticia']['url']" alt="">
        </div>
        <div class="col-12 col-md-12 mt-5 padding-section-responsive">
          <h2 class="title-banners alliance-color-text color-resources ">
            {{ourNewsId['title']}}
          </h2>
          <hr class="line-title-banner color-resources">
          <p class="p-nomaice" [innerHTML]="ourNewsId['body']['processed']"></p>
          <p class="p-nomaice">{{'fuentenoticias' | translate}}: <a href="{{ourNewsId['fieldFuentes']['uri']}}"
              target="_blank" rel="noopener noreferrer">{{ourNewsId['fieldFuentes']['uri']}}</a></p>
        </div>
      </div>
    </div>
  </div>

  <div class="our-allies" *ngIf="ourNewsOpens">
    <div class="container-fuid  container-carousel pb-4 pt-5">
      <owl-carousel-o [options]="customOptions" (dragging)="dragging($event)">
        <ng-template carouselSlide *ngFor="let item of ourNews; let i = index">
          <div class="row">
            <div class="col-sm-12 p-sm-3">
              <a (click)="goLinkNoDragging('/our-news', routerOurNews(item.fieldTituloIngles))" class="btn  btn-block mt-2" role="button">
                <app-card-our-news [title]="item.title" [text]="item.body.summary"
                  [images]="item.fieldImagenPreview.url">
                </app-card-our-news>
              </a>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

</div>

<app-footer></app-footer>

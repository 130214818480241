import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AllianceEventsService } from 'src/app/core/services/alliance-events.service';
import { AlliancesService } from 'src/app/core/services/alliances.service';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-recent-content',
  templateUrl: './recent-content.component.html',
  styleUrls: ['./recent-content.component.scss']
})
export class RecentContentComponent implements OnInit {

  eventsId: any = {};
  events: any[] = [];
  eventsRouletteOpens = false;
  eventDetailOpen = false;
  date: string;

  customOptions: OwlOptions = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    dotsSpeed: 700,
    navText: ['', ''],
  };
  indrag = false;
  idHiddenShowDescription = true;
  idHiddenShowImpartial = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private allianceEventsService: AllianceEventsService,
    private alliancesServices: AlliancesService,
    private eventsService: EventsService,
    private router: Router) {
    window.scrollTo(0, 0);

    this.router.events.subscribe((event: any) => {
      if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
        this.getEventsId(true);
        this.getEventsAcademy();
      }
    });
  }


  ngOnInit(): void {
    this.getEventsId(false);
    this.getEventsAcademy();
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.scrollTop();
      this.router.navigate([link, (title) ]);
    }
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  getEventsId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const eventId = this.getEvenIdByName(arg.title);
      this.eventDetailOpen = false;
      this.eventsService.getEventsAcademy(`
        {
          operator: EQUAL
          field: "title"
          value: "${(!status) ? (eventId) : this.eventsId.title}"
        },
      `,
        1,
        0, localStorage.getItem('lang').toUpperCase(), (events, total) => {
          this.eventsId = events[0];
          if (this.eventsId) {
            this.date = this.eventsId.fieldFechaInicioEvento.date.replace(/-/g, '/');
          }
          this.eventDetailOpen = true;
        });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }

  getEventsAcademy() {
    this.eventsService.getEventsAcademy('',
      100,
      0, localStorage.getItem('lang').toUpperCase(), (data, total) => {
        this.events = data;
        this.eventsRouletteOpens = true;
      });
  }

  getImageTypeContent(target) {
    // tslint:disable-next-line:prefer-const
    let image = 'fieldImagenPreviewAcademy';
    let result = '';
    if (image !== '') {
      const newData = target[image];
      result = (typeof newData.url !== 'undefined') ? newData.url : '';
    }
    return result;
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  routerEventParticipation(title: string) {
    return title.replace(' ', '-');
  }

  hiddenShowDescription(){
    this.idHiddenShowDescription = !this.idHiddenShowDescription;
  }

  hiddenShowImpartial(){
    this.idHiddenShowImpartial = !this.idHiddenShowImpartial;
  }

}

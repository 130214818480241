import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewContentLacchainService } from 'src/app/core/services/new.content.lacchain.service';
import { VideosService } from 'src/app/core/services/videos.service';


@Component({
  selector: 'app-video-related-information',
  templateUrl: './video-related-information.component.html',
  styleUrls: ['./video-related-information.component.scss']
})
export class VideoRelatedInformationComponent implements OnInit {

  customOptionTwoAcademy: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: true,
    margin: 15,
    navSpeed: 700,
    dotsSpeed: 500,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
        nav: false,
      },
      940: {
        items: 3,
      },
    },

  };
  eventsSlider;
  eventsOpensSlider = false;
  videosId: any = {};
  date: string;
  eventDetailOpen = false;
  indrag = false;

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(arg1, arg2) {
    if (!this.indrag) {
      this.router.navigate([arg1, arg2]);
    }
  }

  constructor(private newContentService: NewContentLacchainService,
              private router: Router, private activatedRoute: ActivatedRoute,
              private videosService: VideosService,) {
    window.scrollTo(0, 0);
    this.router.events.subscribe((event: any) => {
      if ((typeof event.url !== 'undefined') && event.url.includes('lang')) {
        this.getEventsId(true);
      }
    });
   }

  ngOnInit(): void {
    this.getEventsId(false);
    this.getContents();

  }

  getContents() {
    this.videosService.getVideosAcademy('', 9, 0,
      localStorage.getItem('lang').toUpperCase(), (data, total) => {
        this.eventsSlider = data;
        this.eventsOpensSlider = true;
      });
  }

  getUrlTypeContentRecent(target) {
    let url = '';
    url = 'video-related-information';
    return url;
  }

  getUrlTypeContent(target) {
    let url = '';
    switch (target) {
      case 'videos_educativos':
        url = 'video-related-information';
        break;
      case 'eventos_ecosistema':
        url = 'events';
        break;
    }

    return url;
  }


  getImageTypeContent(target) {
    // tslint:disable-next-line:prefer-const
    let image = 'fieldImagenVideoEducativo';
    let result = '';
    if (image !== '') {
      const newData = target[image];
      result = (typeof newData.url !== 'undefined') ? newData.url : '';
    }
    return result;
  }



  getEventsId(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {

      const eventId = this.getEvenIdByName(arg.title);
      this.eventDetailOpen = false;
      this.videosService.getVideosAcademy(`
        {
          operator: EQUAL
          field: "title"
          value: "${(!status) ? (eventId) : this.videosId.title}"
        },
      `,
        1,
        0,
        localStorage.getItem('lang').toUpperCase(),(data, total) => {
          window.scrollTo(0, 0);
          this.videosId = data[0];
          this.eventDetailOpen = true;
        });
    });
  }

  getEvenIdByName(title: string) {
    return title.replace('-', ' ');
  }


}

<div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0"
  data-aos-duration="500">
  <app-banner-sites [title]="useCasesDataBanner['title']" [description]="useCasesDataBanner['description']"
    [image]="useCasesDataBanner['image']" [color]="useCasesDataBanner['color']"
    [isButton]="useCasesDataBanner['isButton']" [textButton]="useCasesDataBanner['textButton']"
    [urlRedirect]="urlRedirect" [isScroll]="useCasesDataBanner['isScroll']" [icon]="useCasesDataBanner['icon']">
  </app-banner-sites>

  <!-- Section projects -->
  <div class="container-fluid section-gray" (keyup.enter)="searchProyects()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'proyectos' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap">
        <div class="col-md-12 col-sm-12">
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchProyectsValue"
              placeholder="{{'buscarproyectos' | translate}}">
            <button type="button" (click)="searchProyects()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
          <h3 class="text-search-advanced" (click)="showAdvancedSearch()">{{'busquedaavanzada' | translate}}</h3>
        </div>
        <div class="row container-sites">
          <div class="col-12" *ngIf="showAdvancedSearchCommunitiesClic">
            <div class="col-12 d-flex align-items-center flex-wrap p-0 m-0">
              <div class="row w-100">
                <div class="col-12 col-md-3" *ngIf="listTypeStateProyectOpen">
                  <div class="content-dropdown dropdown-advanced-search d-flex align-items-center">
                    <p class="text-search-advanced p-0 m-0 mr-3">{{'estado' | translate}}:</p>
                    <kendo-dropdownlist (selectionChange)="selectionTypeStateProyect($event)"
                      [data]="listTypeStateProyect" [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="listCategoriesOpen">
                  <div class="content-dropdown dropdown-advanced-search d-flex align-items-center ml-md-3">
                    <p class="text-search-advanced p-0 m-0 mr-3">{{'categoria' | translate}}:</p>
                    <kendo-dropdownlist (selectionChange)="selectionTypeCategoryProyect($event)" [data]="listCategories"
                      [valueField]="'id'" [textField]="'value'">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites" *ngIf="proyectsOpens">
      <pagination-controls id="proyects" (pageChange)="getPageProyects($event)"
        previousLabel="{{'anterior' | translate}}" nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div *ngIf="inTransact.proyects" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of useProjects | paginate: {
              id: 'proyects', itemsPerPage: limitProyect, currentPage: currentPageProyect, totalItems: totalProyect
            }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/projects',routerProjects(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-projects [title]="item.title" [text]="item.body.summary" [state]="item.fieldEstadoProyectos"
              [images]="item.fieldImagenPreview.url">
            </app-card-projects>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="useProjects.length === 0 && !inTransact.proyects">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- Section Tenders -->
  <div class="container-fluid padding-pagination" (keyup.enter)="searchTenders()">
    <div class="row container-sites padding-section-responsive">
      <h2 class="title-sections mb-4 pl-4">{{'licitaciones' | translate}}</h2>
      <div class="col-12 d-flex  align-items-start flex-wrap">
        <div class="col-md-12 col-sm-12">
          <div class="search my-4">
            <input type="text" class="searchTerm" [(ngModel)]="searchTendersValue"
              placeholder="{{'buscarlicitaciones' | translate}}">
            <button type="button" (click)="searchTenders()" class="searchButton">
              {{'buscar' | translate}} <em class="fa fa-search"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row container-sites" *ngIf="tendersOpens">
      <pagination-controls id="tenders" (pageChange)="getPageTenders($event)" previousLabel="{{'anterior' | translate}}"
        nextLabel="{{'siguiente' | translate}}">
      </pagination-controls>
      <div *ngIf="inTransact.tenders" class="d-flex justify-content-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12 d-flex align-itmes-center flex-wrap mt-3">
        <div *ngFor="let item of tenders | paginate: {
              id: 'tenders', itemsPerPage: limitProyect, currentPage: currentPageTender, totalItems: totalTender
            }; let i = index" class="col-md-4 col-sm-12 p-sm-3 min-width-card-componentes">
          <a [routerLink]="['/tenders', routerProjects(item.fieldTituloIngles)]" class="btn  btn-block mt-2" role="button">
            <app-card-tender [title]="item.title" [text]="item.body.summary" [budget]="item.fieldPresupuesto"
              [images]="item.fieldImagenPreview.url">
            </app-card-tender>
          </a>
        </div>
        <div class="col-md-4 col-sm-12 p-sm-3 style-content-not-found"
          *ngIf="tenders.length === 0 && !inTransact.tenders">
          {{'noResults' | translate}}
        </div>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>

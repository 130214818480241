import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss']
})
export class CardContentComponent implements OnInit {

  @Input() title: string;
  @Input() text: string;
  @Input() images: string;
  @Input() formatDate: string;
  @Input() colorAcademy: string;




  constructor() {
   }

  ngOnInit(): void {

  }

}

import { Component, OnInit } from '@angular/core';
import { TheBlockchainService } from 'src/app/core/services/the-blockchain.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-the-blockchain-iframe',
  templateUrl: './the-blockchain-iframe.component.html',
  styleUrls: ['./the-blockchain-iframe.component.scss'],
})
export class TheBlockchainIframeComponent implements OnInit {
  constructor(private theBlockchainService: TheBlockchainService) {
    TheBlockchainIframeComponent.updateLanguageSelected.subscribe((data) => {
      this.getImages();
    });
    this.getImages();
  }

  public static updateLanguageSelected: Subject<boolean> = new Subject();

  dataOpen = false;
  data;

  getImages() {
    this.theBlockchainService.getImagesBlockchain(
      '',
      100,
      0,
      (academy, total) => {
        this.data = academy;
        this.dataOpen = true;
      }
    );
  }

  ngOnInit(): void {
    this.getImages();
  }
}

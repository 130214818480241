import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class UseProjectsService {


  private useProjects: any[] = [

  ];

  constructor(private apollo: Apollo) { }

  getUseProjects(searchFilter: string, limit, offset, lang, callback) {
    this.apollo.query<any>({
      query: this.getQuery(searchFilter, limit, offset, lang),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getQuery(filter: string, limit, offset , lang): DocumentNode {
    return gql`
        query proyectos {
          nodeQuery(
            limit: ${limit},
            offset: ${offset},
            sort: {field: "title", direction: ASC },
            filter: {
              conditions:
              [{
                operator: EQUAL,
                field: "status",
                value: "1",
                language: ${lang}
              },
                {operator: EQUAL,
                  field: "type",
                  value: "Proyectos"}
              ${filter}]}
          ) {
            count
            entities {
              ... on NodeProyectos {
                title
                fieldTituloIngles
                uuid
                fieldCategoriaProyecto
                body {
                  processed
                  summary
                }
                fieldEntidadesInvolucradas
                fieldEstadoProyectos
                fieldGithubProyecto {
                  uri
                }
                fieldIdiomasDisponibles
                fieldImagenDelProyecto {
                  url
                }
                fieldImagenPreview {
                  url
                }
                fieldLugarProyecto
                fieldPersonaDeContacto
                fieldProcesoDeIntegracion
                fieldSitioWebProyectos {
                  uri
                }
                fieldDemo{
                  uri
                }
                fieldImagenDelProyecto {
                  url
                }
              }
            }
          }
        }
      `;
  }

  getUseProjectsId(id) {
    return this.useProjects[id];
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

@Injectable({
  providedIn: 'root',
})
export class TheBlockchainService {
  constructor(private apollo: Apollo) {}

  getEntities(lang: string, callback) {
    this.apollo
      .query<any>({
        query: this.getEntitiesQuery(lang),
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        (result) => {
          callback(result.data.nodeQuery.entities, result.data.nodeQuery.count);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getImagesBlockchain(searchFilter: string, limit, offset, callback) {
    this.apollo
      .query<any>({
        query: this.getImagesQuery(searchFilter, limit, offset),
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        (result) => {
          callback(
            result.data.nodeQuery.entities.map((x) => {
              const lang = localStorage.getItem('lang').toLocaleUpperCase();
              if (lang === 'EN') {
                return {
                  ...x,
                  fieldTextoDashboard: {
                    processed:
                      x.fieldTextoEnDashboard !== null
                        ? x.fieldTextoEnDashboard.processed
                        : x.fieldTextoDashboard.processed,
                  },
                  fieldTextoExplorer: {
                    processed:
                      x.fieldTextoEnExplorer !== null
                        ? x.fieldTextoEnExplorer.processed
                        : x.fieldTextoExplorer.processed,
                  },
                  fieldTextoMainnetNodes: {
                    processed:
                      x.fieldTextoEnMainnetNodes !== null
                        ? x.fieldTextoEnMainnetNodes.processed
                        : x.fieldTextoMainnetNodes.processed,
                  },
                  fieldTextomainnetTransactions: {
                    processed:
                      x.fieldTextoEnMainnetTransact !== null
                        ? x.fieldTextoEnMainnetTransact.processed
                        : x.fieldTextomainnetTransactions.processed,
                  },
                };
              } else {
                return {
                  ...x,
                };
              }
            }),
            result.data.nodeQuery.count
          );
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private getEntitiesQuery(lang: string): DocumentNode {
    return gql`
      query entidades_blockchain {
        nodeQuery (
          limit: 100
          offset: 0
          sort: {field: "title", direction: ASC}
          filter: {
            conditions: [{
              operator: EQUAL
              field: "status"
              value: "1"
              language: ${lang}
            }, {
              operator: EQUAL
              field: "type"
              value: "entidades_blockchain"
            }]
          }
        ) {
          count
          entities {
            ...on NodeEntidadesBlockchain {
              title
              fieldLogoEntidad {
                url
              }
              fieldSitioWebEntidad {
                uri
              }
            }
          }
        }
      }
    `;
  }

  private getImagesQuery(filter: string, limit, offset): DocumentNode {
    return gql`
      query blockchain_images($limit: Int= ${limit}, $offset: Int = ${offset}) {
      nodeQuery(
        limit:$limit,
        offset:$offset,
        filter: {
          conditions: [{
            operator: EQUAL
            field: "status"
            value: "1"
          },
            {
              operator: EQUAL
              field: "type"
              value: "Blockchain_images"
            }${filter}]
        }
      ) {
        count
        entities {
          ... on NodeBlockchainImages {
            title
            fieldImagenDashboard {
              url
            }
            fieldLinkDashboard {
              uri
            }
            fieldImagenExplorer {
              url
            }
            fieldLinkExplorer {
              uri
            }
            fieldTextoDashboard {
              processed
            }
            fieldTextoEnDashboard {
              processed
            }
            fieldLinkBtnDashboard {
              uri
            }
            fieldTextoExplorer {
              processed
            }
            fieldTextoEnExplorer {
              processed
            }
            fieldLinkBtnExplorer {
              uri
            }
            fieldImagenMainnetNodes {
              url
            }
            fieldLinkMainnetNodes {
              uri
            }
            fieldTextoMainnetNodes {
              processed
            }
            fieldTextoEnMainnetNodes {
              processed
            }
            fieldLinkBtnMainNodes {
              uri
            }
          	fieldImagenMainnetTransaction {
              url
            }
            fieldLinkMainnetTransactions {
              uri
            }
            fieldTextomainnetTransactions {
              processed
            }
            fieldTextoEnMainnetTransact {
              processed
            }
            fieldLinkBtnMainnetTransact {
              uri
            }
          }
        }
      }
    }
  `;
  }
}

<div class="col-12">
  <div class="row">
    <img class="image-card-section" [src]="images" alt="">
  </div>
  <div class="row d-flex align-items-center">
    <div class="col-10 p-0 m-0">
      <h3 [ngClass]="{'color-white': color}" class="title-card-section pt-3 color-resources">{{title}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0 m-0">
      <p [ngClass]="{'color-white': color}" class="description-card-section">{{text}}</p>
    </div>
  </div>
</div>
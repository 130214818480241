<div class="container-fluid section-contact-us p-0">
  <div class="back-contact-us">
    <div class="container-entrepreneurship h-100 d-flex flex-wrap align-content-center justify-content-end">
      <div class="container-contact-us">
        <h3>{{'contacto' | translate}}</h3>
        <form role="form" [formGroup]="form" (ngSubmit)="SendProyect()" novalidate>
          <div class="form-scroll">
            <div class="form-group form-group-lstore">
              <label for="name">{{'nombre' | translate}}:</label>
              <input type="text" formControlName="name" maxlength="255" class="form-control form-control-lstore"
                name="name" id="name" placeholder="">
              <div *ngIf="submitted && f.name.errors" class="invalid-field">
                <div *ngIf="f.name.errors.required">{{'esRequerido' | translate}}</div>
              </div>
            </div>
            <div class="form-group form-group-lstore">
              <label for="email">{{'correoelectronico' | translate}}:</label>
              <input type="email" formControlName="email" class="form-control form-control-lstore" id="email"
                placeholder="">
              <div *ngIf="submitted && f.email.errors" class="invalid-field">
                <div *ngIf="f.email.errors.required">{{'esRequerido' | translate}}</div>
              </div>
            </div>
            <div class="form-group form-group-lstore">
              <label for="contactPhone">{{'telefono' | translate}}:</label>
              <input type="text" formControlName="contactPhone" maxlength="255" class="form-control form-control-lstore"
                id="contactPhone" placeholder="">
              <div *ngIf="submitted && f.contactPhone.errors" class="invalid-field">
                <div *ngIf="f.contactPhone.errors.required">{{'esRequerido' | translate}}</div>
              </div>
            </div>
            <div class="form-group form-group-lstore">
              <label for="businessRepresent">{{'empresaProyecto' | translate}}:</label>
              <input type="text" formControlName="businessRepresent" maxlength="255"
                class="form-control form-control-lstore" id="businessRepresent" placeholder="">
              <div *ngIf="submitted && f.businessRepresent.errors" class="invalid-field">
                <div *ngIf="f.businessRepresent.errors.required">{{'esRequerido' | translate}}</div>
              </div>
            </div>
            <div class="form-group form-group-lstore">
              <label for="nameComplet">{{'categoria' | translate}}:</label>
              <section class="example-section">
                <mat-checkbox class="example-margin" (change)="aliado = $event.checked">
                  {{'serAliado' | translate}}
                </mat-checkbox>
                <mat-checkbox class="example-margin" (change)="licitacion = $event.checked">
                  {{'anunciarLicitacion' | translate}}
                </mat-checkbox>
                <mat-checkbox class="example-margin" (change)="financiacion = $event.checked">
                  {{'anunciarPosibilidad' | translate}}
                </mat-checkbox>
                <mat-checkbox class="example-margin" (change)="difundirConm = $event.checked">
                  {{'difundirComunidadentidad' | translate}}
                </mat-checkbox>
                <mat-checkbox class="example-margin" (change)="promocionarP = $event.checked">
                  {{'promocionarProyecto' | translate}}</mat-checkbox>
                <mat-checkbox class="example-margin" (change)="coOtro = $event.checked" (click)="showInput()">
                  {{'otro' | translate}}
                </mat-checkbox>
              </section>
              <input *ngIf="showHidenInput" type="text" maxlength="50" formControlName="categoriaOtro"
                class="form-control form-control-lstore" id="categoriaOtro" placeholder="">
              <div *ngIf="showHidenInput && coOtro && f.categoriaOtro.value == ''" class="invalid-field">
                <div>El campo otro requerido</div>
              </div>
              <div *ngIf="submitted && this.support.data.length == 0 && !coOtro" class="invalid-field">
                <div>Es requerido al menos una categoria</div>
              </div>
            </div>
            <div class="form-group form-group-lstore">
              <label for="description">{{'mensaje' | translate}}:</label>
              <textarea class="form-control form-control-lstore" maxlength="1024" formControlName="description"
                id="description" rows="3"></textarea>
              <div *ngIf="submitted && f.description.errors" class="invalid-field">
                <div *ngIf="f.description.errors.required">{{'esRequerido' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="content-btn">
            <button type="submit" class="btn btn-send btn-lg btn-block">{{'enviar' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<app-footer></app-footer>

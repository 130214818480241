import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class StadisticsHomeService {
  constructor(private apollo: Apollo) { }

  getStadistics(callback) {
    this.apollo.query<any>({
      query: this.getStadisticsQuery(),
      fetchPolicy: 'no-cache',
    })
    .subscribe(
      result => {
        callback(
          result.data.nodeQuery.entities,
          result.data.nodeQuery.count
        );
      },
      error => {console.log(error)});
  }

  private getStadisticsQuery(): DocumentNode {
    return gql`
        query estadisticas_home {
      nodeQuery (
        filter: {
          conditions: [{
            operator: EQUAL
            field: "status"
            value: "1"
          }, {
            operator: EQUAL
            field: "type"
            value: "Estadisticas_home"
          }]
        }
      ) {
        count
        entities {
          ...on NodeEstadisticasHome {
            fieldNumeroNodos
            fieldNumeroEntidades
            fieldNumeroBloques
            fieldAliadosStadistics
            fieldNumeroPaisesEcosistema
          }
        }
      }
    }
      `;
  }
}

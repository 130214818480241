import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-slider-recent',
  templateUrl: './card-slider-recent.component.html',
  styleUrls: ['./card-slider-recent.component.scss']
})
export class CardSliderRecentComponent implements OnInit {

  @Input() title: string;
  @Input() text: string;
  @Input() images: string;
  @Input() year: string;
  @Input() isVideoCurse: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Component, HostListener, OnInit } from '@angular/core';
import { NewContentLacchainService } from 'src/app/core/services/new.content.lacchain.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { CursesService } from 'src/app/core/services/curses.service';
import { ModalAcademyComponent } from '../modal-academy/modal-academy.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-course-detail-academy',
  templateUrl: './course-detail-academy.component.html',
  styleUrls: ['./course-detail-academy.component.scss']
})
export class CourseDetailAcademyComponent implements OnInit {
  widthIframe = '100%';
  heightIframe = 360;
  activeMedium = false;
  customOptionTwoAcademy: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    margin: 15,
    navSpeed: 700,
    dotsSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
        nav: false,
      },
      940: {
        items: 3,
      },
    },

  };
  curseSlider;
  eventsOpensSlider = false;
  hiddeninfoMenu = false;
  eventDetailOpen = false;
  claseOpen = false;
  dataId = null;
  clases = null;
  modules = null;
  moduleOpen = false;
  class = {
   open: false,
   data: null,
  };

  isShow: boolean;
  topPosToStartShowing = 100;
  activeIndex = 0;
  indexMain = 0;
  lastIndex = 0;
  dialogRef: any;
  showMoreId = true;
  showMoreText = 'vermas';
  indrag = false;

  constructor(private newContentService: NewContentLacchainService,
              private router: Router, private activatedRoute: ActivatedRoute,
              private cursesService: CursesService, public dialog: MatDialog) {
    window.scrollTo(0, 0);
    this.loadDetail(true);
  }

  ngOnInit(): void {
    this.getContents();
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
  }
  iframeMedium(){
    this.widthIframe = '100%';
    this.heightIframe = 500;
    this.activeMedium = !this.activeMedium;
    if (this.activeMedium === false) {
      this.heightIframe = 360;
    }
  }

  dragging(event): void {
    this.indrag =  event.dragging;
  }

  goLinkNoDragging(link, title) {
    if (!this.indrag) {
      this.router.navigate([link, this.routerEntities(title) ]);
    }
  }
  loadDetail(status: boolean) {
    this.activatedRoute.params.subscribe(arg => {
      const eventId = this.routerEntities(arg.title);
      if (arg.title !== undefined) {
        this.eventDetailOpen = false;
        this.cursesService.getCursesById(`
          {
            operator: EQUAL
            field: "title"
            value: "${eventId}"
          },
        `,
          1,
          0,
          localStorage.getItem('lang').toUpperCase()).then((data: any) => {
            window.scrollTo(0, 0);
            this.dataId = data[0];
            this.eventDetailOpen = true;
          });
      }

      this.loadModules(arg.title).then((x) => {
        this.loadClases(arg.title);
      });
    });
  }

  loadModules(id) {
    return new Promise((resolve, reject) => {
      this.moduleOpen = false;
      this.cursesService.getModulesByCurseId(`
        {
          operator: EQUAL
          field: "field_curso_modulo.entity.title"
          value: "${(!id) ? (id) : id.replace('-', ' ')}"
          },
      `,
        100,
        0,
        localStorage.getItem('lang').toUpperCase()).then((data: any) => {
          window.scrollTo(0, 0);
          this.modules = data;
          this.moduleOpen = true;
          resolve(true);
        });
    });
  }

  loadClases(id) {
    return new Promise((resolve, reject) => {
      this.claseOpen = false;
      this.activatedRoute.params.subscribe(arg => {
      this.cursesService.getClasesByCurseId(`
        {
          operator: EQUAL
          field: "field_curso_clase.entity.title"
          value: "${(!id) ? (id) : arg.title.replace('-', ' ')}"
        },
      `,
        100,
        0,
        localStorage.getItem('lang').toUpperCase()).then((data: any) => {
          if (data.length > 0) {
            window.scrollTo(0, 0);
            this.clases = data;
            this.lastIndex = this.clases.length - 1;
            this.class.data = data[0];
            this.class.open = true;
            this.claseOpen = true;
            resolve(true);
          }
        });
    });
    });
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  getClasesByModule(keyModule, clases) {
    return clases.filter((x) => x.fieldModuloClase.entity.title === keyModule);
  }

  getGeneralIndexClassCurse(title): number {
    const indexers = this.clases.map((x, i) => {
      return {id: i, title: x.title, last: (this.clases.length === i)};
    });
    return indexers.filter((x) => x.title === title)[0].id;
  }

  goToClass(classs): void {
    this.activeIndex = this.getGeneralIndexClassCurse(classs.title);
    this.class.data = classs;
    this.class.open = true;
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getUrlTypeContentRecent(target) {
    let url = '';
    url = 'course-detail';
    return url;
  }

  routerEntities(title: string) {
    return title.replace('-', ' ');
  }

  onViwModal() {
    (document.querySelector('body') as HTMLElement).style.height = '150vh';

    this.dialogRef = this.dialog.open(ModalAcademyComponent, {
        disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      (document.querySelector('body') as HTMLElement).style.height = '100vh';
      if (result) {
      }
    });

  }

  getContents() {
    this.cursesService.getCurses('', 100, 0,
      localStorage.getItem('lang').toUpperCase()).then((data: any) => {
        if (data.length > 0 && data[0] !== null) {
          this.curseSlider = data;
          this.eventsOpensSlider = true;
        }
      });
  }

  getImageTypeContent(target) {
    // tslint:disable-next-line:prefer-const
    let image = 'fieldImagenPreviewCurso';
    let result = '';
    if (image !== '') {
      const newData = target[image];
      result = (typeof newData.url !== 'undefined') ? newData.url : '';
    }
    return result;
  }

  desQuitActive() {
    (document.querySelector('#sidebar') as HTMLElement).classList.add('active');
    (document.querySelector('#content') as HTMLElement).classList.add('active');
    this.activeMedium = true;
    this.widthIframe = '100%';
    this.heightIframe = 500;
    this.hiddeninfoMenu = true;
  }

  quitActive() {
    (document.querySelector('#sidebar') as HTMLElement).classList.remove('active');
    (document.querySelector('#content') as HTMLElement).classList.remove('active');
    this.activeMedium = false;
    this.widthIframe = '100%';
    this.heightIframe = 500;
    this.hiddeninfoMenu = false;
  }

  showMore(){
    this.showMoreId = !this.showMoreId;
    if (this.showMoreId === false){
      this.showMoreText = 'vermenos';
    }
  }

}

<div class="modal-home">
  <button class="btn btn-link btn-close" mat-button [mat-dialog-close]="true" cdkFocusInitial>
    <em class="fas fa-times"></em>
  </button>
  <mat-dialog-content class="mat-typography">
    <a href="{{link}}" target="_blank"
      rel="noopener noreferrer">
      <img class="img-fluid" src="{{image}}" alt="">
    </a>
  </mat-dialog-content>

</div>
